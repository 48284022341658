const availableCompanies = {
    Avon: {
        id: 1,
        origem: 'AVON',
        cnpj: '56991441000157',
        servico: '17.06',
        inscMunicipal: '12442690',
    },
    Natura: {
        id: 2,
        origem: 'NATURA',
        cnpj: '71673990000177',
        servico: '10.05',
        inscMunicipal: '47671505'
    },
    'Natura Treinadoras': {
        id: 7,
        origem: 'NATURA TREINADORAS',
        cnpj: '20515685000116',
        servico: '8.02',
        inscMunicipal: '50025708'
    },
    Wiser: {
        id: 3,
        origem: 'WISER',
        cnpj: '01959772000703',
        servico: '17.06',
        inscMunicipal: '51761238'
    },
    'Febracis Treinamentos': {
        id: 4,
        origem: 'FEBRACIS_TREINAMENTOS',
        cnpj: '28787336000165',
        servico: '17.06',
        inscMunicipal: '4758412'
    },
    'Febracis Digital': {
        id: 5,
        origem: 'FEBRACIS_DIGITAL',
        cnpj: '29845623000147',
        servico: '17.06',
        inscMunicipal: '4819306'
    },
    'Febracis Desenvolvimento': {
        id: 6,
        origem: 'FEBRACIS_DESENVOLVIMENTO',
        cnpj: '28787336000408',
        servico: '17.06',
        inscMunicipal: '5.AD360-8'
    },
    'Febracis Coaching': {
        id: 7,
        origem: 'FEBRACIS_COACHING',
        cnpj: '31419079000113',
        servico: '17.06',
        inscMunicipal: '0025224700-9'
    },
    'Febracis Gerencial': {
        id: 8,
        origem: 'FEBRACIS_GERENCIAL',
        cnpj: '28787320000152',
        servico: '17.06',
        inscMunicipal: '475840-4'
    },
    'Febracis Sao Paulo': {
        id: 9,
        origem: 'FEBRACIS_SAOPAULO',
        cnpj: '29321520000188',
        servico: '17.06',
        inscMunicipal: '5.870.219-9'
    },
    'Febracis Vitoria': {
        id: 10,
        origem: 'FEBRACIS_VITORIA',
        cnpj: '33576767000112',
        servico: '17.06',
        inscMunicipal: '1262132'
    },
    'Febracis Rio': {
        id: 11,
        origem: 'FEBRACIS_RIO',
        cnpj: '26774465000193',
        servico: '17.06',
        inscMunicipal: '1.037.558-4'
    },
    'Febracis Rahhal': {
        id: 12,
        origem: 'FEBRACIS_RAHHAL',
        cnpj: '13411283000106',
        servico: '17.06',
        inscMunicipal: '14234119'
    },
    'Febracis MLS': {
        id: 13,
        origem: 'FEBRACIS_MLS',
        cnpj: '23877386000110',
        servico: '17.06',
        inscMunicipal: '22441701'
    },
    'Febracis RA5': {
        id: 14,
        origem: 'FEBRACIS_RA5',
        cnpj: '28632363000169',
        servico: '17.06',
        inscMunicipal: '5955300'
    },
    'Febracis Sidney': {
        id: 15,
        origem: 'FEBRACIS_SIDNEY',
        cnpj: '28092027000170',
        servico: '17.06',
        inscMunicipal: '20122809'
    },
    'Febracis NEWCO': {
        id: 16,
        origem: 'FEBRACIS_NEWCO',
        cnpj: '29325338000103',
        servico: '17.06',
        inscMunicipal: '60690925'
    },
    'Febracis Floripa': {
        id: 17,
        origem: 'FEBRACIS_FLORIPA',
        cnpj: '27084974000157',
        servico: '17.06',
        inscMunicipal: '258252812'
    },
    'Febracis Prosperar': {
        id: 18,
        origem: 'FEBRACIS_PROSPERAR',
        cnpj: '43705420000186',
        servico: '17.06',
        inscMunicipal: '681488-3'
    },
    'Febracis RA5 Maceio': {
        id: 19,
        origem: 'FEBRACIS_RA5_MACEIO',
        cnpj: '29605407000124',
        servico: '17.06',
        inscMunicipal: '9014581120'
    },
};

export function getAvailablesCompanies(company = null) {
    if (company) {
        return availableCompanies[company];
    } else {
        return availableCompanies;
    }
}

export function getCapitalizedName(company) {
    const companyName = company.origem.toLowerCase();

    return `${companyName[0].toUpperCase() + companyName.slice(1)}`;

}
