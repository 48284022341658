import Axios from 'axios';
import store from '../../Store';
import Invoice from './Invoice';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {setInvoices, setPagination, setPaginationList} from '../../actions/invoice-actions';
import Company from '../Company/Company';
import {format, addDays} from 'date-fns';

class InvoiceContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            detailNfeId: null,
            page: 0,
            invoiceListLoading: false,
            invoiceDetailLoading: false,
            invoiceDetailRegisterLoading: false,
            userFeedbackRegisterErrorMessage: '',
            userFeedbackRegisterSuccessMessage: '',
            filterDate: [
                'Carregando...'
            ],
            filterStatus: [
                'Carregando...'
            ],
            userFeedbackMessage: '',
            filterItemPerPage: [
                '5',
                '10',
                '15',
                '20'
            ],
            filterDateValue: '',
            filterStatusValue: '',
            itemPerPage: 5,
            filterSearchValue: '',
            labelIdAscending: true,
            labelCnpjAscending: true,
            labelValueAscending: true,
            labelEntryDateAscending: true,
            labelFinalDateAscending: true,
            labelStatusAscending: true,
            invoice: [],
            filteredInvoice: [],
            invoiceFilePhotoName: 'Selecione um arquivo PDF',
            invoiceFilePDFName: 'Selecione um arquivo PDF',
            invoiceFileXMLName: 'Selecione um arquivo XML',
            invoiceDetailOpen: false,
            invoiceDetail: {},
            invoiceProviderOpen: true,
            invoiceHirerOpen: true,
            invoiceDistinctionOpen: true,
            invoiceTaxesOpen: true,
            invoiceDownloadFileOpen: true,
            invoiceOtherInfoOpen: true,
            invoiceAttachmentOpen: true,
            closeInvoiceDetailOpen: false,
            invoiceListOpen: true,
            invoiceInscricaoOpen: true,
            invoiceCredentialOpen: true,
            invoiceServiceInfo: '',
            invoiceOtherInfo: '',
            invoiceFilePDF: '',
            invoiceFileXML: '',
            detailInscricaoMunicipal: '',
            detailInscricaoEstadual: '',
            detailCredentialPassword: '',
            detailCredentialTokenUser: '',
            detailCertifiedPFX: '',
            pageItems: [],
            filteredPageItems: [],
            detailIDDemanda: '',
            detailNumeroNota: '',
            detailValorNota: '',
            detailDataEmissao: new Date().toISOString().substr(0, 10),
            detailPrestadorCpfCnpj: '',
            detailPrestadorRazao: '',
            detailPrestadorInscricao: '',
            detailPrestadorEndereco: '',
            detailPrestadorEstado: '',
            detailPrestadorCidade: '',
            detailPrestadorEmail: '',
            detailPrestadorPhone: '',
            detailPrestadorTipoCNPJ: '',
            detailTomadorCpfCnpj: '',
            detailTomadorRazao: '',
            detailTomadorInscricao: '',
            detailTomadorEndereco: '',
            detailTomadorEstado: '',
            detailTomadorCidade: '',
            detailTomadorEmail: '',
            detailTomadorPhone: '',
            detailTomadorTipoCNPJ: '',
            detailEncargoINSS: '',
            detailEncargoIRRF: '',
            detailEncargoCSLL: '',
            detailEncargoCOFINS: '',
            detailEncargoPISPASEP: '',
            detailEncargoCodServico: '',
            detailEncargoAliquota: '',
            detailEncargoISS: '',
            detailEncargoMunicipioPrestacao: '',
            detailFileXML: '',
            detailFilePDF: '',
            detailFilePhoto: '',
            dicasMEIMessage: '',
            detailPrefeituraHomologada: '',
            invoiceFilePhotoError: '',
            invoiceFilePDFError: '',
            invoiceFileXMLError: '',
            invoiceServiceCNAE: '',
            invoiceServiceLC116: '',
            detailPrestadorNumero: '',
            detailPrestadorComplemento: '',
            detailPrestadorBairro: '',
            detailPrestadorCEP: '',
            detailTomadorNumero: '',
            detailTomadorComplemento: '',
            detailTomadorBairro: '',
            detailTomadorCEP: '',
            detailVerificationCode: '',
            detailSerie: '1',
            pdfValidationRequest: null,
            pdfValidationResponse: null,
            tempFileStore: null
        };

        this.handleFilterSearch = this.handleFilterSearch.bind(this);
        this.handleFilterDate = this.handleFilterDate.bind(this);
        this.handleFilterStatus = this.handleFilterStatus.bind(this);
        this.handleFilterItemPerPage = this.handleFilterItemPerPage.bind(this);
        this.handleLabelID = this.handleLabelID.bind(this);
        this.handleLabelCNPJ = this.handleLabelCNPJ.bind(this);
        this.handleLabelValue = this.handleLabelValue.bind(this);
        this.handleLabelEntryDate = this.handleLabelEntryDate.bind(this);
        this.handleLabelFinalDate = this.handleLabelFinalDate.bind(this);
        this.handleLabelStatus = this.handleLabelStatus.bind(this);
        this.handleShowInvoiceDetail = this.handleShowInvoiceDetail.bind(this);
        this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);
        this.handleInvoiceDetailClose = this.handleInvoiceDetailClose.bind(this);
        this.handleCloseWithoutSave = this.handleCloseWithoutSave.bind(this);
        this.handleCloseAndSave = this.handleCloseAndSave.bind(this);
        this.validateCpfOrCnpj = this.validateCpfOrCnpj.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInvoiceDetailSection = this.handleInvoiceDetailSection.bind(this);
        this.handleDetailCloseButton = this.handleDetailCloseButton.bind(this);
        this.validateCurrency = this.validateCurrency.bind(this);
        this.validateCNPJ = this.validateCNPJ.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.setInvoice = this.setInvoice.bind(this);
        this.handleInputFileChange = this.handleInputFileChange.bind(this);
        this.handleSetInvoice = this.handleSetInvoice.bind(this);
        this.setPdfValidationRequest = this.setPdfValidationRequest.bind(this);
        this.clearPdfValidationResponse = this.clearPdfValidationResponse.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getInvoice(nextProps.companySelected);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        this.getInvoice(this.props.companySelected);
    }

    getApiUrl(endpoint) {
        return `https://www.dicasmei.com.br/api/${endpoint}`;
        // return `http://dicasmei.localhost/api/${endpoint}`
    }

    validateClientToken() {
        const expiresIn = localStorage.getItem('clientToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('clientToken');
            localStorage.removeItem('clientToken_expiration');
            localStorage.setItem('loggedIn', false);
            this.setState({ loggedIn: false });
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('clientToken');
    };

    async fetchCompanyStatusChange(id) {
        const response = await fetch(this.getApiUrl(`backoffice/demanda/${id}`), {
            method: 'GET',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateClientToken()
            }
        });
        const data = await response.json();
        return data;
    }

    async fetchInvoice(client, page) {
        this.setState({
            filteredInvoice: 0
        });
        const filters = store.getState().invoice.filters;
        return Axios.get(this.getApiUrl('backoffice/demanda?' +
            `cliente=${client.origem}` +
            '&servico=1' +
            `&page=${page}` +
            `&limit=${filters.itemPerPage}` +
            `&status=${filters.status}` +
            `&cnpj=${filters.cnpj}`), {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateClientToken()
            }
        });
    }

    async fetchInvoiceDetail(id) {
        const response = await fetch(this.getApiUrl(`backoffice/demanda/${id}/nota`), {
            method: 'GET',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateClientToken()
            }
        });
        const data = await response.json();
        return data;
    }

    async fetchMEIDetail(cnpj) {
        const response = await fetch(this.getApiUrl(`backoffice/mei/${this.formatAllSpecialCharacters(cnpj)}`), {
            method: 'GET',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateClientToken()
            },
        });
        const data = await response.json();
        return data;

    }

    formatCNPJ(cnpj, noCharacter) {
        return noCharacter
            ? this.formatAllSpecialCharacters(cnpj)
            : cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,'\$1.\$2.\$3\/\$4\-\$5');
    }

    formatAllSpecialCharacters(str) {
        return str.replace(/[^\d]+/g, '');
    }

    handleCompanyStatusConsult(id) {
        const self = this;

        if (this.props.loggedIn) {
            this.fetchCompanyStatusChange(id)
                .then(this.handleFetchError)
                .then(result => {
                    if (result.cliente === this.props.companySelected.origem && this.mounted) {
                        if (result.codigostatus !== 2) {
                            return setTimeout(function() { self.handleCompanyStatusConsult(id); }, 300000);
                        }
                        return this.changeCompanyStatusUI(id);
                    }
                })
                .catch(error => {
                    const errorParse = error.message.indexOf('mensagem')  !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                    console.log(`Error at handleCompanyStatusConsult: ${error}`);

                    if (errorParse.code === 401) {
                        this.props.handleLogoff();
                    } else {
                        this.setUIFeedback('userFeedbackRegisterErrorMessage', 'Ocorreu um erro ao carregar, recarregue a página e tente novamente.', true);
                    }
                });
        }
    }

    changeCompanyStatusUI(id) {
        const pageItems = this.state.filteredPageItems.map(company => {
            if (company.cnpj === id) company.status = 'Emitida';
            return company;
        });

        this.setState({
            filteredPageItems: pageItems,
            pageItems: pageItems
        });
    }

    handleFetchError(response) {
        if (response.code || response.codigo) throw new Error(JSON.stringify(response));
        return response;
    }

    formatNFDate(date) {
        var formattedDate = new Date(date);
        var d = formattedDate.getDate();
        var m =  formattedDate.getMonth();
        m += 1;
        var y = formattedDate.getFullYear();
        d = d < 10 ? `0${d}` : d;
        m = m < 10 ? `0${m}` : m;
        return d + '/' + m + '/' + y;
    }

    formatInvoiceValue(val) {
        var valor = String(val);
        var valTot = '';
        return valTot += valor.replace('.', '').replace(',', '.');
    }

    convertToBase64(input, callback) {
        var selectedFile = input.files;
        if (selectedFile.length > 0) {
            var fileToLoad = selectedFile[0];
            var fileReader = new FileReader();
            fileReader.onload = callback;
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    handleSetInvoice() {
        let xmlBase64, pdfBase64, photoBase64;
        const self = this;
        const inputXML = document.querySelector('[name="invoiceFileXML"]');
        const inputPDF = document.querySelector('[name="invoiceFilePDF"]');
        const inputPhoto = document.querySelector('[name="invoiceFilePhoto"]');
        const numeroNota = document.querySelector('[name="detailNumeroNota"]').value;
        const serieNota = document.querySelector('[name="detailSerie"]').value;
        const verificacaoNota = document.querySelector('[name="detailVerificationCode"]').value;

        if (inputXML || inputPDF) {
            this.convertToBase64(inputPDF, function(fileLoadedEvent) {
                pdfBase64 = fileLoadedEvent.target.result.split(',')[1];
                self.convertToBase64(inputXML, function(fileLoadedEvent) {
                    self.setState({
                        tempFileStore: pdfBase64 ? pdfBase64 : xmlBase64
                    });
                    xmlBase64 = fileLoadedEvent.target.result.split(',')[1];
                    self.setInvoice(
                        pdfBase64, xmlBase64, undefined, numeroNota, serieNota, verificacaoNota
                    );
                });
            });
        } else {
            this.convertToBase64(inputPhoto, function(fileLoadedEvent) {
                photoBase64 = fileLoadedEvent.target.result.split(',')[1];
                self.setState({
                    tempFileStore: photoBase64
                });
                self.setInvoice(
                    undefined, undefined, photoBase64, numeroNota, serieNota, verificacaoNota
                );
            });
        }
    }
    
    setInvoice(pdfBase64, xmlBase64, photoBase64, numeroNota, serieNota, verificacaoNota, validations = null) {
        this.setState({
            invoiceDetailRegisterLoading: true,
            invoiceListOpen: false,
            userFeedbackRegisterErrorMessage: '',
            userFeedbackRegisterSuccessMessage: '',
        });

        let bodyFormData = new FormData();
        bodyFormData.append('v2', true);
        bodyFormData.append('id_demanda', this.state.detailIDDemanda);
        bodyFormData.append('pdf', pdfBase64 || photoBase64);
        bodyFormData.append('mensagem', 'Nota finalizada');
        bodyFormData.append('status', '2');
        bodyFormData.append('numeroNota', numeroNota);
        bodyFormData.append('verificacaoNota', verificacaoNota);
        bodyFormData.append('serieNota', serieNota);
        bodyFormData.append('emissaoNota', format(addDays(new Date(this.state.detailDataEmissao), 1), 'dd/MM/yyyy'));

        if (xmlBase64) {
            bodyFormData.append('xml', xmlBase64);
        }

        if (validations) {
            bodyFormData.append('validations', JSON.stringify(validations));
        }

        Axios.post(this.getApiUrl('backoffice/nota/finaliza'),
            bodyFormData,
            {
                headers: {
                    'Authorization': this.validateClientToken(),
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(this.handleFetchError)
            .then(result => {
                this.setState({
                    invoiceDetailOpen: false,
                    invoiceDetailRegisterLoading: false,
                    invoiceListOpen: true,
                    tempFileStore: null
                });

                this.setUIFeedback('userFeedbackRegisterSuccessMessage', 'Nota cadastrada com sucesso!', true);


                this.getInvoice(this.props.companySelected);
                this.resetDetailInput();
            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem')  !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchSetInvoice: ${error}`);

                if (errorParse.code === 401) {
                    this.props.handleLogoff();
                } else {
                    this.setState({
                        invoiceDetailOpen: true,
                        invoiceDetailLoading: false,
                        invoiceDetailRegisterLoading: false,
                        invoiceListOpen: false,
                        invoiceFilePhotoName: 'Selecione um arquivo PDF',
                        invoiceFilePDFName: 'Selecione um arquivo PDF',
                        invoiceFileXMLName: 'Selecione um arquivo XML',
                    });
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse.mensagem, true);
                }
                if (error && error.response && error.response.data) {
                    this.setState({
                        pdfValidationResponse: error.response.data
                    });
                }
            });
    }

    getInvoice(client, page = undefined) {
        const statusName = {
            1: 'Pendente',
            2: 'Emitida',
            3: 'Cancelada',
        };
        const finished = [];
        const pending = [];
        const canceled = [];
        this.setState({ invoiceListLoading: true, userFeedbackRegisterErrorMessage: '' });

        this.fetchInvoice(client, (page !== undefined) ? page : this.state.page)
            .then(this.handleFetchError)
            .then(result => {
                const filters = store.getState().invoice.filters;
                let dateArr = ['Todas'];
                let statusArr = ['Todas'];
                this.setState({ invoiceListLoading: false });

                const data = result.data;
                const paginationObject = {
                    currentPage: data.page,
                    numberOfPages: data.totalItens / data.limit,
                    totalItems: data.totalItens,
                    pageLimit: filters.itemPerPage
                };
                if (Math.round(paginationObject.numberOfPages) < paginationObject.numberOfPages) {
                    paginationObject.numberOfPages = paginationObject.numberOfPages + 1;
                }
                this.props.saveCurrentInvoices(data.itens);
                this.props.saveCurrentPagination(paginationObject);
                this.setPage(paginationObject);

                const invoices = data.itens.map(invoice => {

                    /*if (invoice.codigostatus !== 2 && invoice.codigostatus !== 3) {
                        this.handleCompanyStatusConsult(invoice.codigodemanda)
                    } */

                    if (!dateArr.includes(this.formatNFDate(invoice.datainicial))) {
                        dateArr.push(this.formatNFDate(invoice.datainicial));
                    }

                    if (!statusArr.includes(statusName[invoice.codigostatus])) {
                        statusArr.push(statusName[invoice.codigostatus]);
                    }



                    return {
                        cnpj: this.formatCNPJ(invoice.cnpj),
                        dataInicial: this.formatNFDate(invoice.datainicial) || '',
                        nome: invoice.nome || '',
                        email: invoice.email || '',
                        id: invoice.codigodemanda,
                        coddemanda: invoice.codigodemanda || '',
                        status: statusName[invoice.codigostatus],
                        codstatus: invoice.codigostatus || '',
                        mensagem: invoice.mensagem || ''
                    };

                });
                invoices.map(invoice => {
                    if (invoice.codstatus === 1) {
                        pending.push(invoice);
                    } else if (invoice.codstatus === 2) {
                        finished.push(invoice);
                    } else {
                        canceled.push(invoice);
                    }
                });
                const invoicesSorted = pending.concat(canceled).concat(finished);

                const message = invoices.length ? '' : 'Sem resultados.';

                this.setState({
                    userFeedbackMessage: message,
                    invoice: invoicesSorted,
                    filteredInvoice: invoicesSorted,
                    filteredPageItems: invoicesSorted,
                    filterDate: dateArr,
                    filterStatus: statusArr,
                });
            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem')  !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchInvoice: ${error}`);

                if (errorParse.code === 401) {
                    this.props.handleLogoff();
                } else {
                    this.setState({ invoiceListLoading: false });
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', 'Ocorreu um erro ao carregar, recarregue a página e tente novamente.', true);
                }

            });

    }

    setUIFeedback(target, message, band) {
        this.setState({[target]: message });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        if (band) { setTimeout(() => this.setState({[target]: '' }), 10000); }
    }

    getInvoiceDetail(id) {
        this.setState({
            invoiceDetailLoading: true,
            invoiceListOpen: false,
            userFeedbackRegisterErrorMessage: '',
            userFeedbackRegisterSuccessMessage: '',
            invoiceProviderOpen: true,
            invoiceHirerOpen: true,
            invoiceDistinctionOpen: true,
            invoiceTaxesOpen: true,
            invoiceOtherInfoOpen: true,
            invoiceInscricaoOpen: true,
            invoiceCredentialOpen: true,
            detailInscricaoMunicipal: '',
            detailInscricaoEstadual: '',
            detailCredentialPassword: '',
            detailCredentialTokenUser: '',
            detailCertifiedPFX: '',
        });

        this.fetchInvoiceDetail(id)
            .then(this.handleFetchError)
            .then(result => {
                this.setState({
                    detailNumeroNota: result.numero_rps,
                    detailValorNota: result.valor_total,
                    detailDataEmissao: this.formatNFDate(result.data_criacao),

                    detailPrestadorCpfCnpj: result.prestador_cpf_cnpj,
                    detailPrestadorRazao: result.prestador_nome,
                    detailPrestadorInscricao: result.prestador_inscricao_municipal,
                    detailPrestadorEndereco: result.prestador_endereco_logradouro,
                    detailPrestadorNumero: result.prestador_endereco_numero,
                    detailPrestadorComplemento: result.prestador_endereco_complemento,
                    detailPrestadorBairro: result.prestador_endereco_bairro,
                    detailPrestadorCEP: result.prestador_endereco_cep,
                    detailPrestadorEstado: result.prestador_endereco_uf,
                    detailPrestadorCidade: result.prestador_endereco_cidade,
                    detailPrestadorEmail: result.prestador_email,
                    detailPrestadorPhone: result.prestador_telefone,
                    detailPrestadorTipoCNPJ: result.prestador_tipo_pessoa,

                    detailTomadorCpfCnpj: result.tomador_cpf_cnpj,
                    detailTomadorRazao: result.tomador_nome,
                    detailTomadorInscricao: result.tomador_inscricao_municipal,
                    detailTomadorEndereco: result.tomador_endereco_logradouro,
                    detailTomadorNumero: result.tomador_endereco_numero,
                    detailTomadorComplemento: result.tomador_endereco_complemento,
                    detailTomadorBairro: result.tomador_endereco_bairro,
                    detailTomadorCEP: result.tomador_endereco_cep,
                    detailTomadorEstado: result.tomador_endereco_uf,
                    detailTomadorCidade: result.tomador_endereco_cidade,
                    detailTomadorEmail: result.tomador_email,
                    detailTomadorPhone: result.tomador_telefone,
                    detailTomadorTipoCNPJ: result.tomador_tipo_pessoa,

                    invoiceServiceInfo: result.servico_descricao,
                    invoiceServiceCNAE: result.servico_cnae,
                    invoiceServiceLC116: result.servico_item_lista_servico_l_c116,

                    detailEncargoINSS: result.valor_inss,
                    detailEncargoIRRF: result.valor_ir,
                    detailEncargoCSLL: result.valor_csll,
                    detailEncargoCOFINS: result.valor_cofins,
                    detailEncargoPISPASEP: result.valor_pis,
                    detailEncargoCodServico: result.servico_codigo_servico_municipio,
                    detailEncargoAliquota: result.servico_aliquota_iss,
                    detailEncargoISS: result.valor_iss,
                    detailEncargoMunicipioPrestacao: result.prestador_endereco_cidade,

                    invoiceOtherInfo: result.observacoes,
                    detailFileXML: result.filename_xml,
                    detailFilePDF: result.filename_pdf,
                    detailFilePhoto: result.filename_foto,


                    invoiceDetailOpen: true,
                    invoiceListOpen: false,
                    invoiceDetailLoading: false,
                    invoiceDetailReadOnly: true,
                    detailPrefeituraHomologada: '',
                });
            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem')  !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchInvoiceDetail: ${error}`);

                if (errorParse.code === 401) {
                    this.props.handleLogoff();
                } else {
                    this.setState({ invoiceListLoading: false, invoiceDetailLoading: false, invoiceListOpen: true });
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', 'Ocorreu um erro ao carregar, recarregue a página e tente novamente.', true);
                }
            });
    }

    handleFilterSearch(event) {
        const value = event.target.value;
        const matches = this.state.invoice.filter(invoice => {
            const reg = new RegExp(value.replace(/\\/g, '\\\\').replace(/[./-]/g, ''));
            const cnpj = invoice.cnpj.replace(/\\/g, '\\\\').replace(/[./-]/g, '');
            return cnpj.match(reg);
        });
        const message = matches.length ? '' : 'Sem resultados.';

        this.setState({
            filteredPageItems: matches,
            userFeedbackMessage: message,
            filterSearchValue: value
        });
    }

    handleFilterDate(event) {
        const value =  event.target.value;
        const matches = this.state.pageItems.filter(invoice => {
            const reg = value === 'Todas' ? '' :  new RegExp(value, 'i');
            return invoice.dataInicial.match(reg) && invoice.status.match(this.state.filterStatusValue === 'Todas' ? '' : this.state.filterStatusValue);
        });
        const message = matches.length ? '' : 'Sem resultados.';

        // Set matched elements
        this.setState({
            filteredPageItems: matches,
            filterDateValue: value === 'Todas' ? '' : value,
            userFeedbackMessage: message
        });
    }

    handleFilterStatus(event) {
        const value =  event.target.value;
        const matches = this.state.pageItems.filter(invoice => {
            const reg = value === 'Todas' ? '' :  new RegExp(value, 'i');
            return invoice.status.match(reg) && invoice.dataInicial.match(this.state.filterDateValue === 'Todas' ? '' : this.state.filterDateValue);
        });
        const message = matches.length ? '' : 'Sem resultados.';

        // Set matched elements
        this.setState({
            filteredPageItems: matches,
            filterStatusValue: value === 'Todas as notas' ? '' : value,
            userFeedbackMessage: message
        });
    }

    handleFilterItemPerPage(event) {
        const value = event.target.value;
        this.setState({ itemPerPage: value });
    }

    handleLabelID() {
        const isAscending = this.state.labelIdAscending;

        const matches = this.state.filteredPageItems.sort(function (a, b) {
            if (isAscending) {
                if (a.id < b.id) { return 1; }
                if (a.id > b.id) {  return -1; }
                return 0;

            } else {
                if (a.id > b.id) { return 1; }
                if (a.id < b.id) {  return -1; }
                return 0;
            }
        });

        this.setState({
            labelIdAscending: !this.state.labelIdAscending,
            filteredPageItems: matches
        });

    }

    handleLabelCNPJ() {
        const isAscending = this.state.labelCnpjAscending;

        const matches = this.state.filteredPageItems.sort(function (a, b) {
            if (isAscending) {
                if (a.cnpj < b.cnpj) { return 1; }
                if (a.cnpj > b.cnpj) {  return -1; }
                return 0;
            } else {
                if (a.cnpj > b.cnpj) { return 1; }
                if (a.cnpj < b.cnpj) {  return -1; }
                return 0;
            }
        });
        this.setState({
            labelCnpjAscending: !this.state.labelCnpjAscending,
            filteredPageItems: matches
        });
    }

    handleLabelValue() {
        const isAscending = this.state.labelValueAscending;

        const matches = this.state.filteredPageItems.sort(function (a, b) {
            if (isAscending) {
                if (parseFloat(a.valor) < parseFloat(b.valor)) { return 1; }
                if (parseFloat(a.valor) > parseFloat(b.valor)) {  return -1; }
                return 0;
            } else {
                if (parseFloat(a.valor) > parseFloat(b.valor)) { return 1; }
                if (parseFloat(a.valor) < parseFloat(b.valor)) {  return -1; }
                return 0;
            }
        });
        this.setState({
            filteredPageItems: matches,
            labelValueAscending: !this.state.labelValueAscending
        });
    }

    handleLabelEntryDate() {
        const isAscending = this.state.labelEntryDateAscending;

        const matches = this.state.filteredPageItems.sort(function (a, b) {
            const aFormatedDate = new Date(`${a.dataInicial.substring(6)}/${a.dataInicial.substring(3,5)}/${a.dataInicial.substring(0, 2)}`);
            const bFormatedDate = new Date(`${b.dataInicial.substring(6)}/${b.dataInicial.substring(3,5)}/${b.dataInicial.substring(0, 2)}`);

            if (isAscending) {
                if (aFormatedDate < bFormatedDate) { return 1; }
                if (aFormatedDate > bFormatedDate) {  return -1; }
                return 0;
            } else {
                if (aFormatedDate > bFormatedDate) { return 1; }
                if (aFormatedDate < bFormatedDate) {  return -1; }
                return 0;
            }
        });

        this.setState({
            filteredPageItems: matches,
            labelEntryDateAscending: !this.state.labelEntryDateAscending
        });
    }

    handleLabelFinalDate() {
        const isAscending = this.state.labelFinalDateAscending;

        const matches = this.state.filteredPageItems.sort(function (a, b) {
            const aFormatedDate = new Date(`${a.dataFinal.substring(6)}/${a.dataFinal.substring(3,5)}/${a.dataFinal.substring(0, 2)}`);
            const bFormatedDate = new Date(`${b.dataFinal.substring(6)}/${b.dataFinal.substring(3,5)}/${b.dataFinal.substring(0, 2)}`);

            if (isAscending) {
                if (aFormatedDate < bFormatedDate) { return 1; }
                if (aFormatedDate > bFormatedDate) {  return -1; }
                return 0;
            } else {
                if (aFormatedDate > bFormatedDate) { return 1; }
                if (aFormatedDate < bFormatedDate) {  return -1; }
                return 0;
            }
        });

        this.setState({
            filteredPageItems: matches,
            labelFinalDateAscending: !this.state.labelFinalDateAscending
        });
    }

    handleLabelStatus() {
        const isAscending = this.state.labelStatusAscending;

        const matches = this.state.filteredPageItems.sort(function (a, b) {
            if (isAscending) {
                if (a.status < b.status) { return 1; }
                if (a.status > b.status) {  return -1; }
                return 0;
            } else {
                if (a.status > b.status) { return 1; }
                if (a.status < b.status) {  return -1; }
                return 0;
            }
        });

        this.setState({
            filteredPageItems: matches,
            labelStatusAscending: !this.state.labelStatusAscending
        });
    }

    handleInvoiceDetailEditable(id, cnpj) {
        this.setState({
            invoiceDetailLoading: true,
            invoiceListOpen: false,
            detailPrestadorCpfCnpj: cnpj,
            userFeedbackRegisterErrorMessage: '',
            userFeedbackRegisterSuccessMessage: '',
            invoiceProviderOpen: true,
            invoiceHirerOpen: true,
            invoiceDistinctionOpen: true,
            invoiceTaxesOpen: true,
            invoiceOtherInfoOpen: true,
            invoiceInscricaoOpen: true,
            invoiceCredentialOpen: true,
        });

        this.fetchMEIDetail(cnpj)
            .then(this.handleFetchError)
            .then(result => {

                this.setState({
                    detailInscricaoMunicipal: result.inscricaomunicipal,
                    detailInscricaoEstadual: result.incricaoestadual,
                    detailCredentialTokenUser: result.destoken,
                    detailCredentialPassword: result.dessenha,
                    detailCertifiedPFX: result.arqacesso,
                });

                if (result.idetalao_manual === 'S') {
                    this.setState({ detailPrefeituraHomologada: false });
                } else {
                    this.setState({ detailPrefeituraHomologada: true });
                }

                this.fetchInvoiceDetail(id)
                    .then(this.handleFetchError)
                    .then(result => {
                        this.setState({
                            detailNfeId: result.nota_id_string,

                            invoiceDetailOpen: true,
                            invoiceListOpen: false,
                            invoiceDetailLoading: false,
                            invoiceDetailReadOnly: false,

                            detailNumeroNota: result.numero_rps,

                            detailValorNota: result.valor_total,

                            detailDataEmissao: new Date().toISOString().substr(0, 10),

                            detailPrestadorCpfCnpj: result.prestador_cpf_cnpj,
                            detailPrestadorRazao: result.prestador_nome,
                            detailPrestadorInscricao: result.prestador_inscricao_municipal,
                            detailPrestadorEndereco: result.prestador_endereco_logradouro,
                            detailPrestadorNumero: result.prestador_endereco_numero,
                            detailPrestadorComplemento: result.prestador_endereco_complemento,
                            detailPrestadorBairro: result.prestador_endereco_bairro,
                            detailPrestadorCEP: result.prestador_endereco_cep,
                            detailPrestadorEstado: result.prestador_endereco_uf,
                            detailPrestadorCidade: result.prestador_endereco_cidade,
                            detailPrestadorEmail: result.prestador_email,
                            detailPrestadorPhone: result.prestador_telefone,
                            detailPrestadorTipoCNPJ: result.prestador_tipo_pessoa,

                            detailTomadorCpfCnpj: result.tomador_cpf_cnpj,
                            detailTomadorRazao: result.tomador_nome,
                            detailTomadorInscricao: result.tomador_inscricao_municipal,
                            detailTomadorEndereco: result.tomador_endereco_logradouro,
                            detailTomadorNumero: result.tomador_endereco_numero,
                            detailTomadorComplemento: result.tomador_endereco_complemento,
                            detailTomadorBairro: result.tomador_endereco_bairro,
                            detailTomadorCEP: result.tomador_endereco_cep,
                            detailTomadorEstado: result.tomador_endereco_uf,
                            detailTomadorCidade: result.tomador_endereco_cidade,
                            detailTomadorEmail: result.tomador_email,
                            detailTomadorPhone: result.tomador_telefone,
                            detailTomadorTipoCNPJ: result.tomador_tipo_pessoa,

                            invoiceServiceInfo: result.servico_descricao,
                            invoiceServiceCNAE: result.servico_cnae,
                            invoiceServiceLC116: result.servico_item_lista_servico_l_c116,

                            detailEncargoINSS: result.valor_inss,
                            detailEncargoIRRF: result.valor_ir,
                            detailEncargoCSLL: result.valor_csll,
                            detailEncargoCOFINS: result.valor_cofins,
                            detailEncargoPISPASEP: result.valor_pis,
                            detailEncargoCodServico: result.servico_codigo_servico_municipio,
                            detailEncargoAliquota: result.servico_aliquota_iss,
                            detailEncargoISS: result.valor_iss,
                            detailEncargoMunicipioPrestacao: result.prestador_endereco_cidade,

                            invoiceOtherInfo: result.observacoes,
                        });
                    })
                    .catch(error => {
                        const errorParse = error.message.indexOf('mensagem')  !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                        console.log(`Error at fetchInvoiceDetail: ${error}`);

                        if (errorParse.code === 401) {
                            this.props.handleLogoff();
                        } else {
                            this.setState({ invoiceListLoading: false, invoiceDetailLoading: false, invoiceListOpen: true });
                            this.setUIFeedback('userFeedbackRegisterErrorMessage', 'Ocorreu um erro ao carregar, recarregue a página e tente novamente.', true);
                        }
                    });

            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem')  !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchMEIDetail: ${error}`);

                if (errorParse.code === 401) {
                    this.props.handleLogoff();
                } else {
                    this.setState({ invoiceListLoading: false, invoiceDetailLoading: false, invoiceListOpen: true });
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse.mensagem || 'Ocorreu um erro ao carregar, recarregue a página e tente novamente.', true);
                }
            });
    }

    handleShowInvoiceDetail(event) {
        const id = event.currentTarget.parentNode.getAttribute('data-coddemanda');
        const status = event.currentTarget.parentNode.getAttribute('data-status');
        const cnpj = event.currentTarget.parentNode.getAttribute('data-cnpj');
        const message = event.currentTarget.parentNode.getAttribute('data-mensagem');

        this.setState({
            detailIDDemanda: id,
            dicasMEIMessage: message,
        });

        (status === 'Emitida' || status === 'Nota Duplicada') ? this.getInvoiceDetail(id, cnpj) : this.handleInvoiceDetailEditable(id, cnpj);
    }

    handleInvoiceDetailSection(event) {
        const stateName = event.currentTarget.getAttribute('data-state');
        this.setState({ [stateName]: !(this.state[stateName]) });
    }

    handleInvoiceHirer() {
        this.setState({ invoiceHirerOpen: !this.state.invoiceHirerOpen });
    }

    handleInvoiceTaxes() {
        this.setState({ invoiceTaxesOpen: !this.state.invoiceTaxesOpen });
    }

    handleInvoiceAttachment() {
        this.setState({ invoiceAttachmentOpen: !this.state.invoiceAttachmentOpen });
    }

    handleInvoiceDetailClose() {
        this.setState({  closeInvoiceDetailOpen: !this.state.closeInvoiceDetailOpen });
    }

    handleDetailCloseButton() {
        this.setState({
            invoiceDetailOpen: true,
            closeInvoiceDetailOpen: false,
            userFeedbackRegisterErrorMessage: '',
            userFeedbackRegisterSuccessMessage: '',
        });
    }

    handleCloseWithoutSave() {
        this.setState({
            invoiceDetailOpen: false,
            closeInvoiceDetailOpen: false,
            invoiceListOpen: true,
            userFeedbackRegisterErrorMessage: '',
            userFeedbackRegisterSuccessMessage: '',
        });
        this.resetDetailInput();
    }

    handleCloseAndSave() {
        const button = document.querySelector('.invoice-detail button[type="submit"]');

        if (this.state.invoiceDetailReadOnly) {
            return this.setState({
                invoiceDetailOpen: false,
                invoiceListOpen: true,
                closeInvoiceDetailOpen: false,
            });
        }

        this.handleDetailCloseButton();

        return button ? button.click() : this.handleDetailCloseButton();
    }

    handleCopyToClipboard(event) {
        const element = event.target;

        element.select();
        document.execCommand('copy');
        element.parentNode.classList.add('label_copied');

        setTimeout(function() {
            element.parentNode.classList.remove('label_copied');
        }, 800);

    }

    maskCurrency(value) {
        return value.replace('R$','').trim();
    }

    validateCurrency(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        const maskedValue = this.maskCurrency(value);
        this.setState({ [name]: maskedValue });
    }

    validateCNPJ(event) {
        const target = event.target;
        const name = target.name;
        const value = event.target.value.replace(/\D/g, '');
        const maskedValue = this.maskCnpj(value);
        this.setState({ [name]: maskedValue });
    }

    validateCpfOrCnpj(event) {
        const target = event.target;
        const name = target.name;
        const value = event.target.value.replace(/\D/g, '');
        const maskedValue = value.length <= 11 ? this.maskCpf(value) : this.maskCnpj(value);
        this.setState({ [name]: maskedValue });
    }

    validatePhone(event) {
        const target = event.target;
        const name = target.name;
        const value = event.target.value.replace(/\D/g, '');
        const maskedValue = this.maskPhone(value);
        this.setState({ [name]: maskedValue });
    }

    maskPhone(number) {
        number = number.replace(/\D/g, '');
        number = number.replace(/^(\d{2})(\d)/g,'($1) $2');
        number = number.replace(/(\d)(\d{4})$/,'$1-$2');
        return number;
    }

    maskCnpj(value) {
        value = value.replace(/^(\d{2})(\d)/,'$1.$2');
        //Coloca ponto entre o quinto e o sexto dígitos
        value = value.replace(/^(\d{2})\.(\d{3})(\d)/,'$1.$2.$3');
        //Coloca uma barra entre o oitavo e o nono dígitos
        value = value.replace(/\.(\d{3})(\d)/,'.$1/$2');
        //Coloca um hífen depois do bloco de quatro dígitos
        value = value.replace(/(\d{4})(\d)/,'$1-$2');
        return value;
    }

    maskCpf(value) {
        //Coloca um ponto entre o terceiro e o quarto dígitos
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        //Coloca um hífen entre o terceiro e o quarto dígitos
        value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        return value;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        return this.setState({ [name]: value });
    }

    handleInputFileChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fileCaption = `${name}Name`;
        const fileName = target.files[0].name;
        const accept = target.getAttribute('data-accept');
        const errorName = `${name}Error`;

        if (fileName.match(accept)) {
            this.setState({ [errorName]: '' });
            target.setCustomValidity('');
        } else {
            this.setState({ [errorName]: 'Formato inválido!'});
            target.setCustomValidity('Formato inválido!');
        }

        this.setState({
            [fileCaption]: fileName,
            [name]: value
        });
    }

    async handlePageChange(page) {
        if (this.state.page !== page) {
            await this.setState({
                page: page
            });
            this.getInvoice(this.props.companySelected, page);
        }
    }

    async setPage(paginationObject) {
        const items = paginationObject.totalItems;
        const pageSize = paginationObject.pageLimit;
        const page = this.state.page ? this.state.page + 1 : paginationObject.currentPage;
        let pager = {};

        if (page > paginationObject.totalPages) {
            return;
        }

        // get new pager object for specified page
        pager = await this.getPager(items, page, pageSize);

        // update state
        this.props.savePaginationList(pager);
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || Number(this.props.itemPerPage);

        // calculate total pages
        let totalPages = totalItems / pageSize;

        if (Math.round(totalPages) < totalPages) {
            totalPages = Math.floor(totalPages + 1);
        }

        let startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // create an array of pages to ng-repeat in the pager control
        const pages = [...Array(Math.round((endPage + 1) - startPage)).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            pages: pages
        };
    }

    resetDetailInput() {
        this.setState({
            detailIDDemanda: '',
            invoiceServiceInfo: '',
            invoiceOtherInfo: '',
            invoiceFilePDF: '',
            invoiceFileXML: '',
            invoiceFilePhoto: '',
            detailNumeroNota: '',
            detailValorNota: '',
            detailDataEmissao: '',
            detailPrestadorCpfCnpj: '',
            detailPrestadorRazao: '',
            detailPrestadorInscricao: '',
            detailPrestadorEndereco: '',
            detailPrestadorEstado: '',
            detailPrestadorCidade: '',
            detailPrestadorEmail: '',
            detailPrestadorPhone: '',
            detailPrestadorTipoCNPJ: '',
            detailTomadorCpfCnpj: '',
            detailTomadorRazao: '',
            detailTomadorInscricao: '',
            detailTomadorEndereco: '',
            detailTomadorEstado: '',
            detailTomadorCidade: '',
            detailTomadorEmail: '',
            detailTomadorPhone: '',
            detailTomadorTipoCNPJ: '',
            detailEncargoINSS: '',
            detailEncargoIRRF: '',
            detailEncargoCSLL: '',
            detailEncargoCOFINS: '',
            detailEncargoPISPASEP: '',
            detailEncargoCodServico: '',
            detailEncargoAliquota: '',
            detailEncargoISS: '',
            detailEncargoMunicipioPrestacao: '',
            invoiceFilePhotoName: 'Selecione um arquivo PDF',
            invoiceFilePDFName: 'Selecione um arquivo PDF',
            invoiceFileXMLName: 'Selecione um arquivo XML',
            invoiceFilePhotoError: '',
            invoiceFilePDFError: '',
            invoiceFileXMLError: '',
            invoiceServiceCNAE: '',
            invoiceServiceLC116: '',
            detailPrestadorNumero: '',
            detailPrestadorComplemento: '',
            detailPrestadorBairro: '',
            detailPrestadorCEP: '',
            detailTomadorNumero: '',
            detailTomadorComplemento: '',
            detailTomadorBairro: '',
            detailTomadorCEP: '',
            dicasMEIMessage: '',
            detailInscricaoMunicipal: '',
            detailInscricaoEstadual: '',
            detailCredentialTokenUser: '',
            detailCredentialPassword: '',
            detailCertifiedPFX: '',
        });
    }

    setPdfValidationRequest(data) {
        this.setState({
            pdfValidationResponse: null
        });

        const self = this;
        const pdfBase64 = this.state.tempFileStore;
        const serieNota = document.querySelector('[name="detailSerie"]').value;
        const numeroNota = document.querySelector('[name="detailNumeroNota"]').value;
        const verificacaoNota = document.querySelector('[name="detailVerificationCode"]').value;

        if (pdfBase64) {
            self.setInvoice(
                pdfBase64, undefined, undefined, numeroNota, serieNota, verificacaoNota, data
            );
        }
    }
    
    clearPdfValidationResponse() {
        this.setState({
            tempFileStore: null,
            pdfValidationResponse: null
        });
    }

    render() {
        return (<Invoice
            {...this.state}
            setPdfValitationRequest={this.setPdfValidationRequest}
            clearPdfValidationResponse={this.clearPdfValidationResponse}
            pdfValidationRequest={this.state.pdfValidationRequest}
            pdfValidationResponse={this.state.pdfValidationResponse}
            page={this.state.page}
            setPage={this.setPage}
            getApiUrl={this.props.getApiUrl}
            handleLabelID={this.handleLabelID}
            handleLabelCNPJ={this.handleLabelCNPJ}
            handleLabelValue={this.handleLabelValue}
            handleLabelEntryDate={this.handleLabelEntryDate}
            handleLabelFinalDate={this.handleLabelFinalDate}
            handleLabelStatus={this.handleLabelStatus}
            handleFilterSearch={this.handleFilterSearch}
            handleFilterDate={this.handleFilterDate}
            handleFilterStatus={this.handleFilterStatus}
            detailIDDemanda={this.state.detailIDDemanda}
            handleFilterItemPerPage={this.handleFilterItemPerPage}
            handleInvoiceDetailClose={this.handleInvoiceDetailClose}
            handleShowInvoiceDetail={this.handleShowInvoiceDetail}
            handleCopyToClipboard={this.handleCopyToClipboard}
            handleCloseWithoutSave={this.handleCloseWithoutSave}
            handleCloseAndSave={this.handleCloseAndSave}
            validateCpfOrCnpj={this.validateCpfOrCnpj}
            handlePageChange={this.handlePageChange}
            handleInputChange={this.handleInputChange}
            handleInvoiceDetailSection={this.handleInvoiceDetailSection}
            handleDetailCloseButton={this.handleDetailCloseButton}
            validateCurrency={this.validateCurrency}
            validateCNPJ={this.validateCNPJ}
            validatePhone={this.validatePhone}
            setInvoice={this.setInvoice}
            handleInputFileChange={this.handleInputFileChange}
            handleSetInvoice={this.handleSetInvoice}
        />);
    }

}

const mapStateToProps = state => ({
    filters: state.invoice.filters,
    currentPage: state.invoice.currentPage,
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveCurrentInvoices: setInvoices,
        saveCurrentPagination: setPagination,
        savePaginationList: setPaginationList,
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(InvoiceContainer);
