import React, {useState, useEffect} from 'react';
import useDebounce from './use-debounce';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import {setFilters} from '../../../actions/invoice-actions';

const Filter = (props) => {
    const {
        filterItemPerPage,
    } = props;

    const [cnpj, setCnpj] = useState('');

    const debouncedCnpj = useDebounce(cnpj.replace(/\D/g, ''), 500);

    useEffect(
        () => {
            if (props.filters.cnpj !== '' && debouncedCnpj === '' || debouncedCnpj) {
                setTimeout(async () => {
                    await props.saveFilters({
                        ...props.filters,
                        cnpj: debouncedCnpj
                    });
                    await props.handlePageChange(0);
                }, 500);
            }
        },[debouncedCnpj]
    );

    const handleItemsChange = async (value) => {
        await props.saveFilters({
            ...props.filters,
            itemPerPage: value
        });
        await props.handlePageChange(0);
    };

    const handleStatusChange = async (value) => {
        await props.saveFilters({
            ...props.filters,
            status: value
        });
        await props.handlePageChange(0);
    };

    return (<div className='filter'>
        <form className='filter__form filter__form_invoice'>
            <label className='label'>
                <input
                    className='input input_search'
                    type='search'
                    value={cnpj}
                    name="search"
                    placeholder='Digite aqui'
                    onChange={(e) => setCnpj(e.target.value)}
                />
                <p className='label__p label__p_float'>Busca Notas</p>
            </label>
            <label className='label'>
                <select
                    className='input'
                    value={props.filters.status}
                    name='status'
                    onChange={(e) => handleStatusChange(e.target.value)}
                >
                    <option value="0">Todos</option>
                    <option value="1">Pendente</option>
                    <option value="2">Emitida</option>
                    <option value="3">Cancelada</option>
                    <option value="4">Sem Talão</option>
                    <option value="5">Prefeitura Indisponível</option>
                    <option value="6">Ação Necessária do Agente</option>
                    <option value="7">Pendente AIDF</option>
                    <option value="8">Nota Duplicada</option>
                </select>
                <p className='label__p label__p_float'>Status da Nota</p>
            </label>
            <label className='label'>
                <select
                    className='input'
                    name="itemPerPage"
                    value={props.filters.itemPerPage}
                    onChange={(e) => handleItemsChange(e.target.value)}
                >
                    {filterItemPerPage && filterItemPerPage.map((page, index) => (
                        <option value={page} key={index}> {page} </option>
                    ))}
                </select>
                <p className='label__p label__p_float'>Itens por pg</p>
            </label>
        </form>
    </div>);
};

const mapStateToProps = state => ({
    filters: state.invoice.filters
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveFilters: setFilters,
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(Filter);
