import React, { useState, useEffect } from 'react';
import TextValidator from '../../../TextValidator/TextValidator';

export const Credentials = (props) => {

    const [fileName, setFileName] = useState('');

    useEffect(() => {
        if (props.fileName) {
            setFileName(props.fileName);
        }
        if (props.filePassword) {
            props.saveCredential({
                ...props.credentials,
                'filePassword': props.filePassword
            });
        }

        return () => {
            props.saveCredential({
                ...props.credentials,
                'filePassword': ''
            });
        };
    }, []);

    const handleChanges = (event) => {
        const { name, value } = event.target;
        props.saveCredential({
            ...props.credentials,
            [name]: value
        });
    };

    const handleFile = () => {
        const fileInput = document.getElementById('credentialFile');
        if (fileInput.files.length > 0) {
            const fileName = fileInput.files[0].name;
            setFileName(fileName);
            props.saveCredential({
                ...props.credentials,
                arquivoNome: fileName,
                arquivoBase64: fileInput.files[0],
            });
        } else {
            setFileName('');
            props.saveCredential({
                ...props.credentials,
                arquivoNome: '',
                arquivoBase64: '',
            });
        }
    };

    return (
        <div className='company-detail__section-content credential_header_spacer'>
            {(!props.isHomologated) &&
                (<div className='company-register__row'>
                    <span className={'invalid_city'}>
                        Essa prefeitura não é homologada.
                    </span>
                </div>)}
            {(props.isHomologated && props.credentials.type === 0) &&
                (<div className='company-register__row'>
                    <span className={'unrequired_credentials'}>
                        Essa prefeitura é homologada, porem não necessita de autenticação para emissão.
                    </span>
                </div>)}
            {(props.isHomologated && props.credentials.type === 2) &&
                (<div className='company-register__row'>
                    <label className='label label_credentials-login'>
                        <TextValidator
                            className='input'
                            name='user'
                            type='text'
                            value={props.credentials.user}
                            onChange={(e) => handleChanges(e)}
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Usuário de acesso ao site da Prefeitura</p>
                    </label>
                    <label className='label label_credentials-login'>
                        <TextValidator
                            className='input'
                            name='password'
                            type='text'
                            value={props.credentials.password}
                            onChange={(e) => handleChanges(e)}
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Senha site Prefeitura</p>
                    </label>
                </div>)}
            {(props.isHomologated && props.credentials.type === 3) &&
                (<div className='company-register__row'>
                    <label className='label label_credentials-token'>
                        <TextValidator
                            className='input'
                            name='token'
                            value={props.credentials.token}
                            onChange={(e) => handleChanges(e)}
                            type='text'
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Token site prefeitura</p>
                    </label>
                </div>)}
            {(props.isHomologated && props.credentials.type === 4) &&
                (<div className='company-register__row'>
                    <label className='label label_credentials-secretPhrase'>
                        <TextValidator
                            className='input'
                            name='secretPhrase'
                            type='text'
                            value={props.credentials.secretPhrase}
                            onChange={(e) => handleChanges(e)}
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Frase secreta site Prefeitura</p>
                    </label>
                    <label className='label label_credentials-login'>
                        <TextValidator
                            className='input'
                            name='password'
                            type='text'
                            value={props.credentials.password}
                            onChange={(e) => handleChanges(e)}
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Senha site Prefeitura</p>
                    </label>
                </div>)}
            {(props.isHomologated && props.credentials.type === 5) &&
                (<div className='company-register__row'>
                    <label className='label label_credentials-login'>
                        <TextValidator
                            className='input'
                            name='user'
                            type='text'
                            value={props.credentials.user}
                            onChange={(e) => handleChanges(e)}
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Usuário site Prefeitura</p>
                    </label>
                    <label className='label label_credentials-login-token'>
                        <TextValidator
                            className='input'
                            name='password'
                            type='text'
                            value={props.credentials.password}
                            onChange={(e) => handleChanges(e)}
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Senha site Prefeitura</p>
                    </label>
                    <label className='label label_credentials-token'>
                        <TextValidator
                            className='input'
                            name='token'
                            value={props.credentials.token}
                            onChange={(e) => handleChanges(e)}
                            type='text'
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Token site prefeitura</p>
                    </label>
                </div>)}
            {(props.isHomologated && props.credentials.type === 1) &&
                (<div className='company-register__row'>
                    <label className='label label_certified-file'>
                        <span className='input input_file-custom'>
                            {(!props.isEditing && !props.fileName) ? 'Clique em editar dados para alterar' :
                                fileName !== '' ? fileName : 'Faça upload do seu certificado.'
                            }
                        </span>
                        <p className='label__p label__p_float'>Upload certificado </p>
                        <input
                            className='input_file'
                            name='credentialFile'
                            id='credentialFile'
                            type='file'
                            onChange={() => handleFile()}
                            disabled={!props.isEditing}
                        />
                    </label>
                    <label className='label label_certified-password'>
                        <TextValidator
                            className='input'
                            name='filePassword'
                            type='text'
                            value={props.credentials.filePassword}
                            onChange={(e) => handleChanges(e)}
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Senha do certificado</p>
                    </label>
                </div>)}
            {(props.isHomologated && props.authType === 2 && props.credentials.type !== 1) && (
                <div className='company-register__row'>
                    <label className='label label_certified-file'>
                        <span className='input input_file-custom'>
                            {!props.isEditing ? 'Clique em editar dados para alterar' :
                                fileName !== '' ? fileName : 'Faça upload do seu certificado.'
                            }
                        </span>
                        <p className='label__p label__p_float'>Upload certificado </p>
                        <input
                            className='input_file'
                            name='credentialFile'
                            id='credentialFile'
                            type='file'
                            onChange={() => handleFile()}
                            disabled={!props.isEditing}
                        />
                    </label>
                    <label className='label label_certified-password'>
                        <TextValidator
                            className='input'
                            name='filePassword'
                            type='text'
                            value={props.credentials.filePassword}
                            onChange={(e) => handleChanges(e)}
                            readOnly={!props.isEditing}
                        />
                        <p className='label__p label__p_float'>Senha do certificado</p>
                    </label>
                </div>
            )}
            {(props.fileExpiration && props.fileExpiration !== '') && (
                <div className={'cert_date'}>
                    <p className={'label__p cert_date_text'}>Certificado válido até {props.fileExpiration}</p>
                </div>
            )}
        </div>
    );
};

export default Credentials;
