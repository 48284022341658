import Axios from 'axios';
import Company from './Company';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {
    setCompanies,
    setCurrentCompany,
    setFilters,
    setPagination,
    setPaginationList
} from '../../actions/company-actions';

import store from '../../Store';

class CompanyContainer extends Component {
    constructor(props) {

        super(props);

        this.state = {
            page: 0,
            companyList: [],
            companyListLoading: false,
            companyListFiltered: [],
            pageItems: [],
            filteredPageItems: [],
            filterSearch: '',
            cnpjAscending: true,
            nomeAscending: true,
            emailAscending: true,
            idAscending: true,
            datainicialAscending: true,
            codstatusAscending: true,
            registerOpen: false,
            companyDataOpen: true,
            companyCredentialsOpen: false,
            companyCNPJ: '',
            companyCNPJDisabled: false,
            companyCNPJValid: false,
            companyInscricaoMunicipal: '',
            companyInscricaoMunicipalRequired: true,
            companyInscricaoEstadual: '',
            companyStatus: '',
            companyCodDemanda: '',
            companyRazaoSocial: '',
            companyNomeFantasia: '',
            companyEmail: '',
            companyPhone: '',
            companyUF: '',
            companyCity: '',
            companyCredentialsUser: '',
            companyCredentialsPassword: '',
            companyCredentialsToken: '',
            companyCredentialsCertifiedPassword: '',
            companyCredentialsCertifiedFileName: 'Selecione um arquivo',
            companyCredentialsCertifiedFileError: '',
            companyAssinaturaDigital: '',
            companyCNPJLoading: false,
            companyCredential: '',
            secretPhrasePasswordCredencial: false,
            closeRegisterOpen: false,
            companyRegisterMessage: '',
            userFeedbackRegisterErrorMessage: '',
            userFeedbackRegisterSuccessMessage: '',
            registerLoading: false,
            insricaoMunicipalHelp: '',
            stopCNPJConsult: false,
            companyRegisterReadonly: false,
            companyMensagem: '',
            companySMU: '',
            companyNumeroLote: '',
            companySerie: '',
            companyNumeroRPS: '',
            companyUsaRegimeEspecialTributacao: '',
            companyRegimesEspecialTributacao: '',
            companyRegimesEspecialTributacaoItens: '',
            companyUsaCodigoServicoMunicipal: '',
            companyUsaCNAE: '',
            companyCNAEItens: '',
            companyCNAE: '',
            companyUsaDescricaoServico: '',
            companyLC116List: [],
            itemListaServicoLC116: '',
            companyCodigoServicoMunicipal: '',
            canRegisterEnotas: '',
            cacheFree: false,
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleLabel = this.handleLabel.bind(this);
        this.handleCNPJ = this.handleCNPJ.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getCNPJ = this.getCNPJ.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.setMeiSituation = this.setMeiSituation.bind(this);
        this.handleCloseWithoutSave = this.handleCloseWithoutSave.bind(this);
        this.handleCloseAndSave = this.handleCloseAndSave.bind(this);
        this.handleRegisterCloseButton = this.handleRegisterCloseButton.bind(this);
        this.setCompany = this.setCompany.bind(this);
        this.handleStateToggle = this.handleStateToggle.bind(this);
        this.handleInputFileChange = this.handleInputFileChange.bind(this);
        this.handleShowCompanyDetail = this.handleShowCompanyDetail.bind(this);
        this.handleCloseRegister = this.handleCloseRegister.bind(this);
        this.handleCNAEChange = this.handleCNAEChange.bind(this);
        this.handleSetCompany = this.handleSetCompany.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
        this.handleJSONResponse = this.handleJSONResponse.bind(this);
        this.handleTextResponse = this.handleTextResponse.bind(this);
        this.getCompanyTalao = this.getCompanyTalao.bind(this);
        this.setCompanyTomador = this.setCompanyTomador.bind(this);
        this.fetchTestReceipt = this.fetchTestReceipt.bind(this);
        this.verifyReceiptTest = this.verifyReceiptTest.bind(this);
        this.getNfeJson = this.getNfeJson.bind(this);
        this.cancelReceipt = this.cancelReceipt.bind(this);
        this.fetchCompany = this.fetchCompany.bind(this);
        this.setPage = this.setPage.bind(this);
        this.getPager = this.getPager.bind(this);
        this.handleCleanCnpjConsult = this.handleCleanCnpjConsult.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getCompany(nextProps.companySelected);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        this.getCompany(this.props.companySelected);
    }

    validateClientToken() {
        const expiresIn = localStorage.getItem('clientToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('clientToken');
            localStorage.removeItem('clientToken_expiration');
            localStorage.setItem('loggedIn', false);
            this.setState({loggedIn: false});
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('clientToken');
    };

    validateDicasMEIToken() {
        const expiresIn = localStorage.getItem('dicasMEIToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('dicasMEIToken');
            localStorage.removeItem('dicasMEIToken_expiration');
            localStorage.setItem('loggedIn', false);
            this.setState({loggedIn: false});
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('dicasMEIToken');
    };

    async fetchCompany(client, page) {
        this.setState({
            companyListLoading: true
        });
        const filters = store.getState().company.filters;
        const response = await Axios.get(
            this.props.getApiUrl(
                'backoffice/demanda?' +
                `cliente=${client.origem}` +
                '&servico=2' +
                `&page=${page}` +
                `&limit=${filters.itemPerPage}` +
                `&status=${filters.status}` +
                `&cnpj=${filters.cnpj.replace(/\D/g, '')}`
            ),
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': this.validateClientToken()
                }
            });
        const data = await response.data;
        const paginationObject = {
            currentPage: data.page,
            numberOfPages: data.totalItens / data.limit,
            totalItems: data.totalItens,
            pageLimit: filters.itemPerPage
        };
        if (Math.round(paginationObject.numberOfPages) < paginationObject.numberOfPages) {
            paginationObject.numberOfPages = paginationObject.numberOfPages + 1;
        }
        this.props.saveCurrentCompanies(data.itens);
        this.props.saveCurrentPagination(paginationObject);
        await this.setPage(paginationObject);
        this.setState({
            companyListLoading: false
        });
        return data;
    }

    async fetchCNPJ(cnpj) {
        const response = await fetch(this.props.getApiUrl('cnpj/solicita'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            },
            body: JSON.stringify({
                CNPJ: this.formatCNPJ(cnpj, true),
                tp_consulta: 'X',
                origem: 'BACKOFFICE',
                force: this.state.cacheFree,
            })
        });
        const data = await response.json();
        return data;
    }

    async fetchCNPJData(id) {
        const response = await fetch(this.props.getApiUrl('cnpj/consulta'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            },
            body: JSON.stringify({
                TransactionID: id,
            })
        });
        const data = await response.json();
        return data;
    }

    async fetchCompanyCredentials(uf, city) {
        const response = await (await (fetch(this.props.getApiUrl(`notasinternas/prefeitura/detalhes?uf=${uf}&cidade=${city}`), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            }
        })));
        const data = await response.json();
        if (response.status !== 200) throw new Error(JSON.stringify(data));
        return data;
    }

    async fetchCompanyRegister(certifiedBase64 = undefined, internalFileName = undefined) {
        let codLC116;
        this.state.companyLC116List.map(item => {
            if (item.descricao === this.state.companyLC116) codLC116 = item.codigo;
        });

        const response = await (await fetch(this.props.getApiUrl('backoffice/mei/cadastrar'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateClientToken()
            },
            body: JSON.stringify({
                id_demanda: this.state.companyCodDemanda,
                cnpj: this.formatCNPJ(this.state.companyCNPJ, true),
                email: this.state.companyEmail,
                telefone: this.state.companyPhone,
                inscricaoestadual: this.state.companyInscricaoEstadual,
                inscricaomunicipal: this.state.companyInscricaoMunicipal,
                senha: this.state.companyCredentialsPassword || this.state.companyCredentialsCertifiedPassword,
                tokenacesso: this.state.companyCredentialsUser || this.state.companyCredentialsToken,
                arquivoacesso: certifiedBase64 ? certifiedBase64 : undefined,
                arquivoacesso_nome: internalFileName ? internalFileName : undefined,
                emissao_habilitada: this.state.companyInvoiceEnabled,
                codigo_servico_municipio: this.state.companyCodigoServicoMunicipal,
                item_lista_servicolc116: codLC116 ? this.formatAllSpecialCharacters(codLC116) : undefined,
            })
        }));
        const data = await response.json();
        return data;
    }

    async fetchCompanyStatusChange(id) {
        const response = await fetch(this.props.getApiUrl(`backoffice/demanda/${id}`), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateClientToken()
            }
        });
        const data = await response.json();
        return data;
    }

    async fetchCompanyDetail(cnpj) {
        const response = await fetch(this.props.getApiUrl(`backoffice/mei/${this.formatCNPJ(cnpj, true)}`), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateClientToken()
            }
        });
        const data = await response.json();
        return data;
    }

    async fetchCityIBGECode(uf, city) {
        const response = await fetch(this.props.getApiUrl(`endereco/cidade/ibge?UF=${uf}&Cidade=${city}`), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            }
        });
        const data = await response.json();
        return data;
    }

    async fetchCompanySMU(ibgeCod) {
        const response = await fetch(this.props.getApiUrl(`notasinternas/servico/smu?codigoIbge=${ibgeCod}`), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            }
        });
        const data = await response.json();
        return data;
    }

    async fetchCompanyLC116(cnae) {
        const response = await fetch(this.props.getApiUrl(`lc116/listar/${cnae}`), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            }
        });
        const data = await response.json();
        return data;
    }

    async fetchCompanyEnotas() {
        let codLC116;
        let companyCNAECod;
        let senha = this.state.companyCredentialsPassword || undefined;
        let token = this.state.companyCredentialsToken || undefined;

        // 14/01/2020 - Em são paulo a senha é gravada no campo "token"
        if (this.state.companyUF == 'SP' && this.state.companyCity == 'SAO PAULO') {
            token = senha;
        }

        this.state.companyCNAEItens.map(item => {
            if (item.descricao === this.state.companyLC116) codLC116 = item.codigo;
        });

        this.state.companyCNAEItens.map(item => {
            if (item.descricao === this.state.companyCNAE) companyCNAECod = item.codigo;
        });

        return fetch(this.props.getApiUrl('notasinternas/empresa/cadastrar'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            },
            body: JSON.stringify({
                cnpj: this.formatCNPJ(this.state.companyCNPJ, true),
                inscricaoMunicipal: this.state.companyInscricaoMunicipal || undefined,
                inscricaoEstadual: this.state.companyInscricaoEstadual || undefined,
                razaoSocial: this.state.companyRazaoSocial,
                nomeFantasia: this.state.companyNomeFantasia,
                email: this.state.companyEmail,
                telefoneComercial: this.formatAllSpecialCharacters(this.state.companyPhone),
                regimeEspecialTributacao: this.state.companyRegimesEspecialTributacao || undefined,
                itemListaServicoLC116: codLC116 ? this.formatAllSpecialCharacters(codLC116) : undefined,
                codigoServicoMunicipal: this.state.companyCodigoServicoMunicipal || undefined,
                cnae: companyCNAECod || undefined,
                descricaoServico: this.state.companyLC116 || undefined,
                endereco: {
                    uf: this.state.companyUF,
                    cidade: this.state.companyCity,
                    logradouro: this.state.companyAddressStreet,
                    numero: this.state.companyAddressNumber,
                    complemento: this.state.companyAddressComplement,
                    bairro: this.state.companyAddressDistrict,
                    cep: this.state.companyAddressZipCode
                },
                configuracoesNFSeHomologacao: {
                    sequencialNFe: Number(this.state.companyNumeroRPS) || undefined,
                    serieNFe: this.state.companySerie || undefined,
                    sequencialLoteNFe: Number(this.state.companyNumeroLote) || undefined,
                    usuarioAcessoProvedor: this.state.companyCredentialsUser || undefined,
                    senhaAcessoProvedor: senha,
                    tokenAcessoProvedor: token
                },
                configuracoesNFSeProducao: {
                    sequencialNFe: Number(this.state.companyNumeroRPS) || undefined,
                    serieNFe: this.state.companySerie || undefined,
                    sequencialLoteNFe: Number(this.state.companyNumeroLote) || undefined,
                    usuarioAcessoProvedor: this.state.companyCredentialsUser || undefined,
                    senhaAcessoProvedor: senha,
                    tokenAcessoProvedor: token
                },
            })
        });
    }

    async fetchTestReceipt() {
        let {companySelected} = this.props;

        return Axios.post(this.props.getApiUrl('nota/envio'),
            {
                Valor: '1.00',
                IDExterno: '',
                DadosComplementares: '',
                ServicoDescricao: 'NOTA FISCAL TESTE',
                Origem: companySelected.origem,
                CodServico: companySelected.servico,
                CNPJPrestador: this.formatCNPJ(this.state.companyCNPJ, true),
                CNPJTomador: companySelected.cnpj
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': this.validateDicasMEIToken()
                }
            });
    }

    async verifyReceiptTest(transaction_id) {
        return Axios.post(this.props.getApiUrl('nota/consulta'),
            {
                TransactionID: transaction_id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': this.validateDicasMEIToken()
                }
            });
    }

    async getNfeJson(transaction_id) {
        return Axios.get(this.props.getApiUrl(`backoffice/notaemitida/${transaction_id}`), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateClientToken()
            }
        });
    }

    async setMeiSituation(currentSituation = true) {
        return Axios.post(this.props.getApiUrl('mei/apto'), {
            'cnpj': this.formatCNPJ(this.state.companyCNPJ, true),
            'apto': currentSituation ? 'S' : 'N'
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            }
        });
    }

    async cancelReceipt(transaction_id) {
        return Axios.post(this.props.getApiUrl('nota/cancela'),
            {
                mensagem: '',
                TransactionID: transaction_id,
                motivo: 'Cancelamento de nota teste',
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': this.validateDicasMEIToken()
                }
            });
    }

    async fetchCompanyCertifiedEnotas(id) {
        const inputCertified = document.querySelector('[name="companyCredentialsCertifiedFile"]');
        const formData = new FormData();
        formData.append('empresaId', id);
        formData.append('arquivo', inputCertified.files[0]);
        formData.append('senha', this.state.companyCredentialsCertifiedPassword);

        return fetch(this.props.getApiUrl('notasinternas/empresa/vincular/certificado'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            },
            body: formData
        });
    }

    async fetchRegisterTomador(company) {
        const cnpj = company.cnpj;
        const inscricaoMunicipal = company.inscMunicipal;

        return fetch(this.props.getApiUrl('tomador/cadastrar'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            },
            body: JSON.stringify({
                cnpj,
                email: this.state.companyEmail,
                inscricaoMunicipal,
                telefone: this.state.companyPhone
            })
        });
    }

    handleResponse(response) {
        const contentType = response.headers.get('content-type');

        if (contentType.indexOf('application/json') >= 0) {
            return this.handleJSONResponse(response);
        } else if (contentType.indexOf('text/html') >= 0) {
            return this.handleTextResponse(response);
        } else {
            throw new Error(`Sorry, content-type ${contentType} not supported`);
        }

    }

    handleJSONResponse(response) {
        return response.json()
            .then(json => {
                if (response.ok) {
                    return json;
                } else {
                    return Promise.reject(Object.assign({}, {
                        status: response.status,
                        statusText: response.statusText,
                        errorJSON: json
                    }));
                }
            });
    }

    handleTextResponse(response) {
        return response.text()
            .then(text => {
                if (response.ok) {
                    return text;
                } else {
                    return Promise.reject({
                        status: response.status,
                        statusText: response.statusText,
                        errorText: text.indexOf('<html>') !== -1 ? 'Ocorreu um erro em nosso servidor, por favor recarregue a página.' : text
                    });
                }
            });
    }

    handleFetchError(response) {
        if (response.code || response.codigo) throw new Error(JSON.stringify(response));
        return response;
    }

    convertToBase64(input, callback) {
        var selectedFile = input.files;
        if (selectedFile.length > 0) {
            var fileToLoad = selectedFile[0];
            var fileReader = new FileReader();
            fileReader.onload = callback;
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    getCompany(client, page = undefined) {
        this.setState({
            companyListLoading: true,
            userFeedbackRegisterErrorMessage: '',
            companyList: [],
            companyListFiltered: [],
            filteredPageItems: [],
            userFeedbackMessage: ''
        });

        this.fetchCompany(client, (page !== undefined) ? page : this.state.page)
            .then(() => {
                this.setState({
                    companyListLoading: false
                });
            })
            .catch(error => {
                this.setUIFeedback('userFeedbackRegisterErrorMessage', 'Ocorreu um erro ao carregar, recarregue a página e tente novamente.');
            });
    }

    getCNPJ(value) {
        const cnpj = value.replace(/[^\d]+/g, '');

        this.setState({
            companyCNPJLoading: true,
            companyCNPJValid: false,
            stopCNPJConsult: false,
            userFeedbackRegisterErrorMessage: '',
        });

        this.fetchCNPJ(cnpj)
            .then(this.handleFetchError)
            .then(result => {
                const id = result.TransactionID;
                this.getCNPJData(id);
            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchCNPJ: ${error}`);

                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse.message, true);
                    this.setState({
                        registerLoading: false,
                        companyCNPJValid: false,
                        companyCNPJLoading: false,
                        companyCNPJDisabled: false
                    });
                }
            });
    }

    getCompanyTalao(cnpjData) {
        this.fetchCompanyDetail(cnpjData.dados.cartaoNacionalPessoaJuridica.cnpj)
            .then(this.handleFetchError)
            .then(result => {
                this.setState({
                    canRegisterEnotas: result.idetalao_manual.toLowerCase() === 's' ? false : true
                });
                this.populateCNPJ(cnpjData);
            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse.message, true);
                    this.setState({
                        registerLoading: false,
                        companyCNPJValid: false,
                        companyCNPJLoading: false,
                        companyCNPJDisabled: false
                    });
                }
            });
    }

    getCNPJData(id) {
        this.setState({userFeedbackRegisterErrorMessage: ''});

        this.fetchCNPJData(id)
            .then(this.handleFetchError)
            .then(result => {
                this.setState({companyRegisterMessage: 'Consultando CNPJ...'});

                if (!this.state.stopCNPJConsult) {
                    if (result.Transaction_Return.cod === 1 || result.Transaction_Return.cod === 2 && this.mounted) {
                        setTimeout(() => {
                            this.getCNPJData(id);
                        }, 1000);
                    } else if (result.Transaction_Return.cod !== 99) {
                        this.setUIFeedback('userFeedbackRegisterErrorMessage', result.Transaction_Return.message, true);
                        this.setState({
                            registerLoading: false,
                            companyCNPJValid: false,
                            companyCNPJLoading: false,
                            companyCNPJDisabled: false
                        });
                    } else {
                        this.getCompanyTalao(result);
                    }
                }

            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at getCNPJData: ${error}`);

                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse.message, true);
                    this.setState({
                        registerLoading: false,
                        companyCNPJValid: false,
                        companyCNPJLoading: false,
                        companyCNPJDisabled: false
                    });
                }
            });
    }

    handleSetCompany() {
        const self = this;
        const inputCnpj = document.getElementById('cnpjValueInput');
        const tokenAuth = document.querySelector('.label_credentials-token');
        const loginAuth = document.querySelector('.label_credentials-login');
        const inputCertified = document.querySelector('[name="companyCredentialsCertifiedFile"]');

        if (inputCertified && !tokenAuth && !loginAuth) {
            const fileName = inputCnpj.value.match(/\d+/g).join([]);
            const fileExtension = inputCertified.value.split('.').pop();
            const internalFileName = `${fileName}.${fileExtension}`;
            this.convertToBase64(inputCertified, function (fileLoadedEvent) {
                const base64 = fileLoadedEvent.target.result;
                self.setCompany(base64, internalFileName);
            });
        } else {
            this.setCompany();
        }
    }

    companyRegisterSuccess() {
        const message = `A empresa ${this.state.companyRazaoSocial} foi cadastrada com sucesso. `;
        this.setState({registerOpen: false, registerLoading: false});
        this.setUIFeedback('userFeedbackRegisterSuccessMessage', message, true);
        this.getCompany(this.props.companySelected);
        this.resetCompanyRegister();
    }

    setCompanyTomador() {
        this.fetchRegisterTomador(this.props.companySelected)
            .then(result => {
                result = result[0] ? result[0] : result;
                if (result.codigo || result.cod || result.message || result.error) {
                    throw new Error(JSON.stringify(result));
                }
                return result;
            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchRegisterTomador: ${error}`);
                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setState({companyListLoading: false});
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', 'Ocorreu um erro ao carregar, recarregue a página e tente novamente.');
                }
            });
    }

    setCompanyCertifiedEnotas(id) {
        this.setState({userFeedbackRegisterSuccessMessage: '', userFeedbackRegisterErrorMessage: ''});

        this.fetchCompanyCertifiedEnotas(id)
            .then(this.handleResponse)
            .then(() => {
                this.companyRegisterSuccess();
            })
            .catch(error => {
                const errorJSON = error.errorJSON ? error.errorJSON : '';
                const errorJSONArray = error.errorJSON && error.errorJSON.length > 0 ? error.errorJSON[0] : '';
                console.log(`Error at fetchCompanyCertifiedEnotas: ${error.errorJSON || error.errorText || error}`);

                if (error.status === 401) {
                    this.props.handleLogoff();
                } else {
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', error.errorText || errorJSONArray.mensagem || errorJSONArray.message || errorJSON.mensagem || errorJSON.message || 'Ocorreu um erro ao cadastrar a empresa, tente novamente.', true);
                    this.setState({
                        registerLoading: false,
                        companyCNPJValid: true,
                        companyCNPJLoading: false,
                        companyCNPJDisabled: false,
                        companyCredentialsCertifiedFileName: 'Selecione um arquivo'
                    });
                }
            });
    }

    setCompanyEnotas() {
        this.setState({userFeedbackRegisterSuccessMessage: '', userFeedbackRegisterErrorMessage: ''});
        this.setCompanyTomador();
        this.fetchCompanyEnotas()
            .then(this.handleResponse)
            .then(result => {
                if (this.state.companyCredential === 1 || this.state.companyAssinaturaDigital === 2) {
                    this.setCompanyCertifiedEnotas(result.empresaId);
                } else {
                    this.companyRegisterSuccess();
                }
            })
            .catch(error => {
                const errorJSON = error.errorJSON ? error.errorJSON : '';
                const errorJSONArray = error.errorJSON && error.errorJSON.length > 0 ? error.errorJSON[0] : '';
                console.log(`Error at fetchCompanyEnotas: ${error.errorJSON || error.errorText || error}`);

                if (error.status === 401) {
                    this.props.handleLogoff();
                } else {
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', error.errorText || errorJSONArray.mensagem || errorJSONArray.message || errorJSON.mensagem || errorJSON.message || 'Ocorreu um erro ao cadastrar a empresa, tente novamente.', true);
                    this.setState({
                        registerLoading: false,
                        companyCNPJValid: true,
                        companyCNPJLoading: false,
                        companyCNPJDisabled: false,
                        companyCredentialsCertifiedFileName: 'Selecione um arquivo',
                    });
                }

            });
    }

    setCompany(certifiedBase64, internalFileName) {
        const self = this;

        this.setState({
            companyRegisterMessage: 'Registrando empresa...',
            registerLoading: true,
            userFeedbackRegisterErrorMessage: ''
        });

        this.fetchCompanyRegister(certifiedBase64, internalFileName)
            .then(result => {
                result = result[0] ? result[0] : result;
                if (result.codigo || result.cod || result.message || result.error) {
                    throw new Error(JSON.stringify(result));
                }
                return result;
            })
            .then(result => {

                if (self.state.canRegisterEnotas) {
                    this.setCompanyEnotas();
                } else {
                    this.setCompanyTomador();
                }

            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchCompanyRegister(: ${error}`);

                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse[0] ? errorParse[0].mensagem : errorParse.mensagem, true);
                    this.setState({
                        registerLoading: false,
                        companyCNPJValid: true,
                        companyCNPJLoading: false,
                        companyCNPJDisabled: false,
                        companyCredentialsCertifiedFileName: 'Selecione um arquivo',
                    });
                }

            });
    }

    setUIFeedback(target, message) {
        this.setState({[target]: message});
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setTimeout(() => this.setState({[target]: ''}), 10000);
    }

    changeCompanyStatusUI(id) {
        const pageItems = this.state.filteredPageItems.map(company => {
            if (company.coddemanda === id) company.codstatus = 2;

            return company;
        });

        this.setState({
            filteredPageItems: pageItems,
            pageItems: pageItems
        });
    }

    handleCompanyStatusConsult(id) {
        const self = this;

        if (this.props.loggedIn) {
            this.fetchCompanyStatusChange(id)
                .then(this.handleFetchError)
                .then(result => {
                    if (result.cliente === this.props.companySelected.origem && this.mounted) {
                        if (result.codigostatus !== 2) {
                            return setTimeout(function () {
                                self.handleCompanyStatusConsult(id);
                            }, 300000);
                        }
                        return this.changeCompanyStatusUI(id);
                    }
                })
                .catch(error => {
                    const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                    console.log(`Error at handleCompanyStatusConsult: ${error}`);

                    if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                        this.props.handleLogoff();
                    } else {
                        this.setUIFeedback('userFeedbackRegisterErrorMessage', 'Ocorreu um erro ao carregar, recarregue a página e tente novamente.');
                    }
                });
        }
    }

    populateCompany(result) {
        const finished = [];
        const pending = [];

        const companyData = result.map(company => {
            /*if (company.codstatus !== 2) {
                this.handleCompanyStatusConsult(company.codigodemanda)
            } */

            return {
                id: company.codigodemanda || '',
                datainicial: this.formatCompanyDate(company.datainicial) || '',
                cnpj: this.formatCNPJ(company.cnpj),
                nome: company.nome || '',
                email: company.email || '',
                coddemanda: company.codigodemanda || '',
                status: company.status || '',
                codstatus: company.codigostatus || '',
                mensagem: company.mensagem || '',
            };

        });
        const message = companyData.length ? '' : 'Sem resultados.';

        companyData.map(company => {
            if (company.codstatus === 1) {
                pending.push(company);
            } else {
                finished.push(company);
            }
        });

        const companyDataSorted = pending.concat(finished.reverse());

        this.setState({
            companyListLoading: false,
            companyList: companyDataSorted,
            companyListFiltered: companyDataSorted,
            userFeedbackMessage: message,
            companyMensagem: '',
        });
    }

    formatCompanyDate(date) {
        var formattedDate = new Date(date);
        var d = formattedDate.getDate();
        var m = formattedDate.getMonth();
        m += 1;
        var y = formattedDate.getFullYear();
        d = d < 10 ? `0${d}` : d;
        m = m < 10 ? `0${m}` : m;
        return d + '/' + m + '/' + y;
    }

    populateCNPJ(result) {
        const atividades = result.dados.cartaoNacionalPessoaJuridica.atividadesEconomicasSecundarias;
        atividades.push(result.dados.cartaoNacionalPessoaJuridica.atividadeEconomicaPrimaria);
        this.setState({
            companyStatus: result.dados.cartaoNacionalPessoaJuridica.situacaoCadastral,
            companyRazaoSocial: result.dados.cartaoNacionalPessoaJuridica.nomeEmpresarial || '',
            companyNomeFantasia: result.dados.cartaoNacionalPessoaJuridica.nomeFantasia || '',
            companyEmail: result.dados.cartaoNacionalPessoaJuridica.enderecoEletronico || '',
            companyPhone: result.dados.cartaoNacionalPessoaJuridica.telefone || '',
            companyUF: result.dados.cartaoNacionalPessoaJuridica.endereco.uf || '',
            companyCity: result.dados.cartaoNacionalPessoaJuridica.endereco.municipio || '',
            companyAddressStreet: result.dados.cartaoNacionalPessoaJuridica.endereco.logradouro,
            companyAddressNumber: result.dados.cartaoNacionalPessoaJuridica.endereco.numero,
            companyAddressComplement: result.dados.cartaoNacionalPessoaJuridica.endereco.complemento,
            companyAddressDistrict: result.dados.cartaoNacionalPessoaJuridica.endereco.bairroDistrito,
            companyAddressZipCode: result.dados.cartaoNacionalPessoaJuridica.endereco.cep,
            companyInscricaoEstadual: '',
            companyInscricaoMunicipal: '',
            companyCNAEItens: atividades || '',
            companyCredentialsUser: '',
            companyCredentialsPassword: '',
            companyCredentialsToken: '',
            companyCredentialsCertifiedPassword: '',
            companyDataOpen: true,
        });

        if (
            !result.dados.cartaoNacionalPessoaJuridica.endereco.uf ||
            !result.dados.cartaoNacionalPessoaJuridica.endereco.municipio ||
            result.dados.cartaoNacionalPessoaJuridica.endereco.uf.includes('*') ||
            result.dados.cartaoNacionalPessoaJuridica.endereco.municipio.includes('*')
        ) {
            this.setState({
                companyCNPJLoading: false,
                companyCNPJValid: true,
                companyCNPJDisabled: false,
            });
            return true;
        }
        return this.getCityIBGECode();
    }

    getCompanySMU(ibgeCod) {
        this.setState({userFeedbackRegisterErrorMessage: ''});

        this.fetchCompanySMU(ibgeCod)
            .then(this.handleFetchError)
            .then(result => {
                this.setState({companySMU: result.SMU});
                return this.populateCredentials();
            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchCompanySMU: ${error}`);

                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse[0] ? errorParse[0].mensagem : errorParse.mensagem, true);
                    this.setState({
                        registerLoading: false,
                        companyCNPJValid: false,
                        companyCNPJLoading: false,
                        companyCNPJDisabled: false
                    });
                }
            });
    }

    getCityIBGECode() {
        this.setState({userFeedbackRegisterErrorMessage: ''});

        this.fetchCityIBGECode(this.state.companyUF, this.state.companyCity)
            .then(this.handleFetchError)
            .then(result => {
                this.getCompanySMU(result.CidadeIbge);
            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchCityIBGECode: ${error}`);

                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse[0] ? errorParse[0].mensagem : errorParse.mensagem, true);
                    this.setState({
                        registerLoading: false,
                        companyCNPJValid: false,
                        companyCNPJLoading: false,
                        companyCNPJDisabled: false
                    });
                }
            });
    }

    populateCredentials() {
        this.setState({companyRegisterMessage: 'Consultando Credenciais...', userFeedbackRegisterErrorMessage: ''});
        this.fetchCompanyCredentials(this.state.companyUF, this.state.companyCity)
            .then(this.handleFetchError)
            .then(credential => {

                if (credential.status === 400) {
                    this.setState({
                        companyCredential: 2,
                        companyCredentialsOpen: true,
                        companyInvoiceEnabled: true,
                        companyCNPJLoading: false,
                        companyCNPJValid: true,
                    });
                } else {
                    this.setState({companyCNPJDisabled: false, companyRegisterMessage: ''});
                    this.handleCompanyNoSMU(credential);
                }

            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchCompanyCredentials: ${error}`);

                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse[0] ? errorParse[0].mensagem : errorParse.mensagem, true);
                    this.setState({
                        registerLoading: false,
                        companyCNPJValid: false,
                        companyCNPJLoading: false,
                        companyCNPJDisabled: false
                    });
                }
            });

    }

    handleCompanyNoSMU(result) {
        this.setState({
            companyRegimesEspecialTributacaoItens: result.resposta.regimesEspecialTributacao,
            companyUsaRegimeEspecialTributacao: result.resposta.usaRegimeEspecialTributacao,
            companyUsaCodigoServicoMunicipal: result.resposta.usaCodigoServicoMunicipal,
            companyUsaCNAE: result.resposta.usaCNAE,
            companyUsaDescricaoServico: result.resposta.usaDescricaoServico,
        });

        if (result.resposta.tipoAutenticacao && result.resposta.tipoAutenticacao !== 0) {
            this.setState({
                companyCredential: result.resposta.tipoAutenticacao,
                companyCNPJLoading: false,
                companyCredentialsOpen: true,
                companyCNPJValid: true,
                companyInvoiceEnabled: true,
                insricaoMunicipalHelp: result.resposta.helpInscricaoMunicipal,
                companyAssinaturaDigital: result.resposta.assinaturaDigital
            });
        } else {
            this.setState({
                companyCredential: result.resposta.tipoAutenticacao,
                companyCredentialsOpen: false,
                companyCNPJLoading: false,
                companyCNPJValid: true,
                companyInvoiceEnabled: false,
                companyAssinaturaDigital: result.resposta.assinaturaDigital
            });
        }

        this.setState({companyCNPJDisabled: false, companyRegisterMessage: ''});


    }

    handleSearch(event) {
        const value = event.target.value;
        const matches = this.state.companyList.filter(company => {
            const reg = new RegExp(value.replace(/\\/g, '\\\\').replace(/[./-]/g, ''));
            const cnpj = company.cnpj.replace(/\\/g, '\\\\').replace(/[./-]/g, '');
            return cnpj.match(reg);
        });
        const message = matches.length ? '' : 'Sem resultados.';

        this.setState({
            filterSearch: value,
            filteredPageItems: matches,
            userFeedbackMessage: message
        });
    }

    handleLabel(event) {
        const statusName = {
            1: 'Pendente',
            2: 'Finalizado',
        };
        const target = event.currentTarget;
        const name = target.name;
        const propName = target.name.replace('Ascending', '');
        const isAscending = this.state[name];

        const matches = this.state.filteredPageItems.sort(function (a, b) {
            if (propName === 'codstatus') {
                a = statusName[a[propName]].toLowerCase();
                b = statusName[b[propName]].toLowerCase();
            } else if (propName === 'id') {
                a = a[propName];
                b = b[propName];
            } else if (propName === 'datainicial') {
                a = new Date(`${a[propName].substring(6)}/${a[propName].substring(3, 5)}/${a[propName].substring(0, 2)}`);
                b = new Date(`${b[propName].substring(6)}/${b[propName].substring(3, 5)}/${b[propName].substring(0, 2)}`);
            } else {
                a = a[propName].toLowerCase();
                b = b[propName].toLowerCase();
            }

            if (isAscending) {
                if (a < b) {
                    return 1;
                }
                if (a > b) {
                    return -1;
                }
                return 0;
            } else {
                if (a > b) {
                    return 1;
                }
                if (a < b) {
                    return -1;
                }
                return 0;
            }

        });

        this.setState({
            [name]: !(this.state[name]),
            filteredPageItems: matches
        });

    }

    handleStateToggle(event) {
        const target = event.currentTarget;
        const dataState = target.getAttribute('data-state');
        const name = target.name;
        const stateName = dataState ? dataState : name;

        this.setState({[stateName]: !this.state[stateName]});
    }

    handleCloseRegister(event) {
        this.setState({registerOpen: false});
    }

    handleCNPJ(event) {
        const target = event.target;
        const value = target.value;
        const maskedValue = this.maskCNPJ(value);
        this.setState({companyCNPJ: maskedValue});

        if (maskedValue.length === 18) {
            if (this.isCNPJValid(value)) {
                this.setState({companyCNPJDisabled: true});
                this.getCNPJ(value, target);
            } else {
                this.setState({
                    companyCNPJValid: false
                });
            }
        } else {
            this.setState({
                companyCNPJValid: false
            });
        }
    }

    handlePhone(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const maskedValue = this.maskPhone(value);

        this.setState({[name]: maskedValue});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({[name]: value}, () => {
            if (name === 'companyInscricaoMunicipal' || name === 'companyInscricaoEstadual') {
                this.state.companyInscricaoEstadual === ''
                    ? this.setState({companyInscricaoMunicipalRequired: true})
                    : this.setState({companyInscricaoMunicipalRequired: false});
            }
        });

    }

    handleCNAEChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let cod;

        this.state.companyCNAEItens.map(item => {
            if (item.descricao === value) cod = item.codigo;
        });

        this.setState({[name]: value});
        this.getCompanyLC116(cod);
    }

    getCompanyLC116(cod) {
        this.setState({companyLC116List: [], userFeedbackRegisterErrorMessage: ''});

        this.fetchCompanyLC116(cod)
            .then(this.handleFetchError)
            .then(result => {
                this.setState({
                    companyLC116List: result.lc116 || []
                });
            })
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchCompanyLC116: ${error}`);
                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setState({
                        registerOpen: true,
                        companyRegisterReadonly: false,
                        registerDetailLoading: false,
                    });
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse.mensagem || errorParse.message);
                }

            });
    }

    handleInputFileChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fileName = target.files.length > 0 ? target.files[0].name : 'Selecione um arquivo';

        this.setState({
            [name]: value,
            companyCredentialsCertifiedFileName: fileName
        });
    }

    handleCloseWithoutSave() {
        this.setState({
            registerLoading: false,
            registerOpen: false,
            companyDataOpen: true,
            closeRegisterOpen: false,
            companyCNPJLoading: false,
            stopCNPJConsult: true
        });

        this.resetCompanyRegister();
    }

    handleCloseAndSave() {
        console.log('handleCloseAndSave chamada.');
        const button = document.querySelector('.company-detail button[type="submit"]');

        this.setState({
            registerOpen: true,
            closeRegisterOpen: false
        });

        button ? button.click() : this.handleRegisterCloseButton();
    }

    handleRegisterCloseButton() {
        this.setState({
            registerOpen: true,
            closeRegisterOpen: false
        });
    }

    async handlePageChange(page) {
        if (this.state.page !== page) {
            await this.setState({
                page: page
            });
            this.getCompany(this.props.companySelected, page);
        }
    }

    maskCNPJ(value) {
        value = value.replace(/^(\d{2})(\d)/, '$1.$2');
        //Coloca ponto entre o quinto e o sexto dígitos
        value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
        //Coloca uma barra entre o oitavo e o nono dígitos
        value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
        //Coloca um hífen depois do bloco de quatro dígitos
        value = value.replace(/(\d{4})(\d)/, '$1-$2');
        return value;
    }

    maskPhone(number) {
        number = number.replace(/\D/g, ''); //Remonumbere tudo o que não é dígito
        number = number.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em numberolta dos dois primeiros dígitos
        number = number.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
        return number;
    }

    isCNPJValid(value) {
        let cnpj = value.replace(/[^\d]+/g, '');
        // Elimina CNPJs invalidos conhecidos
        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999')
            return false;

        // Valida DVs
        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0, size);
        let digits = cnpj.substring(size);
        let sum = 0;
        let pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== Number(digits.charAt(0)))
            return false;

        size = size + 1;
        numbers = cnpj.substring(0, size);
        sum = 0;
        pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== Number(digits.charAt(1)))
            return false;

        return true;
    }

    formatCNPJ(cnpj, noCharacter) {
        return noCharacter
            ? this.formatAllSpecialCharacters(cnpj)
            : cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
    }

    formatAllSpecialCharacters(str) {
        return str.replace(/[^\d]+/g, '');
    }

    resetCompanyRegister() {
        this.setState({
            companyCNPJ: '',
            companyStatus: '',
            companyInscricaoEstadual: '',
            companyInscricaoMunicipal: '',
            companyRazaoSocial: '',
            companyNomeFantasia: '',
            companyEmail: '',
            companyPhone: '',
            companyCredentialsCertifiedFileError: '',
            companyCredentialsCertifiedPassword: '',
            companyCredentialsPassword: '',
            companyCredentialsUser: '',
            companyCredentialsToken: '',
            companyCNPJValid: false,
            companyCNPJDisabled: false,
            companyCredentialsCertifiedFileName: 'Selecione um arquivo',
            companySMU: '',
            companyNumeroLote: '',
            companySerie: '',
            companyNumeroRPS: '',
            companyUsaRegimeEspecialTributacao: '',
            companyRegimesEspecialTributacao: '',
            companyRegimesEspecialTributacaoItens: '',
            companyUsaCodigoServicoMunicipal: '',
            companyUsaCNAE: '',
            companyCNAEItens: '',
            companyCNAE: '',
            companyUsaDescricaoServico: '',
            companyLC116List: [],
            itemListaServicoLC116: '',
            companyMensagem: '',
            companyUF: '',
            companyCity: '',
        });
    }

    populateCompanyDetail(result) {
        this.setState({
            registerDetailLoading: false,
            companyStatus: result.emissao_habilitada ? 'Habilitada' : 'Desabilitada',
            companyInscricaoMunicipal: result.inscricaomunicipal,
            companyInscricaoEstadual: result.inscricaoestadual,
            companyRazaoSocial: result.razaosocial,
            companyNomeFantasia: result.nomefantasia,
            companyEmail: result.email,
            companyPhone: result.telefone,
            companyDataOpen: true,
            companyCNPJValid: true,
            companyCredential: '',
            companySMU: '',
            companyNumeroLote: '',
            companySerie: '',
            companyNumeroRPS: '',
            companyUsaRegimeEspecialTributacao: '',
            companyRegimesEspecialTributacao: '',
            companyRegimesEspecialTributacaoItens: '',
            companyUsaCodigoServicoMunicipal: '',
            companyUsaCNAE: '',
            companyCNAEItens: '',
            companyCNAE: '',
            companyUsaDescricaoServico: '',
            companyLC116List: [],
            itemListaServicoLC116: '',
            companyCodigoServicoMunicipal: '',
            companyMensagem: '',
            companyCredentialsCertifiedPassword: '',
            companyCredentialsPassword: '',
            companyCredentialsUser: '',
            companyCredentialsToken: '',
            companyUF: result.estado,
            companyCity: result.cidade,
        });
    }

    async handleCleanCnpjConsult(value, id) {
        await this.setState({
            cacheFree: true,
            registerOpen: false
        });
        this.handleCNPJConsult(value, id);
    }

    handleCNPJConsult(value, id) {
        this.setState({
            registerOpen: true,
            companyDataOpen: true,
            companyCNPJ: this.maskCNPJ(value),
            companyCodDemanda: id,
            companyRegisterReadonly: false,
        });
        this.getCNPJ(value);
    }

    handleCompanyDetail(cnpj, id) {
        this.setState({
            registerOpen: true,
            companyCNPJLoading: false,
            companyCNPJValid: false,
            stopCNPJConsult: false,
            companyRegisterReadonly: true,
            registerDetailLoading: true,
            companyCNPJ: cnpj,
            companyCodDemanda: id,
            companySMU: '',
            companyNumeroLote: '',
            companySerie: '',
            companyNumeroRPS: '',
            companyUsaRegimeEspecialTributacao: '',
            companyRegimesEspecialTributacao: '',
            companyRegimesEspecialTributacaoItens: '',
            companyUsaCodigoServicoMunicipal: '',
            companyUsaCNAE: '',
            companyCNAEItens: '',
            companyCNAE: '',
            companyUsaDescricaoServico: '',
            companyLC116List: [],
            itemListaServicoLC116: '',
            companyCodigoServicoMunicipal: '',
            companyMensagem: '',
            companyCredentialsCertifiedPassword: '',
            companyCredentialsPassword: '',
            companyCredentialsUser: '',
            companyCredentialsToken: '',
            userFeedbackRegisterErrorMessage: '',
            companyDataOpen: true,
        });

        this.fetchCompanyDetail(cnpj)
            .then(this.handleFetchError)
            .then(result => this.populateCompanyDetail(result))
            .catch(error => {
                const errorParse = error.message.indexOf('mensagem') !== -1 || error.message.indexOf('message') !== -1 ? JSON.parse(error.message) : error.message;
                console.log(`Error at fetchCompanyDetail: ${error}`);
                if (errorParse.code === 401 || errorParse.message === 'Bad Credentials') {
                    this.props.handleLogoff();
                } else {
                    this.setState({
                        registerOpen: false,
                        companyRegisterReadonly: false,
                        registerDetailLoading: false,
                    });
                    this.setUIFeedback('userFeedbackRegisterErrorMessage', errorParse.mensagem || errorParse.message);
                    this.resetCompanyRegister();
                }

            });
    }

    handleShowCompanyDetail(event) {
        const coddemanda = event.currentTarget.parentNode.getAttribute('data-coddemanda');
        const codstatus = event.currentTarget.parentNode.getAttribute('data-codstatus');
        const cnpj = event.currentTarget.parentNode.getAttribute('data-cnpj');
        const message = event.currentTarget.parentNode.getAttribute('data-mensagem');

        this.setState({
            companyMensagem: message
        });

        this.props.saveCurrentCompany({cnpj, coddemanda});

        codstatus == 2 ? this.handleCompanyDetail(cnpj, coddemanda) : this.handleCNPJConsult(cnpj, coddemanda);
    }

    handleCopyToClipboard(event) {
        const element = event.target;
        element.select();
        document.execCommand('copy');
        element.parentNode.classList.add('label_copied');

        setTimeout(function () {
            element.parentNode.classList.remove('label_copied');
        }, 800);
    }

    async setPage(paginationObject) {
        const items = paginationObject.totalItems;
        const pageSize = paginationObject.pageLimit;
        const page = this.state.page ? this.state.page + 1 : paginationObject.currentPage;
        let pager = {};

        if (page > paginationObject.totalPages) {
            return;
        }
        // get new pager object for specified page
        pager = await this.getPager(items, page, pageSize);

        // update state
        this.props.savePaginationList(pager);
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || Number(this.props.itemPerPage);

        // calculate total pages
        let totalPages = totalItems / pageSize;

        if (Math.round(totalPages) < totalPages) {
            totalPages = Math.floor(totalPages + 1);
        } else {
            totalPages = Math.round(totalPages);
        }

        let startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages < 1 ? 1 : totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // create an array of pages to ng-repeat in the pager control
        const pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            pages: pages
        };
    }

    render() {
        return (
            <Company
                {...this.state}
                page={this.state.page}
                setPage={this.setPage}
                setCompany={this.setCompany}
                handleCNPJ={this.handleCNPJ}
                getNfeJson={this.getNfeJson}
                handlePhone={this.handlePhone}
                handleLabel={this.handleLabel}
                handleSearch={this.handleSearch}
                getApiUrl={this.props.getApiUrl}
                cancelReceipt={this.cancelReceipt}
                setMeiSituation={this.setMeiSituation}
                handlePageChange={this.handlePageChange}
                handleCNAEChange={this.handleCNAEChange}
                handleSetCompany={this.handleSetCompany}
                fetchTestReceipt={this.fetchTestReceipt}
                setCompanyTomador={this.setCompanyTomador}
                verifyReceiptTest={this.verifyReceiptTest}
                handleStateToggle={this.handleStateToggle}
                handleInputChange={this.handleInputChange}
                handleCloseAndSave={this.handleCloseAndSave}
                companySelected={this.props.companySelected}
                handleCloseRegister={this.handleCloseRegister}
                companyListLoading={this.state.companyListLoading}
                handleInputFileChange={this.handleInputFileChange}
                handleCopyToClipboard={this.handleCopyToClipboard}
                handleCloseWithoutSave={this.handleCloseWithoutSave}
                handleCleanCnpjConsult={this.handleCleanCnpjConsult}
                handleShowCompanyDetail={this.handleShowCompanyDetail}
                handleRegisterCloseButton={this.handleRegisterCloseButton}
            />
        );
    }

}

const mapStateToProps = state => ({
    filters: state.company.filters,
    currentPage: state.company.currentPage,
    currentCompany: state.company.currentCompany
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveCurrentCompanies: setCompanies,
        saveCurrentPagination: setPagination,
        savePaginationList: setPaginationList,
        saveCurrentCompany: setCurrentCompany
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(CompanyContainer);
