import React from 'react';
import { NavLink } from 'react-router-dom';

const Form = ({ 
    loading,
    handleSubmit,
    handleInputChange,
    user
}) => (
    <form className='login__form' onSubmit={handleSubmit}>
        <label className='label'>
            <input 
                className='input input_login' 
                name='user'
                type='email'
                value={user}
                onChange={handleInputChange} 
                required
            />    
            <p className='label__p label__p_float'>E-mail</p>
        </label>       
        <NavLink className='login__link' to='/login'>ir para login</NavLink> 
        <button className={loading ? 'btn btn_login btn_center btn_loading' : 'btn btn_login btn_center'}>
            { loading
                ?   <div className='login__loader'>
                        Enviando...
                </div>
                : 'Enviar'
            }                
        </button>
    </form>
);

export default Form;
