import {
    SET_CNPJ,
    SET_EMAIL,
    SET_ADDRESS,
    SET_TELEPHONE,
    SET_COMPANY_DATA,
    RESET_NEW_COMPANY,
    SET_COMPANY_REGISTERED,
} from '../actions/newCompany-actions';

export default (state  = '', { type, payload }) => {
    switch (type) {
    case SET_CNPJ:
        return {
            ...state,
            cnpj: payload
        };
    case SET_EMAIL:
        return {
            ...state,
            email: payload
        };
    case SET_ADDRESS:
        return {
            ...state,
            address: payload
        };
    case SET_TELEPHONE:
        return {
            ...state,
            telephone: payload
        };
    case SET_COMPANY_DATA:
        return {
            ...state,
            companyData: payload
        };
    case SET_COMPANY_REGISTERED:
        return {
            ...state,
            companyRegistered: payload
        };
    case RESET_NEW_COMPANY:
        return {
            ...state,
            ...payload
        };
    default:
        return state;
    }
};
