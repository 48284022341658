import React, { useState, useEffect, useReducer } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    faTimes,
    faCheck,
    faAsterisk,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import LoaderSpinner from 'react-loader-spinner';
import {ValidatorForm} from 'react-form-validator-core';
import Credentials from './Credentials/CredentialsContainer';
import TextValidator from '../../TextValidator/TextValidator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {setCredential} from '../../../actions/credentials-actions';
import UserFeedBack from '../../../Components/UserFeedback/UserFeedback';
import {
    resetNewCompany,
    setAddress,
    setCnpj,
    setCompanyData,
    setCompanyRegistered,
    setEmail,
    setTelephone
} from '../../../actions/newCompany-actions';
import {Link} from 'react-router-dom';
import {
    drop,
    union
} from 'lodash';
import CompanyOwner from '../../Global/CompanyOwner';
import addNotification from 'react-push-notification';
import apiErrorHandler from '../../Global/ErrorHandler';
import {getCapitalizedName} from '../../CompanyHandler';
import {setFilters} from '../../../actions/company-actions';
import {GrClose} from 'react-icons/all';
import {AnimatePresence, motion} from 'framer-motion';

const NewCompany = (props) => {

    const [status, setStatus] = useState('');
    const [authType, setAuthType] = useState(0);
    const [useAEDF, setUseAEDF] = useState(false);
    const [codDemanda, setCodDemanda] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const [demandaAction, setDemandaAction] = useState(0);
    const [mensagemErro, setMensagemErro] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [loadingMessage, setLoadingMessage] = useState('');
    const [requiredFields, setRequiredFields] = useState([]);
    const [feedBackMessage, setFeedBackMessage] = useState([]);
    const [demandaMensagem, setDemandaMensagem] = useState('');
    const [homolagatedCity, setHomolagatedCity] = useState(false);
    const [cacheFreeConsult, setCacheFreeConsult] = useState(false);
    const [showCompanyButton, setShowCompanyButton] = useState(false);
    const [usaRegimeEspecial, setUsaRegimeEspecial] = useState('Selecione a atividade');
    const [mensagemSuccess, setMensagemSuccess] = useState('Empresa cadastrada com sucesso!');
    const [ownerData, setOwnerData] = useReducer((state, userState) => ({ ...state, ...userState }), {
        cpf: '',
        name: '',
        code: '',
        email: '',
        change: {
            cpf: '',
            email: '',
        }
    });

    useEffect(() => {
        props.resetCompanyData();
        return () => {
            setStatus('');
            setAuthType(0);
            setCodDemanda('');
            setFormReady(false);
            setMensagemErro('');
            setTransactionId('');
            setMensagemAlerta('');
            setLoadingMessage('');
            setRequiredFields([]);
            setHomolagatedCity(false);
            setShowCompanyButton(false);
            setUsaRegimeEspecial('Selecione a atividade');
            setMensagemSuccess('Empresa cadastrada com sucesso!');
        };
    }, []);

    useEffect(() => {
        if (transactionId !== '') {
            setStatus('waitingCompany');
            setLoadingMessage('Obtendo dados da empresa...');
            addNotification({
                native: true,
                theme: 'darkblue',
                title: 'Cadastro Empresa',
                subtitle: 'Busca Iniciada',
                message: 'Busca de dados da empresa iniciada, você será notificado quando a busca acabar.'
            });
            setTimeout(() => listenerCnpj(), 5000);
        }
    }, [transactionId]);

    useEffect(() => {
        if (props.cnpj === '') {
            setStatus('');
            setFormReady(false);
            setRequiredFields([]);
        } else if (props.cnpj.length < 14) {
            setStatus('');
            setFormReady(false);
            setRequiredFields([]);
        }
    }, [props.cnpj]);

    useEffect(() => {
        if (codDemanda !== '') {
            verifyCompany();
        }
    }, [codDemanda]);

    const errorHandler = (status, message, form = false) => {
        setStatus(status);
        setFormReady(form);
        setMensagemErro(message);
        setLoadingMessage('');
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    const validateToken = () => {
        const expiresIn = localStorage.getItem('dicasMEIToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('dicasMEIToken');
            localStorage.removeItem('dicasMEIToken_expiration');
            localStorage.setItem('loggedIn', false);
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('dicasMEIToken');
    };

    const validateClientToken = () => {
        const expiresIn = localStorage.getItem('clientToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('clientToken');
            localStorage.removeItem('clientToken_expiration');
            localStorage.setItem('loggedIn', false);
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('clientToken');
    };

    // FLUXO PARA OBTER DADOS DA EMPRESA

    const startCreationFlux = async () => {
        if (props.cnpj === '') return;
        let activeCnpj = true;
        let existDemmand = false;
        setStatus('working');
        verifyDemanda()
            .then(async ({data}) => {
                if (data.abrirCadastro) {
                    validadeCnpj()
                        .then((response) => {
                            setTransactionId(response.data.TransactionID);
                        })
                        .catch((err) => {
                            activeCnpj = false;
                            errorHandler('error', err.response.data.mensagem);
                        });
                } else {
                    setStatus('');
                    if (data.redirecionarEdicao) {
                        setShowModal(true);
                        setDemandaAction(1);
                        setDemandaMensagem(data.mensagem);
                        
                    } else {
                        setShowModal(true);
                        setDemandaAction(3);
                        setDemandaMensagem(data.mensagem);
                    }
                }
            }).catch((err) => {
                existDemmand = true;
                const mensagem = err.response.data.mensagem ?
                    (err.response &&
                        err.response.data &&
                        err.response.data.mensagem) : 'Ocorreu um erro ao verificar se há demanada existente para o CNPJ.';
                errorHandler('error', mensagem);
            });
    };

    const checkCnpj = async () => {
        return Axios.get(props.getApiUrl(`backoffice/mei/${props.cnpj}`), {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateClientToken()
            }
        });
    };

    const verifyDemanda = async () => {
        const { cnpj, companySelected } = props;
        return Axios.post(props.getApiUrl('backoffice/mei/validarcriacao'), {
            'cnpj': cnpj,
            'cliente': companySelected.origem
        }, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateClientToken()
            }
        });
    };

    const validadeCnpj = () => {
        const { cnpj } = props;
        return Axios.post(`${props.getApiUrl('cnpj/solicita')}`, {
            CNPJ: cnpj,
            tp_consulta: 'X',
            cartaoCNPJ: true,
            ccc: false,
            simplesNacional: true,
            cpom: false,
            ccm: false,
            origem: 'BACKOFFICE',
            force: cacheFreeConsult
        },{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateToken()
            }
        });
    };

    const listenerCnpj = async () => {
        await getCnpjValidation()
            .then(async (res) => {
                switch (res.data.Transaction_Return.cod) {
                case 99:
                    addNotification({
                        native: true,
                        theme: 'darkblue',
                        title: 'Cadastro Empresa',
                        subtitle: 'Busca Finalizada',
                        message: 'Dados da empresa foram preenchidos e ela está pronta para ser cadastrada.'
                    });
                    await checkCnpj().then(
                        async response => {
                            populateForm({
                                cnpj: res.data.dados.cartaoNacionalPessoaJuridica,
                                existingCompany: response.data
                            });
                        }
                    ).catch(
                        err => {
                            if (err.response.status !== 404) {
                                const mensagem = err.response.data.mensagem ?
                                    err.response.data.mensagem : 'Ocorreu um erro ao verificar se há demanada existente para o CNPJ.';
                                errorHandler('error', mensagem);
                            } else {
                                populateForm({
                                    cnpj: res.data.dados.cartaoNacionalPessoaJuridica,
                                    existingCompany: null
                                });
                            }
                        }
                    );
                    return;
                case 1:
                case 2:
                    setStatus('waitingCompany');
                    setLoadingMessage('Obtendo dados da empresa...');
                    setTimeout(() => listenerCnpj(), 5000);
                    break;
                default:
                    addNotification({
                        native: true,
                        theme: 'darkblue',
                        title: 'Cadastro Empresa',
                        subtitle: 'Erro',
                        message: 'Ocorreu um erro ao buscar os dados da empresa.'
                    });
                    setStatus('error');
                    setMensagemErro(res.data.Transaction_Return.message);
                }
            })
            .catch((err) => {
                addNotification({
                    native: true,
                    theme: 'darkblue',
                    title: 'Cadastro Empresa',
                    subtitle: 'Erro',
                    message: 'Ocorreu um erro ao buscar os dados da empresa.'
                });
                const message = err.response.data.mensagem ?
                    err.response.data.mensagem : 'Ocorreu um erro, por favor verifique sua conexão e tente novamente.';
                errorHandler('error', message);
            });
    };

    const getCnpjValidation = () => {
        return Axios.post(`${props.getApiUrl('cnpj/consulta')}`, {
            TransactionID: transactionId
        },{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateToken()
            }
        });
    };

    const populateForm = async (data) => {
        const address = {
            uf: (data.existingCompany && data.existingCompany.estado !== '') ?
                data.existingCompany.estado : data.cnpj.endereco.uf,
            cep: (data.existingCompany && data.existingCompany.cep !== '') ?
                data.existingCompany.cep : data.cnpj.endereco.cep,
            numero: (data.existingCompany && data.existingCompany.numero !== '') ?
                data.existingCompany.numero : data.cnpj.endereco.numero,
            municipio: (data.existingCompany && data.existingCompany.cidade !== '') ?
                data.existingCompany.cidade : data.cnpj.endereco.municipio,
            logradouro: (data.existingCompany && data.existingCompany.endereco !== '') ?
                data.existingCompany.endereco : data.cnpj.endereco.logradouro,
            complemento: (data.existingCompany && data.existingCompany.complemento !== '') ?
                data.existingCompany.complemento : data.cnpj.endereco.complemento,
            bairroDistrito: (data.existingCompany && data.existingCompany.bairro !== '') ?
                data.existingCompany.bairro : data.cnpj.endereco.bairroDistrito,
        };
        await fetchCredentialType(address.uf, address.municipio);
        await props.saveCompanyData({
            ...props.companyData,
            ...props.companyData,
            situacaoCadastral: data.cnpj.situacaoCadastral,
            inscricaoEstadual: data.existingCompany ? data.existingCompany.inscricaoestadual : '',
            inscricaoMunicipal: data.existingCompany ? data.existingCompany.inscricaomunicipal : '',
            nomeFantasia: data.cnpj.nomeFantasia && data.cnpj.nomeFantasia !== '' ? data.cnpj.nomeFantasia : data.existingCompany.nomefantasia,
            codigoServicoMunicipal: data.existingCompany ? data.existingCompany.descodigoservicomunicipio : '',
            nomeEmpresarial: data.cnpj.nomeEmpresarial && data.cnpj.nomeEmpresarial !== '' ? data.cnpj.nomeEmpresarial : data.existingCompany.razaosocial,
        });
        await props.saveTelephone(
            (data.existingCompany && data.existingCompany.telefone !== '') ?
                data.existingCompany.telefone : data.cnpj.telefone
        );
        await props.saveEmail(data.existingCompany ? data.existingCompany.email : data.cnpj.enderecoEletronico);
        await props.saveAddress({
            ...props.address,
            ...address
        });
        setStatus('formingCompany');
        setFormReady(true);
    };

    const fetchCredentialType = async (uf, municipio) => {
        await Axios.get(props.getApiUrl(`notasinternas/prefeitura/detalhes?uf=${uf}&cidade=${municipio}`),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateToken()
                }
            })
            .then(async (response) => {
                if (response.data.status === 400 && response.data.resposta.match(/(prefeitura)/g) &&
                    response.data.resposta.match(/(não)/g) && response.data.resposta.match(/(homologada)/g)) {
                    setHomolagatedCity(false);
                    props.saveCredentials({
                        ...props.credentials,
                        authType: 0,
                        type: 0
                    });
                } else {
                    setHomolagatedCity(true);
                    props.companyData.regimesEspecialTributacao = response.data.resposta.regimesEspecialTributacao;
                    await props.saveCompanyData(props.companyData);
                    await setAuthType(response.data.resposta.assinaturaDigital);
                    await setUsaRegimeEspecial(response.data.resposta.usaRegimeEspecialTributacao);
                    props.saveCredentials({
                        ...props.credentials,
                        authType: response.data.resposta.assinaturaDigital,
                        type: response.data.resposta.tipoAutenticacao ?
                            response.data.resposta.tipoAutenticacao :
                            props.credentials.type
                    });
                    setUseAEDF(response.data.resposta.usaAEDF);
                }
            })
            .catch((err) => {
                setHomolagatedCity(false);
                props.saveCredentials({
                    ...props.credentials,
                    authType: 0,
                    type: 0
                });
                const message = (err.response && err.response.data && err.response.data.mensagem) ?
                    err.response.data.mensagem : 'Ocorreu um erro, por favor verifique sua conexão e tente novamente.';
                errorHandler('error', message);
            });
    };

    const displayMessage = (errorMessage, type = null) => {
        const messageObj = {
            type: type,
            message: errorMessage.toString()
        };
        setFeedBackMessage(union(feedBackMessage, [messageObj]));
        setTimeout(() => {
            setFeedBackMessage(drop(feedBackMessage));
        }, 12000);
    };

    // HANDLE SUBMIT

    const submitCompany = async () => {
        const { companySelected } = props;
        
        // avon pode cadastrar sem proprietario, portanto só valida campo proprietário se origem não for Avon.
        if (companySelected.origem !== 'AVON' && (!ownerData || !ownerData.change || !ownerData.change.code || ownerData.change.code === '')) {
            scrollToTop();
            setRequiredFields([
                {mensagem: 'Você precisa definir um proprietário para está MEI.'}
            ]);
        } else {
            setFormReady(false);
            setRequiredFields([]);
            setStatus('waitingCompany');
            setLoadingMessage('Salvando empresa, aguarde...');
            if ((props.credentials.type === 1 || props.credentials.authType === 2) && props.credentials.arquivoBase64 !== null) {
                await submitFile();
            }
            await handleSubmitCompany();
        }
    };

    const handleSubmitCompany = async () => {
        const bodyFormData = new FormData();
        bodyFormData.append('cnpj', props.cnpj);
        bodyFormData.append('email', props.email);
        bodyFormData.append('telefone', props.telephone);
        bodyFormData.append('cliente', props.companySelected.origem);
        bodyFormData.append('inscricaoEstadual', props.companyData.inscricaoEstadual ?
            props.companyData.inscricaoEstadual : ''
        );
        bodyFormData.append('inscricaoMunicipal', props.companyData.inscricaoMunicipal ?
            props.companyData.inscricaoMunicipal : ''
        );
        bodyFormData.append('codusuario', ownerData.change.code ? ownerData.change.code : '');
        bodyFormData.append('usuario', props.credentials.type === 4 ? props.credentials.secretPhrase : props.credentials.user,);
        bodyFormData.append('senha', props.credentials.password);
        bodyFormData.append('token', props.credentials.token);
        bodyFormData.append('fraseSecreta', props.credentials.secretPhrase);
        bodyFormData.append('tokenacesso', props.credentials.type === 3 ? props.credentials.token :
            (props.address.uf === 'SP' && props.address.city === 'SAO PAULO') ?
                props.credentials.password : null);
        bodyFormData.append('certificado', props.credentials.arquivoBase64 ? props.credentials.arquivoBase64 : '');
        bodyFormData.append('nome_certificado', props.credentials.arquivoNome);
        bodyFormData.append('senha_certificado', props.credentials.filePassword);
        bodyFormData.append('talao_manual', null);
        bodyFormData.append('emissao_habilitada', null);
        bodyFormData.append('AEDF', useAEDF ? props.companyData.AEDF : null);
        bodyFormData.append('nome_fantasia', props.companyData.nomeFantasia);
        bodyFormData.append('nome_empresarial', props.companyData.nomeEmpresarial);
        bodyFormData.append('uf', props.address.uf);
        bodyFormData.append('cidade', props.address.municipio);
        bodyFormData.append('cep', props.address.cep);
        bodyFormData.append('bairro', props.address.bairroDistrito);
        bodyFormData.append('endereco', props.address.logradouro);
        bodyFormData.append('complemento', props.address.complemento);
        bodyFormData.append('numero', props.address.numero);
        bodyFormData.append('numero_rps', props.companyData.numeroRPS !== '' ? props.companyData.numeroRPS : '');
        bodyFormData.append('serie', props.companyData.serie !== '' ? props.companyData.serie : '');
        bodyFormData.append('numero_lote', props.companyData.numeroLote !== '' ? props.companyData.numeroLote : '');
        if (props.companyData.regimeEspecialSelecionado !== '') {
            bodyFormData.append('regime_especial_tributacao', props.companyData.regimeEspecialSelecionado);
        }
        registerCompany(bodyFormData).then(
            response => {
                setCodDemanda(response.data.demanda);
                props.saveCompanyRegistered(props.cnpj);
            }
        ).catch(
            err => {
                scrollToTop();
                setStatus('error');
                setFormReady(true);
                setLoadingMessage('');
                apiErrorHandler(err, displayMessage);
            }
        );
    };

    const submitFile = async () => {
        let bodyFormData = new FormData();
        bodyFormData.append('senha_certificado', props.credentials.filePassword);
        bodyFormData.append('arquivo', props.credentials.arquivoBase64);
        Axios.post(props.getApiUrl(`notasinternas/empresa/vincular/certificado?cnpj=${props.cnpj}`),
            bodyFormData,
            {
                headers: {
                    Authorization: validateToken(),
                    'Content-Type': 'multipart/form-data'
                }
            }).then().catch(err => {
            scrollToTop();
            apiErrorHandler(err, displayMessage);
        });
    };

    // FLUXO PARA CADASTRAR A EMPRESA

    const registerCompany = (data) => {
        return Axios.post(props.getApiUrl('backoffice/mei'), data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateClientToken()
                }
            });
    };
    
    const showNewCompany = () => {
        props.setFilters({
            page: 0,
            cnpj: props.cnpj,
            status: 0,
            itemPerPage: 5,
        });
        const superSecretButton = document.getElementById('superSecretButton');
        props.resetCompanyData();
        if (superSecretButton) superSecretButton.click();
    };

    const verifyCompany = () => {
        setStatus('done');
        setFormReady(false);
        if (homolagatedCity) {
            Axios.get(props.getApiUrl(`backoffice/demanda/empresa/dadosobrigatorios/${props.cnpj}`), {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateClientToken()
                }
            }).then(
                response => {
                    if (response.data.dadosObrigatoriosPreenchidos) {
                        setMensagemSuccess('Empresa cadastrada com sucesso! ' +
                            'Você será redirecionado em poucos segundos para tela da empresa para emitir a nota teste.');
                        Axios.put(props.getApiUrl('backoffice/demanda'), {
                            status: 2,
                            demanda: codDemanda,
                            mensagem: 'cadastro da empresa concluido pelo backoffice',
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                Accept: 'application/json',
                                Authorization: validateClientToken()
                            }
                        }).then((response) => console.log(response));
                        Axios.post(props.getApiUrl('tomador/cadastrar'), {
                            cnpj: props.companySelected.cnpj,
                            email: props.email,
                            inscricaoMunicipal: props.companySelected.inscMunicipal,
                            telefone: props.telephone,
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                Accept: 'application/json',
                                Authorization: validateToken()
                            }
                        });
                    } else {
                        setRequiredFields(response.data.criticas);
                        setMensagemSuccess('Empresa cadastrada com sucesso, mas não há dados suficientes para emissão de ' +
                            'notas. Você pode completar o cadastro mais tarde na tela da empresa. ' +
                            '\n\nVocê será redirecionado em poucos segundos');
                    }
                    setShowCompanyButton(true);                    
                }
            ).catch(
                err => {
                    scrollToTop();
                    apiErrorHandler(err, displayMessage);
                }
            );
        } else {
            displayMessage('Empresa cadastrada com sucesso! Você será redirecionado em poucos segundos.');
            Axios.put(props.getApiUrl('backoffice/demanda'), {
                status: 2,
                demanda: codDemanda,
                mensagem: 'cadastro da empresa concluido pelo backoffice',
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateClientToken()
                }
            }).then(
                (response) => response
            );
            Axios.post(props.getApiUrl('tomador/cadastrar'), {
                cnpj: props.companySelected.cnpj,
                email: props.email,
                inscricaoMunicipal: props.companySelected.inscMunicipal,
                telefone: props.telephone,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateToken()
                }
            });
            props.setFilters({
                page: 0,
                cnpj: props.cnpj,
                status: 0,
                itemPerPage: 5,
            });
            props.resetCompanyData();
            const superSecretButton = document.getElementById('superSecretButton');
            superSecretButton.click();
        }
    };

    // HANDLE INPUTS CHANGE

    const handleCnpjChange = (value) => {
        const cnpj = value.cnpj.replace(/\D/g, '');
        props.saveCnpj(cnpj);
    };

    const handleFormInputChange = (e) => {
        const {name, value} = e.target;
        props.saveCompanyData({
            ...props.companyData,
            [name]: value
        });
    };

    const handleEmailChange = (value) => {
        props.saveEmail(value);
    };

    const handlePhoneChange = (value) => {
        props.saveTelephone(value);
    };

    const handleAddressChange = (event) => {
        const { name, value } = event.target;
        props.saveAddress({
            ...props.address,
            [name]: value,
        });
    };

    return (
        <main className="main">
            {
                (feedBackMessage.length > 0) && (
                    feedBackMessage.map((feedback, key) => (
                        <UserFeedBack
                            key={key}
                            band={true}
                            message={feedback.message}
                            alert={feedback.type === 'alert'}
                            error={feedback.type === 'error'}
                        />
                    ))
                )
            }
            {(status === 'working') && (
                <section className="company-detail">
                    <header className="company-detail__header">
                        <h1 className="page-title page-title_company">
                            Cadastro de Empresa
                        </h1>
                    </header>
                    <span className={'company-submitting'}>
                        <LoaderSpinner
                            width={60}
                            height={60}
                            type="TailSpin"
                            color="#002240"
                            visible={(status === 'working')}
                            style={{marginBottom: '1em', alignSelf: 'center',}}
                        />
                        <h3>Validando empresa, aguarde...</h3>
                    </span>
                </section>
            )}
            {(status !== 'working') && (
                <section className="company-detail">
                    <header className="company-detail__header">
                        <h1 className="page-title page-title_company">
                            Cadastro de Empresa
                        </h1>
                    </header>
                    <div>
                        <div
                            className="company-detail__section"
                        >
                            <div
                                className="company-detail__section-header"
                            >
                                <div className={'company-register-column'}>
                                    <div className="company-detail__section-content">
                                        <div className="company-register__row">
                                            <label className='label company-register-label_cnpj'>
                                                <input
                                                    className='input'
                                                    name='companyEmail'
                                                    type='text'
                                                    value={props.cnpj}
                                                    onChange={e =>
                                                        handleCnpjChange({ cnpj: e.target.value })
                                                    }
                                                />
                                                <p className='label__p label__p_float'>CNPJ</p>
                                            </label>
                                        </div>
                                        <div className="company-register__row">
                                            <label className='label company-register-label_cnpj checkbox_align'>
                                                <input
                                                    className='checkbox_input'
                                                    name='companyCache'
                                                    type='checkbox'
                                                    checked={cacheFreeConsult}
                                                    onChange={e =>{
                                                        setCacheFreeConsult(e.target.checked);
                                                    }}
                                                />
                                                <p className='label__p label__checkbox'>Recriar Cache</p>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div>
                                    {(status === '') &&
                                    <button
                                        className='btn btn_right'
                                        onClick={() => startCreationFlux()}
                                    >
                                        Cadastrar Empresa
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {(status === 'error') && (
                        <span className="company-error">
                            <FontAwesomeIcon icon={faTimes} className={'company-response-icon'}/>
                            {mensagemErro !== '' ? mensagemErro : 'Erro no cadastro da empresa, tente novamente.'}
                        </span>
                    )}
                    {(status === 'warning') && (
                        <span className="company-warning">
                            <FontAwesomeIcon icon={faExclamationTriangle} className={'company-response-icon'}/>
                            {mensagemAlerta}
                        </span>
                    )}
                    {(status === 'done') && (
                        <section className="company-success">
                            <FontAwesomeIcon icon={faCheck} className={'company-response-icon'}/>
                            {mensagemSuccess}
                            {showCompanyButton && (
                                <div className={'company-success-button'}>
                                    <button className='btn btn_ghost' onClick={() => showNewCompany()}>
                                        Ver nova empresa
                                    </button>
                                </div>
                            )}
                        </section>
                    )}
                    {(status === 'waitingCompany') && (
                        <section className="company-loading">
                            <LoaderSpinner
                                width={40}
                                height={40}
                                type="TailSpin"
                                color="#002240"
                                style={{marginBottom: '1em', alignSelf: 'center',}}
                            />
                            {loadingMessage}
                        </section>
                    )}
                    {(requiredFields.length > 0) && (
                        <span className="company-warning">
                            {
                                requiredFields.map((item, key) => (
                                    <div className={'required-field'} key={key}>
                                        <FontAwesomeIcon icon={faAsterisk} className={'required-field-icon'}/>
                                        {item.mensagem}
                                    </div>
                                ))
                            }
                        </span>
                    )}
                    { (formReady &&
                        <ValidatorForm onSubmit={submitCompany} instantValidate={true}>
                            <div className='company-detail__section'>
                                <div className='company-detail__section-content'>
                                    <div className='company-register__row'>
                                        <label className='label label_cnpj'>
                                            <TextValidator
                                                className='input'
                                                name='companyCNPJ'
                                                id='cnpjValueInput'
                                                type='text'
                                                value={props.cnpj}
                                                maxLength='18'
                                                autoComplete='off'
                                                readOnly
                                            />
                                            <p className='label__p label__p_float'>CNPJ</p>
                                        </label>
                                        <label className='label label_status'>
                                            <input
                                                className='input'
                                                value={props.companyData.situacaoCadastral}
                                                type='text'
                                                readOnly
                                            />
                                            <p className='label__p label__p_float'>Status</p>
                                        </label>
                                    </div>
                                    <div className='company-register__row'>
                                        <label className='label label-spacer'>
                                            <TextValidator
                                                className='input'
                                                name='inscricaoMunicipal'
                                                type='text'
                                                value={props.companyData.inscricaoMunicipal}
                                                onChange={handleFormInputChange}
                                            />
                                            <p className='label__p label__p_float'>
                                                Inscrição Municipal
                                            </p>
                                        </label>
                                        <label className='label label'>
                                            <TextValidator
                                                className='input'
                                                name='inscricaoEstadual'
                                                type='text'
                                                value={props.companyData.inscricaoEstadual}
                                                onChange={handleFormInputChange}
                                            />
                                            <p className='label__p label__p_float'>
                                                Inscrição Estadual
                                            </p>
                                        </label>
                                    </div>
                                    <div className='company-register__row'>
                                        <label className='label label_name'>
                                            <TextValidator
                                                className='input'
                                                name='nomeEmpresarial'
                                                type='text'
                                                value={props.companyData.nomeEmpresarial}
                                                onChange={handleFormInputChange}
                                            />
                                            <p className='label__p label__p_float'>Razão Social</p>
                                        </label>
                                        <label className='label label_name'>
                                            <TextValidator
                                                className='input'
                                                name='nomeFantasia'
                                                type='text'
                                                value={props.companyData.nomeFantasia}
                                                onChange={handleFormInputChange}
                                            />
                                            <p className='label__p label__p_float'>Nome Fantasia</p>
                                        </label>
                                    </div>
                                    <div className='company-register__row'>
                                        <label className='label label_email'>
                                            <TextValidator
                                                className='input'
                                                name='email'
                                                type='text'
                                                value={props.email}
                                                onChange={(e) => handleEmailChange(e.target.value)}
                                            />
                                            <p className='label__p label__p_float'>E-mail</p>
                                        </label>
                                        <label className='label label_phone'>
                                            <TextValidator
                                                className='input'
                                                name='telephone'
                                                type='tel'
                                                value={props.telephone}
                                                onChange={(e) => handlePhoneChange(e.target.value)}
                                                maxLength='15'
                                            />
                                            <p className='label__p label__p_float'>Fone</p>
                                        </label>
                                    </div>
                                    <div className='company-register__row'>
                                        <label className='label label_numero-rps'>
                                            <TextValidator
                                                className='input'
                                                name='numeroRPS'
                                                type='text'
                                                value={props.companyData.numeroRPS}
                                                onChange={handleFormInputChange}
                                            />
                                            <p className='label__p label__p_float'>Número do RPS</p>
                                        </label>
                                        <label className='label label_numero-lote'>
                                            <TextValidator
                                                className='input'
                                                name='numeroLote'
                                                type='text'
                                                value={props.companyData.numeroLote}
                                                onChange={handleFormInputChange}
                                            />
                                            <p className='label__p label__p_float'>Número do lote</p>
                                        </label>
                                        <label className='label label_serie'>
                                            <TextValidator
                                                className='input'
                                                name='serie'
                                                type='text'
                                                value={props.companyData.serie}
                                                onChange={handleFormInputChange}
                                            />
                                            <p className='label__p label__p_float'>Série</p>
                                        </label>
                                    </div>
                                    {   (usaRegimeEspecial && homolagatedCity) &&
                                        (<div className='company-register__row'>
                                            <label className='label label_regime-especial'>
                                                <select
                                                    className='input'
                                                    name='regimeEspecialSelecionado'
                                                    value={props.companyData.regimeEspecialSelecionado}
                                                    onChange={handleFormInputChange}
                                                >
                                                    <option value="">
                                                    Selecione um Regime
                                                    </option>
                                                    {
                                                        props.companyData.regimesEspecialTributacao.map(
                                                            (item, index) => (
                                                                <option value={item.codigo} key={index} data-cod={item.codigo}>
                                                                    { item.nome }
                                                                </option>
                                                            ))}
                                                </select>
                                                <p className='label__p label__p_float'>
                                                Regime Especial de Tributação
                                                    {/*{ companyUsaRegimeEspecialTributacao && '*' }*/}
                                                </p>
                                            </label>
                                        </div>)}
                                    <div className='company-register__row'>
                                        <label className='label label_codigo-servico label-spacer'>
                                            <input
                                                className='input'
                                                name='uf'
                                                type='text'
                                                value={props.address.uf}
                                                onChange={handleAddressChange}
                                            />
                                            <p className='label__p label__p_float'>Estado (UF)</p>
                                        </label>
                                        <label className='label label_codigo-servico'>
                                            <input
                                                className='input'
                                                name='city'
                                                type='text'
                                                value={props.address.municipio}
                                                onChange={handleAddressChange}
                                            />
                                            <p className='label__p label__p_float'>Cidade</p>
                                        </label>
                                    </div>
                                    <div className='company-register__row'>
                                        <label className='label label_codigo-servico label-spacer'>
                                            <input
                                                className='input'
                                                name='cep'
                                                type='text'
                                                value={props.address.cep}
                                                onChange={handleAddressChange}
                                            />
                                            <p className='label__p label__p_float'>CEP</p>
                                        </label>
                                        <label className='label label_codigo-servico'>
                                            <input
                                                className='input'
                                                name='logradouro'
                                                type='text'
                                                value={props.address.logradouro}
                                                onChange={handleAddressChange}
                                            />
                                            <p className='label__p label__p_float'>Logradouro</p>
                                        </label>
                                    </div>
                                    <div className='company-register__row'>
                                        <label className='label label_numero-rps'>
                                            <TextValidator
                                                className='input'
                                                name='numero'
                                                type='text'
                                                value={props.address.numero}
                                                onChange={handleAddressChange}
                                            />
                                            <p className='label__p label__p_float'>Número</p>
                                        </label>
                                        <label className='label label_numero-lote'>
                                            <TextValidator
                                                className='input'
                                                name='bairroDistrito'
                                                type='text'
                                                value={props.address.bairroDistrito}
                                                onChange={handleAddressChange}
                                            />
                                            <p className='label__p label__p_float'>Bairro/Distrito</p>
                                        </label>
                                        <label className='label label_serie'>
                                            <TextValidator
                                                className='input'
                                                name='complemento'
                                                type='text'
                                                value={props.address.complemento}
                                                onChange={handleAddressChange}
                                            />
                                            <p className='label__p label__p_float'>Complemento</p>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className='company-detail__section credential_section' >
                                <h2 className='invoice-detail__section-title' style={{marginTop: '2em'}}>
                                    Credencial da MEI
                                </h2>
                                <Credentials authType={authType} isEditing={true} isHomologated={homolagatedCity}/>
                            </div>

                            <CompanyOwner
                                editing
                                notLoad
                                ownerData={ownerData}
                                setOwnerData={setOwnerData}
                                submitCompany={() => false}
                                validateClientToken={validateClientToken}
                                getApiUrl={props.getApiUrl}
                            />

                            <div className='company-detail__section credential_section' >
                                <button className='btn btn_right' type='submit' style={{marginTop: '2em'}}>
                                    Salvar alterações
                                </button>
                            </div>
                        </ValidatorForm>
                    )}
                </section>
            )}
            {(showModal) && (
                <div className='ownersModal'>
                    <div className='ownersModal_container' style={{textAlign: 'center'}}>
                        <GrClose
                            className='ownerModalClose'
                            onClick={() => {
                                setShowModal(false);
                            }}
                        />
                        <h2> Mensagem </h2>
                        <h3>
                            {demandaMensagem}
                        </h3>
                        {demandaAction === 3 ? (
                            <button
                                className='owner_confirm_button'
                                onClick={(e) => {
                                    setShowModal(false);
                                }}
                            >
                                Ok
                            </button>
                        ) : demandaAction === 1 ? (
                            <button
                                className='owner_confirm_button'
                                onClick={(e) => {
                                    props.setFilters({
                                        page: 0,
                                        cnpj: props.cnpj,
                                        status: 0,
                                        itemPerPage: 5,
                                    });
                                    const superSecretButton = document.getElementById('superSecretButton');
                                    props.resetCompanyData();
                                    setShowModal(false);
                                    if (superSecretButton) superSecretButton.click();
                                }}
                            >
                                Ver dados da empresa
                            </button>
                        ) : ''}
                    </div>
                </div>
            )}
            <Link
                style={{display: 'none'}}
                id={'superSecretButton'}
                to='/empresas'
                title='empresas'
            />
        </main>
    );
};

const mapStateToProps = state => ({
    cnpj: state.newCompany.cnpj,
    email: state.newCompany.email,
    credentials: state.credentials,
    address: state.newCompany.address,
    telephone: state.newCompany.telephone,
    companyData: state.newCompany.companyData,
    companyRegistered: state.newCompany.companyRegistered,
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveCnpj: setCnpj,
        saveEmail: setEmail,
        setFilters: setFilters,
        saveAddress: setAddress,
        saveTelephone: setTelephone,
        saveCredentials: setCredential,
        saveCompanyData: setCompanyData,
        resetCompanyData: resetNewCompany,
        saveCompanyRegistered: setCompanyRegistered
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(NewCompany);
