import storage from 'redux-persist/lib/storage';
import { combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

//Reducers
import LoginReducer from '../reducers/login-reducer';
import InvoiceReducer from '../reducers/invoice-reducer';
import CompanyReducer from '../reducers/company-reducer';
import NewCompanyReducer from '../reducers/newCompany-reducer';
import CredentialReducer from '../reducers/credentials-reducer';

const persistConfig = {
    key: 'root',
    storage
};

const initialState = {
    login: {
        email: '',
        password: ''
    },
    invoice: {
        filters: {
            page: 0,
            cnpj: '',
            status: 0,
            itemPerPage: 5,
        },
        invoices: {},
        pagination: {
            currentPage: 0,
            numberOfPages: 0,
            totalItems: 0,
            pageLimit: 0
        },
        currentPage: 0,
        paginationList: {
            totalItems: 0,
            currentPage: 0,
            pageSize: 5,
            totalPages: 0,
            startPage: 0,
            endPage: 0,
            pages: 0
        }
    },
    company: {
        filters: {
            page: 0,
            cnpj: '',
            status: 0,
            itemPerPage: 5,
        },
        companies: {},
        pagination: {
            currentPage: 0,
            numberOfPages: 0,
            totalItems: 0,
            pageLimit: 0
        },
        currentPage: 0,
        paginationList: {
            totalItems: 0,
            currentPage: 0,
            pageSize: 5,
            totalPages: 0,
            startPage: 0,
            endPage: 0,
            pages: 0
        }
    },
    newCompany: {
        cnpj: '',
        address: {
            uf: '',
            cep: '',
            numero: '',
            municipio: '',
            logradouro: '',
            complemento: '',
            bairroDistrito: '',
        },
        email: '',
        telephone: '',
        companyRegistered: '',
        companyData: {
            AEDF: '',
            CNAE: [],
            serie: '',
            LC116: '',
            numeroRPS: '',
            LC116List: [],
            numeroLote: '',
            nomeFantasia: '',
            selectedCNAE: '',
            nomeEmpresarial: '',
            situacaoCadastral: '',
            inscricaoEstadual: '',
            inscricaoMunicipal: '',
            codigoServicoMunicipal: '',
            regimeEspecialSelecionado: '',
            regimesEspecialTributacao: [],
        }
    },
    credentials: {
        type: 2,
        user: '',
        token: '',
        authType: 0,
        password: '',
        secretPhrase: '',
        arquivoNome: null,
        arquivoBase64: null,
        filePassword: '',
    }
};

const RootReducer = combineReducers({
    login: LoginReducer,
    invoice: InvoiceReducer,
    company: CompanyReducer,
    newCompany: NewCompanyReducer,
    credentials: CredentialReducer
});

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(
    RootReducer,
    {
        ...initialState
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store);

export { persistor };
export default store;
