import React from 'react';

import Logo from './Logo/Logo';
import Company from './Company/Company';
import Avatar from './Avatar/Avatar';
import Navigation from './Navigation/Navigation';

import './header.css';

const Header = ({
    nfeToggle,
    companyToggle,
    avatarMenu,
    handleStateToggle,
    handleLogoff,
    companySelected,
    handleInputChange
}) => (
    <header className='header'>
        <div className='header__container'>
            <Logo />
            <Navigation
                nfeToggle={nfeToggle}
                companyToggle={companyToggle}
                handleStateToggle={handleStateToggle}
            />
            <Company companySelected={companySelected} handleInputChange={handleInputChange} />
            <Avatar
                avatarMenu={avatarMenu}
                handleStateToggle={handleStateToggle}
                handleLogoff={handleLogoff}
            />
        </div>
    </header>
);

export default Header;
