import React from 'react';
import { NavLink } from 'react-router-dom';

const Form = ({
    user,
    password,   
    loading,
    
    handleSubmit,
    handleInputChange,
}) => (
    <form 
        className='login__form'
        onSubmit={handleSubmit}
    >
        <label className='label label_login'>
            <input 
                className='input input_login' 
                name='user'
                value={user}
                onChange={handleInputChange}
                type='email' 
                required
            />    
            <p className='label__p label__p_float'>E-mail</p>
        </label>
        <label className='label label_login'>
            <input 
                className='input input_login' 
                name='password'
                value={password}
                onChange={handleInputChange}
                type='password' 
                required
            />    
            <p className='label__p label__p_float'>Senha</p>
        </label>     
        <NavLink className='login__link' to='/esqueci-senha'>esqueci minha senha</NavLink> 
        <button className={loading ? 'btn btn_login btn_center btn_loading' : 'btn btn_login btn_center'}>
            { 
                loading
                    ?   <div className='login__loader'>
                        Entrando...
                    </div>
                    : 'Entrar'
            }                
        </button>
    </form>
);

export default Form;