import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setCredential} from '../../../../actions/credentials-actions';

import Credentials from './Credentials';

const mapStateToProps = state => ({
    company: state.company,
    newCompany: state.newCompany,
    credentials: state.credentials
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        saveCredential: setCredential
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Credentials);
