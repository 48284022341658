import React from 'react';
import './error.css';

const Error = ({
    message
}) => (
    <div className="error">
        <p className="error__message"> { message } </p>
    </div>
);

export default Error;