import React, {useState} from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../../TextValidator/TextValidator';
import TextareaValidator from '../../TextareaValidator/TextareaValidator';
import './detail.css';
import iconArrowDown from '../../../Images/icon-arrow-down.svg';
import iconArrowDownWhite from '../../../Images/icon-arrow-down-white.svg';
import iconArrowLeft from '../../../Images/icon-arrow-left.svg';
import Loader from '../../Loader/Loader';
import Axios from 'axios';

import ConfirmTalaoManual from './ConfirmTalaoManual/ConfirmTalaoManual';

ValidatorForm.addValidationRule('isCPF', (val) => {
    const value = val.replace(/[^\d]+/g,'');

    if (value.length === 11) {
        let sum = 0;
        let mod;
        let i;

        if (value === '00000000000') return false;

        for (i = 1; i <= 9; i++) sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
        mod = (sum * 10) % 11;

        if ((mod === 10) || (mod === 11))  mod = 0;
        if (mod !== parseInt(value.substring(9, 10)) ) return false;

        sum = 0;
        for (i = 1; i <= 10; i++) sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);
        mod = (sum * 10) % 11;

        if ((mod === 10) || (mod === 11))  mod = 0;
        if (mod !== parseInt(value.substring(10, 11) ) ) return false;
        return true;
    } else if (value.length < 11) {
        return false;
    }
    return true;
});

ValidatorForm.addValidationRule('isCNPJ', (value) => {
    let cnpj = value.replace(/[^\d]+/g,'');

    if (cnpj.length === 14) {
        // Elimina CNPJs invalidos conhecidos
        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999')
            return false;

        // Valida DVs
        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0,size);
        let digits = cnpj.substring(size);
        let sum = 0;
        let pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== Number(digits.charAt(0)))
            return false;

        size = size + 1;
        numbers = cnpj.substring(0,size);
        sum = 0;
        pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== Number(digits.charAt(1)))
            return false;

        return true;
    } else if (cnpj.length < 14 && cnpj.length > 11) {
        return false;
    }
    return true;
});

ValidatorForm.addValidationRule('isCurrency', (value) => {
    const number = Number(value.replace('R$ ', ''));
    return number <= 0 ? false : true;
});


const CancelInvoiceDetail = ({
    invoiceProviderOpen,
    invoiceHirerOpen,
    invoiceDistinctionOpen,
    invoiceTaxesOpen,
    invoiceOtherInfoOpen,
    invoiceAttachmentOpen,
    handleCopyToClipboard,
    handleInvoiceDetailClose,
    validateCpfOrCnpj,
    invoiceServiceInfo,
    invoiceOtherInfo,
    handleInputChange,
    invoiceFilePhotoName,
    invoiceFilePDFName,
    invoiceFileXMLName,
    handleInvoiceDetailSection,
    detailNumeroNota,
    detailValorNota,
    detailDataEmissao,
    invoiceDetailReadOnly,
    validateCurrency,
    validatePhone,
    detailIDDemanda,
    detailPrestadorCpfCnpj,
    detailPrestadorRazao,
    detailPrestadorInscricao,
    detailPrestadorEndereco,
    detailPrestadorEstado,
    detailPrestadorCidade,
    detailPrestadorEmail,
    detailPrestadorPhone,
    detailPrestadorTipoCNPJ,
    detailTomadorCpfCnpj,
    detailTomadorRazao,
    detailTomadorInscricao,
    detailTomadorEndereco,
    detailTomadorEstado,
    detailTomadorCidade,
    detailTomadorEmail,
    detailTomadorPhone,
    detailTomadorTipoCNPJ,
    detailEncargoINSS,
    detailEncargoIRRF,
    detailEncargoCSLL,
    detailEncargoCOFINS,
    detailEncargoPISPASEP,
    detailEncargoCodServico,
    detailEncargoAliquota,
    detailEncargoISS,
    detailEncargoMunicipioPrestacao,
    dicasMEIMessage,
    detailPrefeituraHomologada,
    handleInputFileChange,
    invoiceFilePhotoError,
    invoiceFilePDFError,
    invoiceFileXMLError,
    invoiceDownloadFileOpen,
    detailFileXML,
    detailFilePDF,
    detailFilePhoto,
    invoiceServiceCNAE,
    invoiceServiceLC116,
    detailPrestadorNumero,
    detailPrestadorComplemento,
    detailPrestadorBairro,
    detailPrestadorCEP,
    detailTomadorNumero,
    detailTomadorComplemento,
    detailTomadorBairro,
    detailTomadorCEP,
    invoiceDetailRegisterLoading,
    handleSetInvoice,
    invoiceInscricaoOpen,
    detailInscricaoMunicipal,
    detailInscricaoEstadual,
    detailCredentialTokenUser,
    detailCredentialPassword,
    detailCertifiedPFX,
    invoiceCredentialOpen,
    handleCancelReasonChange,
    cancelNfeReason,
    handleCancelInvoice,
    cancelInProgress
}) => {

    const [showModal, setShowModal] = useState(false);
    const [talaoSucesso, setTalaoSucesso] = useState(null);
    const [talaoWarning, setTalaoWarning] = useState(null);

    const validateClientToken = () => {
        const expiresIn = localStorage.getItem('clientToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('clientToken');
            localStorage.removeItem('clientToken_expiration');
            localStorage.setItem('loggedIn', false);
            this.setState({loggedIn: false});
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('clientToken');
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalConfirm = () => {
        Axios.post('https://www.dicasmei.com.br/api/backoffice/nota/finaliza', {
            pdf: '',
            xml: '',
            status: '4',
            mensagem: '',
            id_demanda: detailIDDemanda
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': validateClientToken()
            }
        }).then(
            () => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setShowModal(false);
                setTalaoSucesso('Nota alterada para sem talao manual com sucesso!');
            }
        ).catch(
            () => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setShowModal(false);
                setTalaoWarning('Ocorreu um erro ao tentar alterar o status da nota, por favor, verifique sua conexao e tente novamente');
            }
        );
    };

    return (<div className='invoice-detail'>

        {showModal &&
        <ConfirmTalaoManual
            handleModalClose={handleModalClose}
            handleModalConfirm={handleModalConfirm}
        />}

        { dicasMEIMessage && <div className='dicasMEI-message'> { dicasMEIMessage } </div> }

        { talaoSucesso && <div className='talaoSucesso-message'> { talaoSucesso } </div> }

        { talaoWarning && <div className='talaoError-message'> { talaoWarning } </div> }

        <header className='invoice-detail__header'>
            <div className='invoice-detail__main-title'>
                <button className='invoice-detail__close' type='button' onClick={handleInvoiceDetailClose}>
                    <div className='invoice-detail__close-icon'>
                        <img src={iconArrowLeft} alt='Icone voltar' />
                    </div>
                    <span>Voltar</span>
                </button>
                <h1 className='page-title page-title_invoice-detail'>Cancelamento de Nota Fiscal</h1>
            </div>
        </header>

        { invoiceDetailRegisterLoading ||
            <ValidatorForm onSubmit={handleCancelInvoice}>
                <div className='invoice-detail__title'>
                    <label className='label'>
                        <TextValidator
                            className='input'
                            name='detailNumeroNota'
                            type='number'
                            value={detailNumeroNota}
                            validators={['isNumber']}
                            errorMessages={['Valor inválido!']}
                            onChange={handleInputChange}
                            readOnly
                        />
                        <p className='label__p label__p_float'>Número da Nota</p>
                    </label>
                    <label className='label'>
                        <TextValidator
                            className='input input_copy'
                            name='detailValorNota'
                            type='text'
                            value={detailValorNota}
                            onChange={validateCurrency}
                            onClick={handleCopyToClipboard}
                            readOnly
                        />
                        <p className='label__p label__p_float'>Valor da Nota (R$)</p>
                    </label>
                    <label className='label'>
                        <TextValidator
                            className='input'
                            name='detailDataEmissao'
                            type={invoiceDetailReadOnly ? 'text' : 'date'}
                            value={detailDataEmissao}
                            validators={['minStringLength:10']}
                            errorMessages={['Data inválida!']}
                            onChange={handleInputChange}
                            readOnly
                        />
                        <p className='label__p label__p_float'>Data de Emissão</p>
                    </label>
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceProviderOpen'onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Prestador de Serviço</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceProviderOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_invoice-provider'>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorCpfCnpj'
                                    type='text'
                                    onChange={validateCpfOrCnpj}
                                    value={detailPrestadorCpfCnpj}
                                    maxLength='18'
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CPF / CNPJ</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorRazao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorRazao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Nome / Razão Social</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorInscricao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorInscricao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Inscrição Municipal</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorEndereco'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorEndereco}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Endereço</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorNumero'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorNumero}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Número</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorComplemento'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorComplemento}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Complemento</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorBairro'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorBairro}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Bairro</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorCEP'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorCEP}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CEP</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorEstado'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorEstado}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Estado (UF)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorCidade'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorCidade}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Cidade</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorEmail'
                                    type='email'
                                    onChange={handleInputChange}
                                    value={detailPrestadorEmail}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>E-mail</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorPhone'
                                    type='tel'
                                    onChange={validatePhone}
                                    value={detailPrestadorPhone}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Fone</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorTipoCNPJ'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorTipoCNPJ}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Tipo CNPJ</p>
                            </label>
                        </div>
                    }
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceHirerOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Tomador de Serviço</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceHirerOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_invoice-provider'>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorCpfCnpj'
                                    type='text'
                                    onChange={validateCpfOrCnpj}
                                    value={detailTomadorCpfCnpj}
                                    maxLength='18'
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CPF / CNPJ</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorRazao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorRazao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Nome / Razão Social</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorInscricao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorInscricao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Inscrição Municipal</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorEndereco'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorEndereco}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Endereço</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorNumero'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorNumero}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Número</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorComplemento'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorComplemento}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Complemento</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorBairro'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorBairro}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Bairro</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorCEP'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorCEP}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CEP</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorEstado'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorEstado}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Estado (UF)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorCidade'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorCidade}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Cidade</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorEmail'
                                    type='email'
                                    onChange={handleInputChange}
                                    value={detailTomadorEmail}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>E-mail</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorPhone'
                                    type='tel'
                                    onChange={validatePhone}
                                    value={detailTomadorPhone}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Fone</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorTipoCNPJ'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorTipoCNPJ}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Tipo CNPJ</p>
                            </label>
                        </div>
                    }
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceDistinctionOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Discriminação dos serviços</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceDistinctionOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_distinction'>
                            <label className='label'>
                                <TextareaValidator
                                    name='invoiceServiceInfo'
                                    className='input input_copy'
                                    value={invoiceServiceInfo}
                                    onChange={handleInputChange}
                                    rows='5'
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Discriminação</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    name='invoiceServiceCNAE'
                                    className='input input_copy'
                                    value={invoiceServiceCNAE}
                                    onChange={handleInputChange}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CNAE</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    name='invoiceServiceLC116'
                                    className='input input_copy'
                                    value={invoiceServiceLC116}
                                    onChange={handleInputChange}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>LC116</p>
                            </label>
                        </div>
                    }
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceTaxesOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Encargos</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceTaxesOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_taxes'>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoINSS'
                                    type='text'
                                    value={detailEncargoINSS}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>INSS (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoIRRF'
                                    type='text'
                                    value={detailEncargoIRRF}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>IRRF (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoCSLL'
                                    type='text'
                                    value={detailEncargoCSLL}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CSLL (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoCOFINS'
                                    type='text'
                                    value={detailEncargoCOFINS}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>COFINS (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoPISPASEP'
                                    type='text'
                                    value={detailEncargoPISPASEP}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>PIS/PASEP (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoCodServico'
                                    type='number'
                                    value={detailEncargoCodServico}
                                    onChange={handleInputChange}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Cód. Serviço</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoAliquota'
                                    type='number'
                                    value={detailEncargoAliquota}
                                    onChange={handleInputChange}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Alíquota (%)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoISS'
                                    type='text'
                                    value={detailEncargoISS}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Valor de ISS (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoMunicipioPrestacao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailEncargoMunicipioPrestacao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Município da Prestação de Serviço</p>
                            </label>
                        </div>
                    }
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceOtherInfoOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Outras Informações</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceOtherInfoOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_other-info'>
                            <label className='label'>
                                <TextareaValidator
                                    name='invoiceOtherInfo'
                                    className='input input_copy'
                                    value={invoiceOtherInfo}
                                    onChange={handleInputChange}
                                    rows='5'
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Discriminação</p>
                            </label>
                        </div>
                    }
                </div>

                { (detailInscricaoMunicipal || detailInscricaoEstadual) && <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceInscricaoOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Inscrição Municipal/Estadual</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceInscricaoOpen &&  <div className='invoice-detail__section-content invoice-detail__section-content_inscricao'>
                        <label className='label'>
                            <TextValidator
                                className='input input_copy'
                                name='detailInscricaoMunicipal'
                                type='text'
                                value={detailInscricaoMunicipal}
                                onClick={handleCopyToClipboard}
                                readOnly
                                required
                            />
                            <p className='label__p label__p_float'>Inscrição Municipal</p>
                        </label>
                        <label className='label'>
                            <TextValidator
                                className='input input_copy'
                                name='detailInscricaoEstadual'
                                type='text'
                                value={detailInscricaoEstadual}
                                onClick={handleCopyToClipboard}
                                readOnly
                                required
                            />
                            <p className='label__p label__p_float'>Inscrição Estadual</p>
                        </label>
                    </div> }

                </div>  }

                { (detailCredentialTokenUser || detailCredentialPassword || detailCertifiedPFX) && <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceCredentialOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Credenciais de acesso</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceCredentialOpen && <div className='invoice-detail__section-content invoice-detail__section-content_credential'>
                        { detailCredentialTokenUser && <label className='label'>
                            <TextValidator
                                className='input input_copy'
                                name='detailCredentialTokenUser'
                                type='text'
                                value={detailCredentialTokenUser}
                                onClick={handleCopyToClipboard}
                                readOnly
                                required
                            />
                            <p className='label__p label__p_float'>Token/usuário de acesso à prefeitura</p>
                        </label>  }
                        { detailCredentialPassword && <label className='label'>
                            <TextValidator
                                className='input input_copy'
                                name='detailCredentialPassword'
                                type='text'
                                value={detailCredentialPassword}
                                onClick={handleCopyToClipboard}
                                readOnly
                                required
                            />
                            <p className='label__p label__p_float'>Senha de acesso à prefeitura</p>
                        </label> }
                        { detailCertifiedPFX && <a
                            className='invoice-detail__link-download'
                            rel="noopener noreferrer"
                            href={detailCertifiedPFX}
                            target='_blank'
                            download
                        >
                                Download Certificado (pfx)
                        </a> }
                    </div> }
                </div>  }

                { invoiceDetailReadOnly && <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceDownloadFileOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Download de arquivos</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceDownloadFileOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_download-file'>
                            { detailFileXML &&
                            <a
                                className='invoice-detail__link-download'
                                rel="noopener noreferrer"
                                href={detailFileXML}
                                target='_blank'
                                download>
                                Download XML
                            </a> }
                            { detailFilePDF &&
                            <a
                                className='invoice-detail__link-download'
                                rel="noopener noreferrer"
                                href={detailFilePDF}
                                target='_blank'>
                                Download PDF
                            </a> }
                            { detailFilePhoto &&
                            <a
                                className='invoice-detail__link-download'
                                rel="noopener noreferrer"
                                href={detailFilePhoto}
                                target='_blank'>
                                Download Imagem
                            </a> }
                        </div>
                    }
                </div>
                }


                <div id='attachment' className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceAttachmentOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>
                            { detailPrefeituraHomologada ? 'Cancelamento da NFSe' : '' }
                        </h2>
                        <div className='invoice-detail__section-icon'>
                            {invoiceAttachmentOpen ? (
                                <img className={'arrowUp'} src={iconArrowDown} alt='Ícone seta para baixo' />
                            ) : (
                                <img src={iconArrowDown} alt='Ícone seta para cima' />
                            )}
                        </div>
                    </div>

                    { (invoiceAttachmentOpen && detailPrefeituraHomologada) &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_cancelbox'>
                            <label className='label label_input label_textarea_cancel'>
                                <p className='label__p label__p_float'>Motivo</p>
                                <textarea
                                    className='input_textarea'
                                    name='cancelNfeReason'
                                    value={cancelNfeReason}
                                    onChange={(event) => handleCancelReasonChange(event)}
                                    required
                                />
                            </label>
                        </div>
                    }

                </div>

                { (invoiceAttachmentOpen && detailPrefeituraHomologada) &&
                <div className='invoice-detail__section-content invoice-detail__section-content_attachment'>
                    <label className='label label_input-pdf'>
                        <span className='input input_file-custom'>
                            { invoiceFilePDFName }
                        </span>
                        <p className='label__p label__p_float'>PDF Nota Fiscal</p>
                        <input
                            className='input_file'
                            name='invoiceFilePDF'
                            type='file'
                            onChange={handleInputFileChange}
                            accept='application/pdf'
                            data-accept='^.*\.(pdf|PDF)$'
                        />
                        { invoiceFilePDFError && <div className='error-message'> { invoiceFilePDFError } </div>}
                    </label>
                    <label className='label label_input-xml'>
                        <span className='input input_file-custom'>
                            { invoiceFileXMLName }
                        </span>
                        <p className='label__p label__p_float'>XML Nota Fiscal</p>
                        <input
                            className='input_file'
                            name='invoiceFileXML'
                            type='file'
                            onChange={handleInputFileChange}
                            accept='application/vnd.ms-excel, text/xml'
                            data-accept='^.*\.(xml|XML)$'
                        />
                        { invoiceFileXMLError && <div className='error-message'> { invoiceFileXMLError } </div>}
                    </label>
                </div>
                }

                {cancelInProgress ? <Loader message='Cancelando nota...' /> : (
                    <div className={'buttons_aligment'}>
                        <button
                            className='btn'
                            type='submit'
                            disabled={cancelInProgress}
                        >
                            Cancelar NFSe
                        </button>
                    </div>
                )}
            </ValidatorForm>
        }

        { invoiceDetailRegisterLoading && <Loader message='Registrando nota...' /> }
    </div>);};

export default CancelInvoiceDetail;
