import React, {useState} from 'react';
import {
    motion
} from 'framer-motion';
import {Link} from 'react-router-dom';

import './Navigation.css';
import iconArrowDown from '../../../Images/icon-arrow-down-white.svg';

const Navigation = () => {

    const [showNfeMenu, setShowNfeMenu] = useState(false);

    const [showCompanyMenu, setShowCompanyMenu] = useState(false);

    return (
        <nav className='navigation'>
            <ul className='navigation__list'>
                <button
                    className='navigation__dropdown__item navigation__item'
                    name='companyToggle'
                    onMouseOver={() => setShowCompanyMenu(true)}
                    onMouseLeave={() => setShowCompanyMenu(false)}
                    type='button'
                >
                    <div style={{height: '40px', padding: '10px', backgroundColor: showCompanyMenu ? '#45b347' : 'unset'}}>
                        <span className='navigation__dropdown__title'>
                            <span> Empresas </span>
                        </span>
                        <span className='navigation_dropdown_arrow-down'>
                            <motion.img animate={{rotate: showCompanyMenu ? 180 : 0}} style={{width: '15px'}} src={iconArrowDown} alt='Ícone seta para baixo'/>
                        </span>
                    </div>
                    <div className='dropdown_menu_container' style={{ width: '152px' }}>
                        {showCompanyMenu &&
                        <div className='dropdown__menu'>
                            <Link
                                className='dropdown__link'
                                to='/empresas'
                                title='empresas'
                            >
                                Lista
                            </Link>
                            <Link
                                className='dropdown__link'
                                to='/nova-empresa'
                                title='nova_empresa'
                            >
                                Nova Empresa
                            </Link>
                        </div>
                        }
                    </div>
                </button>
                <button
                    className='navigation__dropdown__item navigation__item'
                    name='nfeToggle'
                    onMouseOver={() => setShowNfeMenu(true)}
                    onMouseLeave={() => setShowNfeMenu(false)}
                    type='button'
                >
                    <div style={{height: '40px', padding: '10px', backgroundColor: showNfeMenu ? '#45b347' : 'unset'}}>
                        <span className='navigation__dropdown__title'>
                            <span> Notas Fiscais </span>
                        </span>
                        <span className='navigation_dropdown_arrow-down'>
                            <motion.img animate={{rotate: showNfeMenu ? 180 : 0}} style={{width: '15px'}} src={iconArrowDown} alt='Ícone seta para baixo'/>
                        </span>
                    </div>
                    <div className='dropdown_menu_container'>
                        {showNfeMenu &&
                        <div className='dropdown__menu'>
                            <Link
                                className='dropdown__link'
                                to='/notas-fiscais'
                                title='notas'
                            >
                                Lista de notas
                            </Link>
                            <Link
                                className='dropdown__link'
                                to='/notas-fiscais-me'
                                title='notas'
                            >
                                Lista de notas ME
                            </Link>
                            <Link
                                className='dropdown__link'
                                to='/cancelamento-nfe'
                                title='cancelamento_nota'
                            >
                                Cancelamento Manual
                            </Link>
                            <Link
                                className={'dropdown__link'}
                                to={'/relatorio'}
                                title={'relatório de notas fiscais'}
                            >
                                Relatório
                            </Link>
                        </div>
                        }
                    </div>
                </button>
            </ul>
        </nav>
    );
};

export default Navigation;
