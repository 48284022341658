import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch, Redirect } from 'react-router';
import { formatISO, addHours } from 'date-fns';
import LoginContainer from './Components/Login/LoginContainer';
import HeaderContainer from './Components/Header/HeaderContainer';
import CompanyContainer from './Components/Company/CompanyContainer';
import InvoiceContainer from './Components/Invoice/InvoiceContainer';
import CancelInvoiceContainer from './Components/Invoice/CancelInvoiceContainer';
import Footer from './Components/Footer/Footer';
import ForgotPasswordContainer from './Components/ForgotPassword/ForgotPasswordContainer';
import ResetPasswordContainer from './Components/ResetPassword/ResetPasswordContainer';
import UserFeedback from './Components/UserFeedback/UserFeedback';
import { HashRouter } from 'react-router-dom';
import NewCompany from './Components/Company/NewCompany/NewCompany';
import {getAvailablesCompanies} from './Components/CompanyHandler';
import Chart from './Components/Charts';
import InvoiceMeContainer from './Components/Invoice/InvoiceMeContainer';

class App extends Component {

    constructor() {
        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };

        super();

        this.state = {
            loggedIn: localStorage.getItem('loggedIn') === 'true' ? true : false,
            errorMessage: '',
            nfeToggle: false,
            avatarMenu: false,
            successMessage: '',
            companyToggle: false,
            companySelected: getAvailablesCompanies('Avon')
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogoff = this.handleLogoff.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStateToggle = this.handleStateToggle.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.getDicasMEIToken();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.state.companyToggle) {
            setTimeout(() => this.setState({ companyToggle: false }), 250);
        }
        else if (this.state.avatarMenu) {
            setTimeout(() => this.setState({ avatarMenu: false }), 250);
        }
        else if (this.state.nfeToggle) {
            setTimeout(() => this.setState({ nfeToggle: false }), 250);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    async fetchDicasMEIToken() {
    //const response = await fetch("http://dicasmei.localhost/api/login_check", {
        const response = await fetch(
            `${this.getApiUrl('login_check')}`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: 'api_user',
                    password: 'pZ25ShU2DRo3gxYf'
                })
            }
        );
        const data = await response.json();
        return data;
    }

    handleFetchError(response) {
        if (response.code) throw new Error(response.message);
        return response;
    }

    handleError(error) {
        this.setState({
            loading: false,
            errorMessage: error.message
        });

        setTimeout(() => this.setState({ errorMessage: '' }), 8000);
    }

    getDicasMEIToken() {
        this.fetchDicasMEIToken()
            .then(this.handleFetchError)
            .then(response => {
                const dicasMEIToken = `Bearer ${response.token}`;
                localStorage.setItem('dicasMEIToken', dicasMEIToken);
                localStorage.setItem('dicasMEIToken_expiration', formatISO(addHours(new Date(), 1)));
            })
            .catch(error => this.handleError(error));
    }

    handleLogoff() {
        localStorage.setItem('loggedIn', false);
        localStorage.removeItem('clientToken');
        localStorage.removeItem('clientToken_expiration');
        this.setState({ loggedIn: false });
    }

    handleLogin(clientToken, dicasMEIToken) {
        localStorage.setItem('loggedIn', true);
        localStorage.setItem('clientToken', clientToken);
        localStorage.setItem('clientToken_expiration', formatISO(addHours(new Date(), 1)));
        this.setState({ loggedIn: true });
    }

    getApiUrl(endpoint) {
        return `https://www.dicasmei.com.br/api/${endpoint}`;
        // return `https://dev.dicasmei.com.br/api/${endpoint}`;
        //return `http://dicasmei.localhost/api/${endpoint}`;
    }

    handleStateToggle(event) {
        const target = event.currentTarget;
        const name = target.name;
        this.setState({ [name]: !(this.state[name]) });
    }

    render() {
        return (
            <HashRouter>
                <div className="app">
                    {this.state.errorMessage && (
                        <UserFeedback
                            message={this.state.errorMessage}
                            band={true}
                            error={true}
                        />
                    )}
                    {this.state.successMessage && (
                        <UserFeedback
                            message={this.state.successMessage}
                            band={true}
                            success={true}
                        />
                    )}

                    {this.state.loggedIn ? (
                        <HeaderContainer
                            nfeToggle={this.state.nfeToggle}
                            handleLogoff={this.handleLogoff}
                            avatarMenu={this.state.avatarMenu}
                            companyToggle={this.state.companyToggle}
                            handleStateToggle={this.handleStateToggle}
                            handleInputChange={this.handleInputChange}
                            companySelected={this.state.companySelected}
                        />
                    ) : null}

                    <Switch>
                        <Route
                            exact
                            path="/esqueci-senha"
                            render={() =>
                                this.state.loggedIn ? (
                                    <Redirect to="/empresas" />
                                ) : (
                                    <ForgotPasswordContainer handleLogoff={this.handleLogoff} />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/redefinir-senha"
                            render={() =>
                                this.state.loggedIn ? (
                                    <Redirect to="/empresas" />
                                ) : (
                                    <ResetPasswordContainer />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/"
                            render={() =>
                                this.state.loggedIn ? (
                                    <Redirect to="/empresas" />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/empresas"
                            render={() =>
                                this.state.loggedIn ? (
                                    <CompanyContainer
                                        handleLogin={this.handleLogin}
                                        companySelected={this.state.companySelected}
                                        handleLogoff={this.handleLogoff}
                                        loggedIn={this.state.loggedIn}
                                        getApiUrl={this.getApiUrl}
                                    />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/nova-empresa"
                            render={() =>
                                this.state.loggedIn ? (
                                    <NewCompany
                                        handleNewCompany={this.handleNewCompany}
                                        getApiUrl={this.getApiUrl}
                                        companySelected={this.state.companySelected}
                                    />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/notas-fiscais"
                            render={() =>
                                this.state.loggedIn ? (
                                    <InvoiceContainer
                                        handleLogin={this.handleLogin}
                                        companySelected={this.state.companySelected}
                                        handleLogoff={this.handleLogoff}
                                        loggedIn={this.state.loggedIn}
                                        getApiUrl={this.getApiUrl}
                                    />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/notas-fiscais-me"
                            render={() =>
                                this.state.loggedIn ? (
                                    <InvoiceMeContainer
                                        handleLogin={this.handleLogin}
                                        companySelected={this.state.companySelected}
                                        handleLogoff={this.handleLogoff}
                                        loggedIn={this.state.loggedIn}
                                        getApiUrl={this.getApiUrl}
                                    />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/cancelamento-nfe"
                            render={() =>
                                this.state.loggedIn ? (
                                    <CancelInvoiceContainer
                                        handleLogin={this.handleLogin}
                                        companySelected={this.state.companySelected}
                                        handleLogoff={this.handleLogoff}
                                        loggedIn={this.state.loggedIn}
                                    />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            }
                        />

                        <Route
                            exact
                            path={'/relatorio'}
                            render={() => (
                                <Chart
                                    getApiUrl={this.getApiUrl}
                                    companySelected={this.state.companySelected}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/login"
                            render={() =>
                                this.state.loggedIn ? (
                                    <Redirect to="/empresas" />
                                ) : (
                                    <LoginContainer handleLogin={this.handleLogin} />
                                )
                            }
                        />
                    </Switch>

                    <Footer />
                </div>
            </HashRouter>
        );
    }
}

export default App;
