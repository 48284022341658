import {
    SET_CREDENTIAL
} from '../actions/credentials-actions';

export default (state  = '', { type, payload }) => {
    switch (type) {
    case SET_CREDENTIAL:
        return {
            ...state,
            ...payload
        };
    default:
        return state;
    }
};
