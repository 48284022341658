import React, {useEffect, useState, useReducer} from 'react';
import {Prompt} from 'react-router';
import {ValidatorForm} from 'react-form-validator-core';
import TextValidator from '../../TextValidator/TextValidator';
import LoaderSpinner from 'react-loader-spinner';
import iconArrowLeft from '../../../Images/icon-arrow-left.svg';
import {BsQuestionCircle} from 'react-icons/all';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes, faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';
import {
    drop,
    union
} from 'lodash';
import {
    GrClose
} from 'react-icons/gr';
import './register.css';
import Axios from 'axios';
import ReactJson from 'react-json-view';
import {bindActionCreators} from 'redux';
import {
    setAddress,
    setCnpj,
    setCompanyData,
    setCompanyRegistered,
    setEmail,
    setTelephone
} from '../../../actions/newCompany-actions';
import {setCredential} from '../../../actions/credentials-actions';
import {connect} from 'react-redux';
import UserFeedBack from '../../UserFeedback/UserFeedback';
import Credentials from '../NewCompany/Credentials/CredentialsContainer';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import {find} from 'lodash';
import {stateToHTML} from 'draft-js-export-html';
import { ContentState, convertFromHTML } from 'draft-js';
import {
    motion,
    AnimatePresence
} from 'framer-motion';
import errorHandler from '../../Global/ErrorHandler';

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CompanyOwner from '../../Global/CompanyOwner';

ValidatorForm.addValidationRule('isCNPJ', (value) => {
    let cnpj = value.replace(/[^\d]+/g,'');

    if (cnpj.length === 14) {
        // Elimina CNPJs invalidos conhecidos
        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999')
            return false;

        // Valida DVs
        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0,size);
        let digits = cnpj.substring(size);
        let sum = 0;
        let pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== Number(digits.charAt(0)))
            return false;

        size = size + 1;
        numbers = cnpj.substring(0,size);
        sum = 0;
        pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== Number(digits.charAt(1)))
            return false;

        return true;
    } else if (cnpj.length < 14 && cnpj.length > 11) {
        return false;
    }
    return true;
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveCnpj: setCnpj,
        saveEmail: setEmail,
        saveAddress: setAddress,
        saveTelephone: setTelephone,
        saveCredentials: setCredential,
        saveCompanyData: setCompanyData,
        saveCompanyRegistered: setCompanyRegistered
    }, dispatch);
};

const mapStateToProps = state => ({
    cnpj: state.newCompany.cnpj,
    email: state.newCompany.email,
    credentials: state.credentials,
    address: state.newCompany.address,
    telephone: state.newCompany.telephone,
    companyData: state.newCompany.companyData,
    selectedCompany: state.company.currentCompany.cnpj,
    companyRegistered: state.newCompany.companyRegistered,
    selectedCompanyDemanda: state.company.currentCompany.coddemanda,
});

const Register = (props) => {

    const [status, setStatus] = useState('');
    const [authType, setAuthType] = useState(0);
    const [copied, setCopied] = useState(false);
    const [nfeJson, setNfeJson] = useState(null);
    const [cnaeList, setCnaeList] = useState([]);
    const [editing, setEditing] = useState(false);
    const [useAEDF, setUseAEDF] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [codDemanda, setCodDemanda] = useState('');
    const [testResult, setTestResult] = useState(null);
    const [noteAttempts, setNoteAttempts] = useState(0);
    const [receiptResponse, setReceiptResponse] = useState({
        cod: null,
        message: null
    });
    const [mensagemErro, setMensagemErro] = useState('');
    const [cityHelpText, setCityHelpText] = useState('');
    const [showNfeJson, setShowNfeJson] = useState(false);
    const [sendingTest, setSendingTest] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [cityHelpShow, setCityHelpShow] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [requiredFields, setRequiredFields] = useState([]);
    const [feedBackMessage, setFeedBackMessage] = useState([]);
    const [processingTest, setProcessingTest] = useState(false);
    const [homologatedCity, setHomologatedCity] = useState(false);
    const [usaRegimeEspecial, setUsaRegimeEspecial] = useState('');
    const [cityHelperResult, setCityHelperResult] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [currentCompanyData, setCurrentCompanyData] = useState({});
    const [currentCompanyCityId, setCurrentCompanyCityId] = useState(null);
    const [lc116default, setLc116Default] = useState('Selecione a atividade');

    const [ownerData, setOwnerData] = useReducer((state, userState) => ({ ...state, ...userState }), {
        cpf: '',
        name: '',
        code: '',
        email: '',
        change: {
            cpf: '',
            email: '',
        }
    });

    const [mei, setMei] = useState(true);

    const toolBarOptions = [
        'inline',
        'blockType',
        'list',
    ];

    const toolBarInline = {
        options: ['bold', 'italic', 'underline', 'monospace', ]
    };

    useEffect(() => {
        if (currentCompanyCityId) {
            getCityHelper(currentCompanyCityId)
                .then(response => {
                    const htmlState = convertFromHTML(response.data.descricao_backoffice);
                    const state = ContentState.createFromBlockArray(
                        htmlState.contentBlocks,
                        htmlState.entityMap
                    );
                    handleEditorStateChange(EditorState.createWithContent(state));
                })
                .catch(response => {
                    if (response &&
                        response.response &&
                        response.response.data &&
                        response.response.data.descricao_backoffice)
                    {
                        const htmlState = convertFromHTML(response.response.data.descricao_backoffice);
                        const state = ContentState.createFromBlockArray(
                            htmlState.contentBlocks,
                            htmlState.entityMap
                        );
                        handleEditorStateChange(EditorState.createWithContent(state));
                    }
                });
        }
    }, [currentCompanyCityId]);

    useEffect(() => {
        if (currentCompanyData.cnpj || currentCompanyData.existingCompany) {
            const companyUf = (
                currentCompanyData.cnpj &&
                currentCompanyData.cnpj.endereco &&
                currentCompanyData.cnpj.endereco.uf) ?
                currentCompanyData.cnpj.endereco.uf :
                currentCompanyData.existingCompany ?
                    currentCompanyData.existingCompany.estado : '';
            const companyCity = (
                currentCompanyData.cnpj &&
                currentCompanyData.cnpj.endereco &&
                currentCompanyData.cnpj.endereco.municipio) ?
                currentCompanyData.cnpj.endereco.municipio :
                currentCompanyData.existingCompany ?
                    currentCompanyData.existingCompany.cidade : '';
            getUf().then(response => {
                if (find(response.data, ['dessigla', companyUf])) {
                    getCity(find(response.data, ['dessigla', companyUf]).codigo)
                        .then(response => {
                            if (find(response.data.map(item => {
                                item.desnome = item.desnome.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                                return item;
                            }), ['desnome', companyCity.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')])) {
                                const companyCityId = find(response.data.map(item => {
                                    item.desnome = item.desnome.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                                    return item;
                                }), ['desnome', companyCity.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')]).codigo;
                                setCurrentCompanyCityId(companyCityId);
                            }
                        });
                }
            });
        }
    }, [currentCompanyData]);

    useEffect(() => {
        if (codDemanda !== '') {
            verifyCompany();
        }
    }, [codDemanda]);

    useEffect(() => {
        if (transactionId !== '') {
            setStatus('waitingCompany');
            setLoadingMessage('Obtendo dados da empresa...');
            setTimeout(() => listenerCnpj(), 5000);
        }
    }, [transactionId]);

    useEffect(() => {
        if (props.selectedCompany !== '') {
            startCreationFlux();
        }
    }, [props.selectedCompany]);

    const displayMessage = (errorMessage, type = null) => {
        const messageObj = {
            type: type,
            message: errorMessage.toString()
        };
        setFeedBackMessage(union(feedBackMessage, [messageObj]));
        setTimeout(() => {
            setFeedBackMessage(drop(feedBackMessage));
        }, 12000);
    };

    const getUf = () => {
        return Axios.get(props.getApiUrl('endereco/estado'), {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateToken()
            }
        });
    };

    const getCity = (uf) => {
        return Axios.get(props.getApiUrl(`endereco/estado/${uf}/cidades`), {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateToken()
            }
        });
    };

    const getCityHelper = (cityId) => {
        return Axios.get(props.getApiUrl(`backoffice/cidade/dados/${cityId}`), {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateClientToken()
            }
        });
    };

    const sendCityHelper = (cityId, text) => {
        return Axios.post(props.getApiUrl('backoffice/cidade/dados'), {
            codigo: cityId,
            descricao_backoffice: text
        }, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateClientToken()
            }
        });
    };

    const fetchNfeJson = (nfeId) => {
        props.getNfeJson(nfeId)
            .then(response => {
                setNfeJson(response.data);
            })
            .catch(error => {
                errorHandler(error, displayMessage);
            });
    };

    const verifyTransactionStatus = (transaction_id) => {
        props.verifyReceiptTest(transaction_id)
            .then(
                response => {
                    if (!nfeJson) {
                        fetchNfeJson(transaction_id);
                    }
                    // Salvar resultados
                    setReceiptResponse({
                        cod: response.data.Transaction_Return.transaction_codigo,
                        message: response.data.Transaction_Return.transaction_mensagem[0]
                    });
                    // Caso ainda a nota ainda não tenha entrado na fila, caso não, verificar novamente após 2 segundos
                    if (response.data.Transaction_Return.transaction_codigo === 0) {
                        setSendingTest(false);
                        setTestResult('Aguardando entrar na fila');
                        setTimeout(() => verifyTransactionStatus(transaction_id), 2000);
                        return;
                    }
                    else if (response.data.Transaction_Return.transaction_codigo === 1) {
                        // Caso o status da nota seja PENDENTE, verificar novamente a cada 5 segundos
                        setTimeout(() => verifyTransactionStatus(transaction_id), 5000);
                    }
                    else if (response.data.Transaction_Return.transaction_codigo === 2 ||
                        response.data.Transaction_Return.transaction_codigo === 5 ||
                        response.data.Transaction_Return.transaction_codigo === 6 ||
                        response.data.Transaction_Return.transaction_codigo === 500) {
                        props.setMeiSituation(false)
                            .then(() => {
                                fetchNfeJson(transaction_id);
                                setProcessingTest(false);
                            })
                            .catch(error => {
                                errorHandler(error, displayMessage);
                                setSendingTest(false);
                                setProcessingTest(false);
                            });
                    }
                    else if (response.data.Transaction_Return.transaction_codigo === 3) {
                        // Após cancelada e válidada retorna sucesso.
                        setProcessingTest(false);
                        setTestResult('Sucesso!');
                        return;
                    }
                    else if (response.data.Transaction_Return.transaction_codigo === 4) {
                        // Caso a nota tenha sido emitida com sucesso, chamar função de cancelamento da nota
                        props.setMeiSituation()
                            .then(() => {
                                setProcessingTest(false);
                                setTestResult('Nota emitida com sucesso!');
                            })
                            .catch(error => {
                                errorHandler(error, displayMessage);
                                setSendingTest(false);
                                setProcessingTest(false);
                            });
                        return;
                    }
                    let mensagemResposta = '';
                    (isObject(response.data.Transaction_Return.transaction_mensagem)) ?
                        response.data.Transaction_Return.transaction_mensagem.map(itens => {
                            isObject(itens) ? forEach(itens, (value, key) => {
                                mensagemResposta = mensagemResposta + ` ${value}`;
                            }) : mensagemResposta = mensagemResposta + ` ${itens}`;
                        }) : mensagemResposta = mensagemResposta + ` ${response.data.Transaction_Return.transaction_mensagem[0]}`;
                    setTestResult(mensagemResposta);
                    setSendingTest(false);
                }
            ).catch((error) => {
                let errorMessage = 'Erro: ';
                if (error &&
                error.response &&
                error.response.data &&
                error.response.data.Transaction_Return &&
                error.response.data.Transaction_Return.transaction_mensagem)
                {
                    (isObject(error.response.data.Transaction_Return.transaction_mensagem)) ?
                        error.response.data.Transaction_Return.transaction_mensagem.map(message => {
                            errorMessage = errorMessage + ` ${message}`;
                        }) : errorMessage = errorMessage + ` ${error.response.data.Transaction_Return.transaction_mensagem}`;
                } else if (
                    error.response &&
                    error.response.data &&
                    error.response.data.mensagem
                ) {
                    if (error.response.data.mensagem[0] && error.response.data.mensagem[0].mensagem) {
                        errorMessage = errorMessage + ` ${error.response.data.mensagem[0].mensagem}`;
                    } else {
                        errorMessage = errorMessage + ` ${error.response.data.mensagem}`;
                    }
                } else {
                    if (error.response.error) {
                        errorMessage = errorMessage + ` ${error.response.error}`;
                    } else if (error.response.data.error) {
                        errorMessage = errorMessage + ` ${error.response.data.error}`;
                    } else {
                        errorMessage = errorMessage + ` ${error}`;
                    }
                }
                setReceiptResponse({
                    cod: 7,
                    message: 'Erro interno'
                });
                setTestResult(errorMessage);
                setSendingTest(false);
                setProcessingTest(false);
            });
    };

    const handleTestReceiptSend = (e) => {
        e.preventDefault();
        setTestResult(null);
        setSendingTest(true);
        setProcessingTest(true);
        setNoteAttempts(noteAttempts + 1);
        if (noteAttempts <= 2) {
            props.fetchTestReceipt()
                .then(
                    response => {
                        setTimeout(() => verifyTransactionStatus(response.data.TransactionID), 2000);
                    }
                ).catch(
                    (error) => {
                        let errorMessage = '';
                        if (error &&
                        error.response &&
                        error.response.data)
                        {
                            if (error.response.data.Transaction_Return &&
                                error.response.data.Transaction_Return.transaction_mensagem) {
                                errorMessage = errorMessage + '\nErro:';
                                (isObject(error.response.data.Transaction_Return.transaction_mensagem)) ?
                                    error.response.data.Transaction_Return.transaction_mensagem.map(message => {
                                        errorMessage = errorMessage + ` ${message}`;
                                    }) : errorMessage = errorMessage + ` ${error.response.data.Transaction_Return.transaction_mensagem}`;
                            } else if (error.response.data.mensagem) {
                                if (error.response.data.mensagem[0]) {
                                    errorMessage = error.response.data.mensagem[0].mensagem;
                                } else {
                                    errorMessage = error.response.data;
                                }
                            }
                        }
                        if (
                            errorMessage.toLowerCase().match('tomador') &&
                        !errorMessage.toLowerCase().match('invalido')) {
                            props.setCompanyTomador();
                            handleTestReceiptSend(e);
                        } else {
                            errorMessage = (errorMessage === '') ?
                                errorHandler(error, displayMessage) :
                                errorMessage;
                            setReceiptResponse({
                                cod: 7,
                                message: 'Erro interno'
                            });
                            setTestResult(errorMessage);
                            setSendingTest(false);
                            setProcessingTest(false);
                        }
                    }
                );
        } else {
            setReceiptResponse({
                cod: 7,
                message: 'Erro interno'
            });
            setTestResult('Ocorreu um erro ao cadastrar o tomador da nota, favor contactar os desenvolvedores.');
            setSendingTest(false);
            setProcessingTest(false);
        }
    };

    const __renderIcon = () => {
        switch (receiptResponse.cod) {
        case 4:
            return <FontAwesomeIcon
                size={'2x'}
                icon={faCheck}
                style={{marginRight: '10px', alignSelf: 'center',  color: 'green'}}
            />;
        case 0:
        case 1:
            return null;
        default:
            return <FontAwesomeIcon
                size={'2x'}
                icon={faTimes}
                style={{marginRight: '10px', alignSelf: 'center', color: 'red'}}
            />;
        }
    };

    const handleBackButton = (e) => {
        if (processingTest && window.confirm('Emissão de nota teste em andamento,' +
            ' deixar esta página poderá causar problemas. Deseja realmente sair?')) {
            props.handleCloseRegister(e);
        } else if (!processingTest) {
            props.handleCloseRegister(e);
        }
    };

    const validateToken = () => {
        const expiresIn = localStorage.getItem('dicasMEIToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('dicasMEIToken');
            localStorage.removeItem('dicasMEIToken_expiration');
            localStorage.setItem('loggedIn', false);
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('dicasMEIToken');
    };

    const validateClientToken = () => {
        const expiresIn = localStorage.getItem('clientToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('clientToken');
            localStorage.removeItem('clientToken_expiration');
            localStorage.setItem('loggedIn', false);
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('clientToken');
    };

    // FLUXO PARA OBTER DADOS DA EMPRESA

    const startCreationFlux = async (clean = false) => {
        if (props.selectedCompany === '') return;
        let activeCnpj = true;
        setStatus('working');
        await validadeCnpj(clean)
            .then(async (response) => {
                await setTransactionId(response.data.TransactionID);
            })
            .catch((err) => {
                activeCnpj = false;
                errorHandler(err, displayMessage);
            });
        if (!activeCnpj) return;
    };

    const checkCnpj = async () => {
        return Axios.get(props.getApiUrl(`backoffice/mei/${props.selectedCompany}`), {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateClientToken()
            }
        });
    };

    const validadeCnpj = (uncache) => {
        const cnpj = props.selectedCompany;
        return Axios.post(`${props.getApiUrl('cnpj/solicita')}`, {
            CNPJ: cnpj,
            force: uncache,
            tp_consulta: 'X',
            cartaoCNPJ: true,
            ccc: false,
            simplesNacional: true,
            cpom: false,
            ccm: false,
            origem: 'BACKOFFICE'
        },{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateToken()
            }
        });
    };

    const listenerCnpj = async () => {
        await getCnpjValidation()
            .then(async (res) => {
                if (res && res.data && res.data.dados && res.data.dados.hasOwnProperty('mei')) {
                    setMei(res.data.dados.mei);
                }
                if (res && res.data && res.data.Transaction_Return) {
                    switch (res.data.Transaction_Return.cod) {
                    case 99:
                        await checkCnpj().then(
                            async response => {
                                populateForm({
                                    cnpj: res.data.dados.cartaoNacionalPessoaJuridica,
                                    existingCompany: response.data,
                                    AEDF: response.data.AEDF
                                });
                                if (response.data.proprietario) {
                                    setOwnerData({
                                        cpf : response.data.proprietario.cpf,
                                        name : response.data.proprietario.nome,
                                        email : response.data.proprietario.email,
                                        code : response.data.proprietario.codusuario
                                    });
                                }
                            }
                        ).catch(
                            err => {
                                setNotFound(true);
                                errorHandler(err, displayMessage);
                            }
                        );
                        return;
                    case 1:
                    case 2:
                        setStatus('waitingCompany');
                        setLoadingMessage('Obtendo dados da empresa...');
                        setTimeout(() => listenerCnpj(), 5000);
                        break;
                    default:
                        setStatus('error');
                        setMensagemErro(res.data.Transaction_Return.message);
                    }
                }
            })
            .catch((error) => {
                errorHandler(error, displayMessage);
            });
    };

    const getCnpjValidation = () => {
        return Axios.post(`${props.getApiUrl('cnpj/consulta')}`, {
            TransactionID: transactionId
        },{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateToken()
            }
        });
    };

    const populateForm = async (data) => {
        setCurrentCompanyData(data);
        const cnaeArray = data.cnpj.atividadesEconomicasSecundarias;
        cnaeArray.push(data.cnpj.atividadeEconomicaPrimaria);
        setCnaeList(cnaeArray);
        const address = {
            uf: data.cnpj ? data.cnpj.endereco.uf : data.existingCompany ? data.existingCompany.estado : '',
            cep: data.cnpj ? data.cnpj.endereco.cep : data.existingCompany ? data.existingCompany.cep : '',
            numero: data.cnpj ? data.cnpj.endereco.numero : data.existingCompany ? data.existingCompany.numero : '',
            municipio: data.cnpj ? data.cnpj.endereco.municipio : data.existingCompany ? data.existingCompany.cidade : '',
            logradouro: data.cnpj ? data.cnpj.endereco.logradouro : data.existingCompany ? data.existingCompany.endereco : '',
            complemento: data.cnpj ? data.cnpj.endereco.complemento : data.existingCompany ? data.existingCompany.complemento : '',
            bairroDistrito: data.cnpj ? data.cnpj.endereco.bairroDistrito : data.existingCompany ? data.existingCompany.bairro : '',
        };
        const listaLC116 = await fetchLC116(cnaeArray[cnaeArray.length-1].codigo);
        await fetchCredentialType(address.uf, address.municipio, {
            token: data.existingCompany ? data.existingCompany.destoken: '',
            user: data.existingCompany ? data.existingCompany.desusuario : '',
            password: data.existingCompany ? data.existingCompany.dessenha : '',
        });
        await props.saveCompanyData({
            ...props.companyData,
            ...data,
            CNAE: cnaeArray,
            selectedCNAE: cnaeArray[cnaeArray.length-1].codigo,
            AEDF: data.AEDF ? data.AEDF : '',
            codmei: (data.existingCompany && data.existingCompany.codmei) ? data.existingCompany.codmei: '',
            situacaoCadastral: data.cnpj.situacaoCadastral,
            codusuario: (data.existingCompany && data.existingCompany.proprietario
                && data.existingCompany.proprietario.codusuario) ? data.existingCompany.proprietario.codusuario : '',
            serie: data.existingCompany ? data.existingCompany.serie_nfe : '',
            numeroRPS: data.existingCompany ? data.existingCompany.sequencial_nfe : '',
            LC116: data.existingCompany ? data.existingCompany.item_lista_servicolc116 : '',
            LC116List: listaLC116,
            numeroLote: data.existingCompany ? data.existingCompany.sequencial_lote_nfe : '',
            inscricaoEstadual: data.existingCompany ? data.existingCompany.inscricaoestadual : '',
            inscricaoMunicipal: data.existingCompany ? data.existingCompany.inscricaomunicipal.trim() : '',
            nomeFantasia: data.cnpj ? data.cnpj.nomeFantasia : data.existingCompany ? data.existingCompany.razaosocial : '',
            codigoServicoMunicipal: data.existingCompany ? data.existingCompany.descodigoservicomunicipio : '',
            nomeEmpresarial: data.cnpj ? data.cnpj.nomeEmpresarial : data.existingCompany ? data.existingCompany.nomefantasia : '',
            regimeEspecialSelecionado: data.existingCompany ?
                data.existingCompany.regime_especial_tributacao : '',
        });
        await props.saveTelephone(data.cnpj ? data.cnpj.telefone : data.existingCompany ? data.existingCompany.telefone : '');
        await props.saveEmail(data.cnpj ? data.cnpj.enderecoEletronico : data.existingCompany ? data.existingCompany.email : '');
        await props.saveAddress({
            ...props.address,
            ...address
        });
        setStatus('formingCompany');
        
    };

    const fetchCredentialType = async (uf, municipio, credentials) => {
        await Axios.get(props.getApiUrl(`notasinternas/prefeitura/detalhes?uf=${uf}&cidade=${municipio}`),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateToken()
                }
            })
            .then(async (response) => {
                const enotasResponse = response.data.status === 400 ?
                    JSON.parse(response.data.resposta)[0] : response.data.resposta;
                if (enotasResponse && enotasResponse.codigo === 'PNFe0005') {
                    props.saveCredentials({
                        ...props.credentials,
                        authType: 0,
                        type: 0
                    });
                    setHomologatedCity(false);
                } else {
                    setHomologatedCity(true);
                    await props.saveCredentials({
                        ...props.credentials,
                        user: response.data.resposta.tipoAutenticacao === 4 ? '' : credentials.user,
                        secretPhrase: response.data.resposta.tipoAutenticacao === 4 ? credentials.user : '',
                        token: credentials.token,
                        password: credentials.password,
                        type: response.data.resposta.tipoAutenticacao,
                        authType: response.data.resposta.assinaturaDigital,
                    });
                    props.companyData.regimesEspecialTributacao = response.data.resposta.regimesEspecialTributacao;
                    await props.saveCompanyData(props.companyData);
                    await setAuthType(response.data.resposta.assinaturaDigital);
                    await setUsaRegimeEspecial(response.data.resposta.usaRegimeEspecialTributacao);
                    setUseAEDF(response.data.resposta.usaAEDF);
                    fetchSMU(response.data.IBGE);
                }
            })
            .catch((err) => {
                errorHandler(err, displayMessage);
            });
    };

    const fetchSMU = (ibge) => {
        Axios.get(props.getApiUrl(`notasinternas/servico/smu?codigoIbge=${ibge}`),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateToken()
                }
            })
            .then((response) => {
            })
            .catch((err) => {
                errorHandler(err, displayMessage);
            });
    };

    // HANDLE SUBMIT

    const submitCompany = async () => {
        setCodDemanda('');
        setProcessingTest(false);
        setTestResult(null);
        setTransactionId('');
        setLoadingMessage('');
        setStatus('waitingCompany');
        setLoadingMessage('Salvando empresa, aguarde...');
        if ((props.credentials.type === 1 || props.credentials.authType === 2) && props.credentials.arquivoBase64 !== null) {
            await submitFile();
        }
        await handleSubmitCompany();
    };

    const handleSubmitCompany = async (fixedLc = null, fixedSm = null) => {
        await createDemanda()
            .then(
                (response) => {
                    const idDemanda = response.data.demanda;
                    const bodyFormData = new FormData();
                    bodyFormData.append('id_demanda', idDemanda);
                    bodyFormData.append('cnpj', props.selectedCompany);
                    bodyFormData.append('email', props.email && props.email !== '' ? props.email : ownerData.email);
                    bodyFormData.append('telefone', props.telephone);
                    bodyFormData.append('codmei', props.companyData.codmei);
                    bodyFormData.append('codusuario', (ownerData.change && ownerData.change.code) ? ownerData.change.code : ownerData.code);
                    bodyFormData.append('inscricaoEstadual', props.companyData.inscricaoEstadual ?
                        props.companyData.inscricaoEstadual : ''
                    );
                    bodyFormData.append('inscricaoMunicipal', props.companyData.inscricaoMunicipal ?
                        props.companyData.inscricaoMunicipal.trim() : ''
                    );
                    bodyFormData.append('usuario', props.credentials.type === 4 ? props.credentials.secretPhrase : props.credentials.user,);
                    bodyFormData.append('senha', props.credentials.password);
                    bodyFormData.append('token', props.credentials.token);
                    bodyFormData.append('fraseSecreta', props.credentials.secretPhrase);
                    bodyFormData.append('tokenacesso', props.credentials.type === 3 ? props.credentials.token :
                        (props.address.uf === 'SP' && props.address.city === 'SAO PAULO') ?
                            props.credentials.password : null);
                    bodyFormData.append('certificado', props.credentials.arquivoBase64 ? props.credentials.arquivoBase64 : '');
                    bodyFormData.append('nome_certificado', props.credentials.arquivoNome);
                    bodyFormData.append('senha_certificado', props.credentials.filePassword);
                    bodyFormData.append('talao_manual', null);
                    bodyFormData.append('emissao_habilitada', null);
                    bodyFormData.append('AEDF', useAEDF ? props.companyData.AEDF : null);
                    bodyFormData.append('codigo_servico_municipio', fixedSm ? fixedSm : props.companyData.codigoServicoMunicipal);
                    bodyFormData.append('nome_fantasia', props.companyData.nomeFantasia);
                    bodyFormData.append('nome_empresarial', props.companyData.nomeEmpresarial);
                    bodyFormData.append('uf', props.address.uf);
                    bodyFormData.append('cidade', props.address.municipio);
                    bodyFormData.append('cep', props.address.cep);
                    bodyFormData.append('bairro', props.address.bairroDistrito);
                    bodyFormData.append('endereco', props.address.logradouro);
                    bodyFormData.append('complemento', props.address.complemento);
                    bodyFormData.append('numero', props.address.numero);
                    bodyFormData.append('numero_rps', props.companyData.numeroRPS !== '' ? props.companyData.numeroRPS : '');
                    bodyFormData.append('serie', props.companyData.serie !== '' ? props.companyData.serie : '');
                    bodyFormData.append('numero_lote', props.companyData.numeroLote !== '' ? props.companyData.numeroLote : '');
                    if (props.companyData.LC116 !== '') {
                        bodyFormData.append('item_lista_servicolc116', fixedLc ? fixedLc : props.companyData.LC116);
                    }
                    if (props.companyData.regimeEspecialSelecionado !== '') {
                        bodyFormData.append('regime_especial_tributacao', props.companyData.regimeEspecialSelecionado);
                    }
                    registerCompany(bodyFormData).then(
                        response => {
                            setOwnerData({
                                cpf: '',
                                name: '',
                                code: '',
                                email: '',
                                change: {
                                    cpf: '',
                                    email: '',
                                }
                            });
                            setCodDemanda(response.data.demanda);
                            props.saveCompanyRegistered(props.selectedCompany);
                        }
                    ).catch(
                        err => {
                            setOwnerData({
                                change: {
                                    cpf: '',
                                    email: '',
                                }
                            });
                            errorHandler(err, displayMessage);
                        }
                    );
                }
            ).catch(
                error => {
                    errorHandler(error, displayMessage);
                });
    };

    const submitFile = async () => {
        const cnpj = props.selectedCompany;
        let bodyFormData = new FormData();
        bodyFormData.append('senha_certificado', props.credentials.filePassword);
        bodyFormData.append('arquivo', props.credentials.arquivoBase64);
        Axios.post(props.getApiUrl(`notasinternas/empresa/vincular/certificado?cnpj=${cnpj}`),
            bodyFormData,
            {
                headers: {
                    Authorization: validateToken(),
                    'Content-Type': 'multipart/form-data'
                }
            }).then().catch(error => {
            errorHandler(error, displayMessage);
        });
    };

    // FLUXO PARA ATUALIZIAR A EMPRESA

    const createDemanda = () => {
        const cnpj = props.selectedCompany;
        return Axios.post(props.getApiUrl('backoffice/demanda'),
            {
                cliente: props.companySelected.origem,
                servico: 2, // Serviço 2 é a demanda de cadastro de empresa
                mensagem: 'Empresa cadastrada manualmente pelo Backoffice',
                id_externo: null,
                empresa: {
                    CNPJ: cnpj
                }
            }
            ,{
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateClientToken()
                }
            });
    };

    const registerCompany = (data) => {
        return Axios.post(props.getApiUrl('backoffice/mei'), data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateClientToken()
                }
            });
    };

    const registerUser = () => {
        return Axios.post(props.getApiUrl('cadastrar'), {
            nome: props.companyData.nomeEmpresarial,
            email: props.email,
            cnpj: props.cnpj,
            origem: props.companySelected.origem
        }, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateToken()
            }
        });
    };

    const verifyCompany = async () => {
        if (!homologatedCity) {
            setStatus('done');
            await Axios.put(props.getApiUrl('backoffice/demanda'), {
                status: 2,
                demanda: props.selectedCompanyDemanda,
                mensagem: 'Empresa atualizada pelo backoffice',
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateClientToken()
                }
            });
            displayMessage('Empresa atualizada com sucesso, mas a cidade não é homologada pelo eNotas. ' +
                'Não será possivel emitir a nota de teste', 'alert');
            setEditing(false);
            startCreationFlux();
        } else {
            await Axios.get(props.getApiUrl(`backoffice/demanda/empresa/dadosobrigatorios/${props.selectedCompany}`), {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateClientToken()
                }
            }).then(
                async response => {
                    setEditing(false);
                    if (response.data.dadosObrigatoriosPreenchidos) {
                        await Axios.put(props.getApiUrl('backoffice/demanda'), {
                            status: 2,
                            demanda: codDemanda,
                            mensagem: 'cadastro da empresa concluido pelo backoffice',
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                Accept: 'application/json',
                                Authorization: validateClientToken()
                            }
                        }).then();
                        setStatus('done');
                        displayMessage('Empresa atualizada com sucesso!');
                    } else {
                        await Axios.put(props.getApiUrl('backoffice/demanda'), {
                            status: 1,
                            demanda: codDemanda,
                            mensagem: 'Empresa atualizada pelo backoffice',
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                Accept: 'application/json',
                                Authorization: validateClientToken()
                            }
                        }).then();
                        errorHandler(response.data, displayMessage,'alert');
                        setStatus('done');
                    }
                    startCreationFlux();
                }
            ).catch(
                err => {
                    errorHandler(err, displayMessage);
                }
            );
        }
    };

    // HANDLE LC116 FETCH FROM CNAE
    const fetchLC116 =  async (cnae) => {
        setLc116Default('Carregando...');
        try {
            const response = await Axios.get(props.getApiUrl(`lc116/listar/${cnae}`), {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateToken()
                }
            });

            setLc116Default('Selecione a atividade');
            return response.data.lc116 ? response.data.lc116 : [];
        } catch(err) {
            errorHandler(err, displayMessage);
        };
    };

    // HANDLE INPUTS CHANGE

    const handleFormInputChange = async (e) => {
        const {name, value} = e.target;
        var lc116List = props.companyData && props.companyData.LC116List ? props.companyData.LC116List : null;
        
        if (name === 'selectedCNAE') {
            if (value !== '') {
                lc116List = await fetchLC116(value);
            } else {
                lc116List = [];
            }
        }
        props.saveCompanyData({
            ...props.companyData,
            LC116List: lc116List,
            [name]: value
        });
    };

    const handleEmailChange = (value) => {
        props.saveEmail(value);
    };

    const handlePhoneChange = (value) => {
        props.saveTelephone(value);
    };

    const handleAddressChange = (event) => {
        const { name, value } = event.target;
        props.saveAddress({
            ...props.address,
            [name]: value,
        });
    };

    const handleEditorStateChange = (state) => {
        const stateHtml = stateToHTML(state.getCurrentContent());
        setEditorState(state);
        setCityHelpText(stateHtml);
    };

    const handleCityHelperSave = () => {
        if (currentCompanyCityId && cityHelpText !== '') {
            sendCityHelper(currentCompanyCityId, cityHelpText)
                .then(() => {
                    setCityHelperResult(true);
                })
                .catch(() => {
                    setCityHelperResult(false);
                });
            setTimeout(() => setCityHelperResult(null), 10000);
        }
    };

    const handleCleanFetch = () => {
        startCreationFlux(true);
    };

    const renderTestButton = () => {
        if(!editing && homologatedCity) {
            if(!mei) {
                return (
                    <div className="company_not_mei">Essa empresa é ME. Para empresas ME, não é necessário<br/> emissão de nota teste. As notas serão emitidas manualmente.</div>
                );
            }
            else {
                return (
                    <button
                        className='c_button'
                        onClick={(e) => {
                            e.preventDefault();
                            handleTestReceiptSend(e);
                        }}
                        disabled={(processingTest)}
                    >
                        Emitir nota de teste
                    </button>
                );
            }
        } 
        return '';
    };

    return (
        <section className='company-detail'>
            {
                (feedBackMessage.length > 0) && (
                    feedBackMessage.map((feedback, key) => (
                        <UserFeedBack
                            key={key}
                            band={true}
                            message={feedback.message}
                            alert={feedback.type === 'alert'}
                            error={feedback.type === 'error'}
                        />
                    ))
                )
            }
            {
                requiredFields.map((item, key) =>
                    (<UserFeedBack
                        key={key}
                        band={true}
                        alert={true}
                        message={`- ${item.mensagem}`}
                    />)
                )
            }
            <Prompt
                when={processingTest}
                message={'Emissão de nota teste em andamento, deixar esta página poderá causar problemas.'}
            />
            { props.companyMensagem && <div className='dicasMEI-message'> { props.companyMensagem } </div> }


            <header className='company-detail__header'>
                <button className='company-detail__close' name='closeRegisterOpen' type='button' onClick={
                    (e) => handleBackButton(e)}>
                    <div className='company-detail__close-icon'>
                        <img src={iconArrowLeft} alt='Icone voltar' />
                    </div>
                    <span>Voltar</span>
                </button>
                <h1 className='page-title page-title_company'>Dados da Empresa</h1>
            </header>

            {((status === 'working') || (status === 'waitingCompany')) && (
                <span className={'company-submitting'}>
                    {notFound ? (
                        <>
                            <GrClose
                                size={60}
                                color="#f90000"
                                style={{marginBottom: '1em', alignSelf: 'center',}}
                            />
                            <h3>Ocorreu um erro ao buscar os dados dessa empresa</h3>
                        </>
                    ) : (
                        <>
                            <LoaderSpinner
                                width={60}
                                height={60}
                                type="TailSpin"
                                color="#002240"
                                style={{marginBottom: '1em', alignSelf: 'center',}}
                            />
                            <h3>{loadingMessage !== '' ? loadingMessage : 'Obtendo dados da empresa...'}</h3>
                        </>
                    )}
                </span>
            )}

            {
                ((status !== 'working') && (status !== 'waitingCompany')) &&
                <ValidatorForm onSubmit={submitCompany} instantValidate={true}>

                    <div className={'button_alignment'} style={{justifyContent: 'flex-start !important'}}>
                        <button className='c_button'
                            style={{
                                height: '50px',
                                width: '200px',
                                fontSize: '16px',
                                fontWeight: '400',
                                backgroundColor: '#002042'
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                handleCleanFetch();
                            }}
                        >
                            Atualizar dados deste CNPJ
                        </button>
                        
                        <div style={{textAlign: 'left', marginLeft: '20px', height: '40px', alignSelf: 'center'}}>
                            <label className="label label_name">
                                <p className='label__p'>Atualizado em:</p>
                                {props.companyData.existingCompany && props.companyData.existingCompany.data_atualizacao}
                            </label>
                        </div>
                    </div>

                    <CompanyOwner
                        editing={editing}
                        ownerData={ownerData}
                        setOwnerData={setOwnerData}
                        submitCompany={submitCompany}
                        validateClientToken={validateClientToken}
                        getApiUrl={props.getApiUrl}
                    />

                    <div className='invoice-detail__section'>
                        <div
                            className='invoice-detail__section-header'
                        >
                            <h2 className='invoice-detail__section-title'>Dados da MEI</h2>
                        </div>
                        <div className='company-detail__section'>
                            <div className='company-detail__section-content'>
                                <div className='company-register__row'>
                                    <label className='label label_cnpj'>
                                        <TextValidator
                                            className='input'
                                            name='companyCNPJ'
                                            id='cnpjValueInput'
                                            type='text'
                                            value={props.selectedCompany}
                                            maxLength='18'
                                            autoComplete='off'
                                            readOnly
                                        />
                                        <p className='label__p label__p_float'>CNPJ</p>
                                    </label>
                                    <label className='label label_status'>
                                        <input
                                            className='input'
                                            value={props.companyData.situacaoCadastral}
                                            type='text'
                                            readOnly
                                        />
                                        <p className='label__p label__p_float'>Status</p>
                                    </label>
                                    <label className='label label_status'>
                                        <input
                                            className='input'
                                            value={ mei ? 'Sim' : 'Não' }
                                            type='text'
                                            readOnly
                                        />
                                        <p className='label__p label__p_float'>É MEI?</p>
                                    </label>
                                </div>
                                <div className='company-register__row'>
                                    <label className='label label_name'>
                                        <TextValidator
                                            className='input'
                                            name='nomeEmpresarial'
                                            type='text'
                                            value={props.companyData.nomeEmpresarial}
                                            onChange={handleFormInputChange}
                                            readOnly
                                        />
                                        <p className='label__p label__p_float'>Razão Social</p>
                                    </label>
                                    <label className='label label_name'>
                                        <TextValidator
                                            className='input'
                                            name='nomeFantasia'
                                            type='text'
                                            value={props.companyData.nomeFantasia}
                                            onChange={handleFormInputChange}
                                            readOnly
                                        />
                                        <p className='label__p label__p_float'>Nome Fantasia</p>
                                    </label>
                                </div>
                                <div className='company-register__row'>
                                    <label className='label label-spacer'>
                                        <TextValidator
                                            className='input'
                                            name='inscricaoMunicipal'
                                            type='text'
                                            value={props.companyData.inscricaoMunicipal}
                                            onChange={handleFormInputChange}
                                            readOnly={!editing}
                                        />
                                        <p className='label__p label__p_float'>
                                            Inscrição Municipal
                                        </p>
                                    </label>
                                    <label className='label label'>
                                        <TextValidator
                                            className='input'
                                            name='inscricaoEstadual'
                                            type='text'
                                            value={props.companyData.inscricaoEstadual}
                                            onChange={handleFormInputChange}
                                            readOnly={!editing}
                                        />
                                        <p className='label__p label__p_float'>
                                            Inscrição Estadual
                                        </p>
                                    </label>
                                </div>
                                <div className='company-register__row'>
                                    <label className='label label_numero-rps'>
                                        <TextValidator
                                            className='input'
                                            name='numeroRPS'
                                            type='text'
                                            readOnly={!editing}
                                            value={props.companyData.numeroRPS}
                                            onChange={handleFormInputChange}
                                        />
                                        <p className='label__p label__p_float'>Número do RPS</p>
                                    </label>
                                    <label className='label label_numero-lote'>
                                        <TextValidator
                                            className='input'
                                            name='numeroLote'
                                            type='text'
                                            readOnly={!editing}
                                            value={props.companyData.numeroLote}
                                            onChange={handleFormInputChange}
                                        />
                                        <p className='label__p label__p_float'>Número do lote</p>
                                    </label>
                                    <label className='label label_serie'>
                                        <TextValidator
                                            className='input'
                                            name='serie'
                                            type='text'
                                            readOnly={!editing}
                                            value={props.companyData.serie}
                                            onChange={handleFormInputChange}
                                        />
                                        <p className='label__p label__p_float'>Série</p>
                                    </label>
                                </div>
                                {   (usaRegimeEspecial) &&
                                (<div className='company-register__row'>
                                    <label className='label label_regime-especial'>
                                        <select
                                            className='input'
                                            name='regimeEspecialSelecionado'
                                            disabled={!editing}
                                            value={props.companyData.regimeEspecialSelecionado}
                                            onChange={handleFormInputChange}
                                        >
                                            <option value="">
                                                Selecione um Regime
                                            </option>
                                            {
                                                props.companyData.regimesEspecialTributacao.map(
                                                    (item, index) => (
                                                        <option value={item.codigo} key={index} data-cod={item.codigo}>
                                                            { item.nome }
                                                        </option>
                                                    ))}
                                        </select>
                                        <p className='label__p label__p_float'>
                                            Regime Especial de Tributação
                                        </p>
                                    </label>
                                </div>)}
  
                                <div className='company-register__row'>
                                    <label className='label label_codigo-servico'>
                                        <input
                                            className='input'
                                            name='codigoServicoMunicipal'
                                            type='text'
                                            value={props.companyData.codigoServicoMunicipal}
                                            onChange={handleFormInputChange}
                                            readOnly={!editing}
                                        />
                                        <p className='label__p label__p_float'>
                                            Código do Serviço Municipal
                                        </p>
                                    </label>
                                    {(useAEDF) && (
                                        <label className='label label_AEDF'>
                                            <input
                                                className='input'
                                                name='AEDF'
                                                type='text'
                                                value={props.companyData.AEDF}
                                                onChange={handleFormInputChange}
                                                readOnly={!editing}
                                            />
                                            <p className='label__p label__p_float'>
                                                AEDF
                                            </p>
                                        </label>
                                    )}
                                </div>
                                <div className='company-register__row'>
                                    <label className='label label_codigo-servico label-spacer'>
                                        <input
                                            className='input'
                                            name='uf'
                                            type='text'
                                            value={props.address.uf}
                                            onChange={handleAddressChange}
                                            readOnly={!editing}
                                        />
                                        <p className='label__p label__p_float'>Estado (UF)</p>
                                    </label>
                                    <label className='label label_codigo-servico'>
                                        <input
                                            className='input'
                                            name='city'
                                            type='text'
                                            value={props.address.municipio}
                                            onChange={handleAddressChange}
                                            readOnly={!editing}
                                        />
                                        <p className='label__p label__p_float'>Cidade</p>
                                    </label>
                                </div>
                                <div className='company-register__row'>
                                    <label className='label label_codigo-servico label-spacer'>
                                        <input
                                            className='input'
                                            name='cep'
                                            type='text'
                                            value={props.address.cep}
                                            onChange={handleAddressChange}
                                            readOnly={!editing}
                                        />
                                        <p className='label__p label__p_float'>CEP</p>
                                    </label>
                                    <label className='label label_codigo-servico'>
                                        <input
                                            className='input'
                                            name='logradouro'
                                            type='text'
                                            value={props.address.logradouro}
                                            onChange={handleAddressChange}
                                            readOnly={!editing}
                                        />
                                        <p className='label__p label__p_float'>Logradouro</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='company-detail__section credential_section' >
                        <Credentials
                            authType={authType}
                            isEditing={editing}
                            isHomologated={homologatedCity}
                            fileName={
                                (currentCompanyData && currentCompanyData.existingCompany)  ?
                                    currentCompanyData.existingCompany.nome_certificado : ''
                            }
                            filePassword={
                                (currentCompanyData && currentCompanyData.existingCompany)  ?
                                    currentCompanyData.existingCompany.senha_certificado : ''
                            }
                            fileExpiration={
                                (currentCompanyData && currentCompanyData.existingCompany)  ?
                                    currentCompanyData.existingCompany.vencimento_certificado  : ''
                            }
                        />
                    </div>

                    <div className={'buttons'}>

                        <button className='c_button_edit'
                            onClick={(e) => {
                                e.preventDefault();
                                setEditing(!editing);
                            }}
                            disabled={(processingTest)}
                        >
                            {editing ? 'Cancelar' : 'Editar Dados'}
                        </button>

                        { (editing && status !== 'working' && status !== 'workingCompany') &&
                        <button className='c_button_save' type='submit'>
                            Salvar alterações
                        </button>
                        }

                        { renderTestButton() }
                    </div>

                    { (!editing && testResult
                    ) &&
                        <div className={'company-nfe__container'}>
                            <div className='company-detail__section'>
                                <div className='company-detail__section-header' data-state='companyTestResultData'>
                                    <h2 className='company-detail__section-title'>Retorno</h2>
                                </div>
                                <div className='company-detail__section-content'>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        {__renderIcon()}
                                        <LoaderSpinner
                                            width={25}
                                            height={25}
                                            type="TailSpin"
                                            color="#002240"
                                            visible={(receiptResponse.cod === 1)}
                                            style={{marginRight: '10px', alignSelf: 'center'}}
                                        />
                                        <h4>{testResult}</h4>
                                    </div>
                                </div>
                            </div>
                            {(nfeJson) && (
                                <div className='company-detail__section'>
                                    <div
                                        className='company-detail__section-header'
                                        data-state='companyTestJsonData'
                                        onClick={() => setShowNfeJson(!showNfeJson)}
                                        style={{flexDirection: 'row'}}
                                    >
                                        <h2 className='company-detail__section-title'>JSON da Nota teste</h2>
                                        {showNfeJson ?
                                            <FontAwesomeIcon
                                                icon={faChevronUp}
                                                style={{
                                                    position: 'Absolute',
                                                    right: '18em'
                                                }}
                                            /> :
                                            <FontAwesomeIcon
                                                icon={faChevronDown}
                                                style={{
                                                    position: 'Absolute',
                                                    right: '18em'
                                                }}
                                            />}
                                    </div>
                                    {(showNfeJson) && (
                                        <div className='company-detail__section-content'>
                                            <h4 style={{textAlign: 'left'}}>ID da Nota: {nfeJson.nota_id}</h4>
                                            <h4 style={{textAlign: 'left'}}>ID da Empresa: {nfeJson.empresa_id}</h4>
                                            <h4 style={{textAlign: 'left'}}>JSON Enviado:</h4>
                                            <ReactJson
                                                style={{textAlign: 'left'}}
                                                name={false}
                                                src={nfeJson.request}
                                                indentWidth={4}
                                                theme={'rjv-default'}
                                                enableClipboard={true}
                                                iconStyle={'triangle'}
                                                displayDataTypes={false}
                                                displayObjectSize={false}
                                                collapsed={1}
                                                collapseStringsAfterLength={false}
                                            />
                                            <h4 style={{textAlign: 'left'}}>JSON Recebido:</h4>
                                            {(typeof nfeJson.response === 'object') ? (
                                                <ReactJson
                                                    style={{textAlign: 'left'}}
                                                    name={false}
                                                    src={nfeJson.response}
                                                    indentWidth={4}
                                                    theme={'rjv-default'}
                                                    enableClipboard={true}
                                                    iconStyle={'triangle'}
                                                    displayDataTypes={false}
                                                    displayObjectSize={false}
                                                    collapsed={1}
                                                    collapseStringsAfterLength={false}
                                                />
                                            ) : (
                                                <h4>{nfeJson.response}</h4>
                                            )}
                                            <button
                                                style={{margin: 'auto'}}
                                                className='c_button_edit'
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    navigator.clipboard.writeText(JSON.stringify(nfeJson))
                                                        .then(() => {
                                                            setCopied(true);
                                                            setTimeout(
                                                                () => setCopied(false), 5000
                                                            );
                                                        })
                                                        .catch(error => errorHandler(error, displayMessage));
                                                }}
                                            >
                                                {copied ? 'JSON Copiado!' : 'Copiar JSON'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    }
                </ValidatorForm>
            }

            {(((status !== 'working') && (status !== 'waitingCompany'))) && (
                <motion.div
                    transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 40
                    }}
                    initial={{right: '3em'}}
                    style={{position: 'fixed'}}
                    animate={{ right: cityHelpShow ? '31em' : '3em' }}
                >
                    <BsQuestionCircle
                        size={'2.5em'}
                        className={'company_city_helper'}
                        onClick={() => {
                            setCityHelpShow(!cityHelpShow);
                        }}
                    />
                </motion.div>
            )}

            <AnimatePresence>
                {(cityHelpShow) &&
                <motion.div
                    exit={{ x: 1000 }}
                    initial={{ x: 1000 }}
                    animate={{ x: 0 }}
                    transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 40
                    }}
                    className={'company_city_help_container'}
                >
                    <h3 className={'company_city_title'}>
                        {`Ajuda sobre ${props.address.municipio} - ${props.address.uf}`}
                    </h3>
                    <Editor
                        toolbar={{
                            options: toolBarOptions,
                            inline: toolBarInline
                        }}
                        editorState={editorState}
                        wrapperClassName="company_city_editor"
                        editorClassName="city_editor_style"
                        onEditorStateChange={handleEditorStateChange}
                    />
                    <button
                        className={'city_helper_button'}
                        onClick={() => handleCityHelperSave()}
                    >
                        Salvar
                    </button>
                    {(cityHelperResult !== null) && (
                        <p
                            className={'city_helper_result'}
                            style={{
                                color: cityHelperResult ? 'green' : 'red'
                            }}
                        >
                            {cityHelperResult ? 'Salvo com sucesso!' : 'Ocorreu um erro ao salvar.'}
                        </p>
                    )}
                </motion.div>
                }
            </AnimatePresence>

        </section>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(Register);
