//warning: Código ilegivel mas necessário (mostra a importancia de patronizar os retornos!).
export default function (error, callback, type = 'error') {
    if (error.response) {
        if (error.response.data) {
            if (error.response.data.mensagem) {
                callback(error.response.data.mensagem, type);
            } else if (error.response.data.error) {
                if (error.response.data.error.message) {
                    callback(error.response.data.error.message, type);
                }  else {
                    callback(error.response.data.error, type);
                }
            } else {
                if (Object.prototype.toString.call(error.response.data) === '[object Array]') {
                    callback(error.response.data[0].mensagem, type);
                } else if (error.response.data.mensagem) {
                    callback(error.response.data.mensagem, type);
                } else {
                    callback(error.response.data, type);
                }
            }
        } else {
            callback(error.response, type);
        }
    } else if (error.criticas) {
        let mensagem = '';
        error.criticas.map(critica => {
            mensagem = mensagem + `${critica.campo}: ${critica.mensagem} <br>`;
        });
        callback(mensagem, type);
    } else if (error.error) {
        if (error.error.message) {
            callback(error.error.message, type);
        } else {
            callback(error.error, type);
        }
    } else {
        callback(error, type);
    }
};