import React from 'react';
import Label from '../Label/Label';
import List from '../List/List';
import './table.css';

const Table = ({
    filteredInvoice,    
    labelIdAscending,
    labelCnpjAscending,
    labelValueAscending,
    labelEntryDateAscending,
    labelFinalDateAscending,
    labelStatusAscending,
    handleLabelValue,
    handleLabelCNPJ,
    handleLabelID,
    handleLabelEntryDate,
    handleLabelFinalDate,
    handleLabelStatus,
    handleShowInvoiceDetail,
}) => (
    <div className='invoice-table'>
        <Label 
            labelIdAscending={labelIdAscending}
            labelCnpjAscending={labelCnpjAscending}
            labelValueAscending={labelValueAscending}
            labelEntryDateAscending={labelEntryDateAscending}
            labelFinalDateAscending={labelFinalDateAscending}
            labelStatusAscending={labelStatusAscending}
            handleLabelCNPJ={handleLabelCNPJ}
            handleLabelID={handleLabelID}
            handleLabelValue={handleLabelValue}
            handleLabelEntryDate={handleLabelEntryDate}
            handleLabelFinalDate={handleLabelFinalDate}
            handleLabelStatus={handleLabelStatus}
        />
        <List 
            filteredInvoice={filteredInvoice} 
            handleShowInvoiceDetail={handleShowInvoiceDetail}
        />
    </div>
);

export default Table;