import React, { Component } from 'react';
import ForgotPassword from './ForgotPassword';

class ForgotPasswordContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: '',
            loading: false,
            errorMessage: '',
            successMessage: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    handleFetchError(response) {
        if (response.code) throw new Error(response.message);
        return response;
    }

    handleError(error) {
        const self = this;

        this.setState({
            loading: false,
            errorMessage: error.message,
        });

        setTimeout(function() { self.setState({ errorMessage: '' }); }, 5000);
    }

    validateDicasMEIToken() {
        const expiresIn = localStorage.getItem('dicasMEIToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('dicasMEIToken');
            localStorage.removeItem('dicasMEIToken_expiration');
            localStorage.setItem('loggedIn', false);
            this.setState({ loggedIn: false });
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('dicasMEIToken');
    };

    async fetchForgotPassword() {
        const response = await (fetch('https://www.dicasmei.com.br/api/recuperarsenha', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.validateDicasMEIToken()
            },
            body: JSON.stringify({
                email: this.state.user
            })
        }));
        const data = await response.json();
        return data;
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });

        this.fetchForgotPassword()
            .then(this.handleFetchError)
            .then(response => {
                const self = this;
                this.setState({ loading: false, successMessage: 'E-mail de recuperação enviado com sucesso!', user: '' });
                setTimeout(function() { self.setState({ successMessage: '' }); }, 5000);
            })
            .catch(error => this.handleError(error));
    }

    render() {
        return (
            <ForgotPassword
                {...this.state}
                handleSubmit={this.handleSubmit}
                handleInputChange={this.handleInputChange}
            />
        );
    }

}

export default ForgotPasswordContainer;
