export const SET_FILTERS = 'company:setFilters';
export const SET_COMPANIES = 'company:setCompanies';
export const SET_PAGINATION = 'company:setPagination';
export const SET_CURRENT_PAGE = 'company:setCurrentPage';
export const RESET_PAGINATION = 'company:resetPagination';
export const SET_PAGINATION_LIST = 'company:setPaginationList';
export const SET_CURRENT_COMPANY = 'company:setCurrentCompany';

export function setPagination(payload) {
    return {
        type: SET_PAGINATION,
        payload: payload
    };
}

export function setCurrentPage(payload) {
    return {
        type: SET_CURRENT_PAGE,
        payload: payload
    };
}

export function setPaginationList(payload) {
    return {
        type: SET_PAGINATION_LIST,
        payload: payload
    };
}

export function resetPagination() {
    return {
        type: RESET_PAGINATION,
        payload: {
            currentPage: 0,
            numberOfPages: 0,
            totalItems: 0,
            pageLimit: 0
        }
    };
}

export function setFilters(payload) {
    return {
        type: SET_FILTERS,
        payload: payload
    };
}

export function setCompanies(payload) {
    return {
        type: SET_COMPANIES,
        payload: payload
    };
}

export function setCurrentCompany(payload) {
    return {
        type: SET_CURRENT_COMPANY,
        payload: payload
    };
}
