import React, {useState} from 'react';
import './Company.css';

import {getAvailablesCompanies} from '../../CompanyHandler';

const Company = ({ handleInputChange }) => {
    const [companyName, setCompanyName] = useState('');

    return (
        <div className='company-select'>
            <select
                className="company-select__select"
                value={companyName}
                name='companySelected'
                onChange={e => {
                    setCompanyName(e.target.value);
                    handleInputChange({
                        target: {
                            type: 'select',
                            name: 'companySelected',
                            value: getAvailablesCompanies(e.target.value)
                        },
                    });
                }}
            >
                {Object.keys(getAvailablesCompanies()).map((company, key) => (
                    <option key={key} value={company}>{company}</option>
                ))}
            </select>
        </div>
    );
};

export default Company;
