import React from 'react';
import iconArrowDown from '../../../Images/icon-arrow-down.svg';
import './label.css';

const Label = ({ 
    handleLabel,
    cnpjAscending,
    codstatusAscending,
    idAscending,
    datainicialAscending,
}) => ( 
    <div className='invoice-label'>        
        <button className='invoice-label__btn' name='idAscending' type='button' onClick={handleLabel}>
            ID
            <div className={idAscending ? 'invoice-label__icon' : 'invoice-label__icon invoice-label__icon_up'}>
                <img src={iconArrowDown} alt='Ícone seta para baixo' />
            </div>
        </button>
        <button className='invoice-label__btn' name='cnpjAscending' type='button' onClick={handleLabel}>
            CNPJ
            <div className={cnpjAscending ? 'invoice-label__icon' : 'invoice-label__icon invoice-label__icon_up'}>
                <img src={iconArrowDown} alt='Ícone seta para baixo' />
            </div>
        </button>
        <button className='invoice-label__btn' name='datainicialAscending' type='button' onClick={handleLabel}>
            Data entrada
            <div className={datainicialAscending ? 'invoice-label__icon' : 'invoice-label__icon invoice-label__icon_up'}>
                <img src={iconArrowDown} alt='Ícone seta para baixo' />
            </div>
        </button>
        <button className='invoice-label__btn' name='codstatusAscending' type='button' onClick={handleLabel}>
            Status
            <div className={codstatusAscending ? 'invoice-label__icon' : 'invoice-label__icon invoice-label__icon_up'}>
                <img src={iconArrowDown} alt='Ícone seta para baixo' />
            </div>
        </button>
    </div>
);

export default Label;