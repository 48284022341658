export const SET_CNPJ = 'newCompany:setCNPJ';
export const SET_EMAIL = 'newCompany:setEmail';
export const SET_ADDRESS = 'newCompany:setAddress';
export const SET_TELEPHONE = 'newCompany:setTelephone';
export const SET_COMPANY_DATA = 'newCompany:setCompanyData';
export const RESET_NEW_COMPANY = 'newCompany:resetNewCompany';
export const SET_COMPANY_REGISTERED = 'newCompany:setCompanyRegistered';

export function resetNewCompany() {
    return {
        type: RESET_NEW_COMPANY,
        payload: {
            cnpj: '',
            address: {
                uf: '',
                cep: '',
                numero: '',
                municipio: '',
                logradouro: '',
                complemento: '',
                bairroDistrito: '',
            },
            email: '',
            telephone: '',
            companyRegistered: '',
            companyData: {
                CNAE: [],
                serie: '',
                LC116: '',
                numeroRPS: '',
                LC116List: [],
                numeroLote: '',
                nomeFantasia: '',
                selectedCNAE: '',
                nomeEmpresarial: '',
                situacaoCadastral: '',
                inscricaoEstadual: '',
                inscricaoMunicipal: '',
                codigoServicoMunicipal: '',
                regimeEspecialSelecionado: '',
                regimesEspecialTributacao: [],
            }
        }
    };
}

export function setCnpj(payload) {
    return {
        type: SET_CNPJ,
        payload: payload
    };
}

export function setEmail(payload) {
    return {
        type: SET_EMAIL,
        payload: payload
    };
}

export function setAddress(payload) {
    return {
        type: SET_ADDRESS,
        payload: payload
    };
}

export function setTelephone(payload) {
    return {
        type: SET_TELEPHONE,
        payload: payload
    };
}

export function setCompanyData(payload) {
    return {
        type: SET_COMPANY_DATA,
        payload: payload
    };
}

export function setCompanyRegistered(payload) {
    return {
        type: SET_COMPANY_REGISTERED,
        payload: payload
    };
}
