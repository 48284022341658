import React from 'react';
import Error from './Error/Error';
import Logo from './Logo/Logo';
import Form from './Form/Form';
import './Login.css';

const Login = ({ 
    loading,    
    handleSubmit,
    handleInputChange,
    errorMessage
}) => (
    <main>    
        { errorMessage && <Error message={errorMessage} /> }
        <section className='login'>
            <Logo />
            <Form 
                loading={loading}
                handleSubmit={handleSubmit} 
                handleInputChange={handleInputChange}
            />        
        </section>
    </main>
);

export default Login;
