import React from 'react';
import List from '../List/List';
import Label from '../Label/Label';
import { connect } from 'react-redux';

const select = state => ({
    companies: state.company.companies
});

const Table = ({
    handleLabel,
    handleShowCompanyDetail,
    filteredPageItems,
    cnpjAscending,
    codstatusAscending,
    idAscending,
    datainicialAscending,
    companies
}) => {
    return (<div className='invoice-table'>
        <Label
            handleLabel={handleLabel}
            cnpjAscending={cnpjAscending}
            codstatusAscending={codstatusAscending}
            idAscending={idAscending}
            datainicialAscending={datainicialAscending}
        />
        <List
            companiesList={companies}
            handleShowCompanyDetail={handleShowCompanyDetail}
        />
    </div>);
};

export default connect(select)(Table);
