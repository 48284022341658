export const SAVE_LOGIN_EMAIL = 'login:saveEmail';
export const SAVE_LOGIN_PASSWORD = 'login:savePassword';

export function saveEmail(payload) {
    return {
        type: SAVE_LOGIN_EMAIL,
        payload: payload
    };
}

export function savePassword(payload) {
    return {
        type: SAVE_LOGIN_PASSWORD,
        payload: payload
    };
}
