import React from 'react';
import Modal from '../../Modal/Modal';
import './detail-close.css';

const DetailClose = ({
    handleDetailCloseButton,
    handleCloseWithoutSave,
    handleCloseAndSave,
}) => (
    <Modal small handleClose={handleDetailCloseButton}>
        <div className='invoice-detail-close'>
            <h2 className='invoice-detail-close__title'>Confirmação</h2>     
            <div className='invoice-detail-close__confirm'>
                <p className='invoice-detail-close__p'>
                    Deseja realmente fechar sem salvar as alterações feitas?
                </p>
                <div className='invoice-detail-close__action'>
                    <button className='btn btn_ghost' type='button' onClick={handleCloseWithoutSave}>
                        Fechar sem salvar
                    </button>
                    <button className='btn' type='button' onClick={handleCloseAndSave}>
                        Salvar alterações
                    </button>
                </div>
            </div>   
        </div>
    </Modal>
);

export default DetailClose;