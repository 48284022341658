import React from 'react';
import Modal from '../../../Modal/Modal';
import './ConfirmTalaoManual.css';

const ConfirmTalaoManual = ({
    handleModalClose,
    handleModalConfirm}) => (
    <Modal small handleClose={handleModalClose}>
        <div className='company-detail-close'>
            <h2 className='company-detail-close__title'>Confirmação</h2>
            <div className='company-detail-close__confirm'>
                <p className='company-detail-close__p'>
                    Deseja realmente marcar esta nota como sem talao manual?
                </p>
                <div className='company-detail-close__action'>
                    <button className='btn btn_ghost' type='button' onClick={handleModalClose}>
                        Cancelar
                    </button>
                    <button className='btn' type='button' onClick={handleModalConfirm}>
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    </Modal>
);

export default ConfirmTalaoManual;
