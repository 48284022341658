import {
    SAVE_LOGIN_EMAIL,
    SAVE_LOGIN_PASSWORD
} from '../actions/login-actions';

export default (state  = '', { type, payload }) => {
    switch (type) {
    case SAVE_LOGIN_EMAIL:
        return {
            ...state,
            email: payload
        };
    case SAVE_LOGIN_PASSWORD:
        return {
            ...state,
            password: payload
        };
    default:
        return state;
    }
};
