import React from 'react';
import './loader.css';
import iconLoaderRegister from '../../Images/loader-register.svg';

const Loader = ({ message }) => (
    <div className='loader'>
        <div className='loader__img'>
            <img src={iconLoaderRegister} alt='Loader' />
        </div>
        { message && <p className='paragraph'>{ message  }</p> }
    </div>     
);

export default Loader;