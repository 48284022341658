import React from 'react';
import Logo from '../Login/Logo/Logo';
import Form from './Form/Form';
import UserFeedback from '../UserFeedback/UserFeedback';

const ForgotPassword = ({ 
    loading,
    handleSubmit,
    handleInputChange,
    user,
    errorMessage,
    successMessage
}) => (
    <main>    
        { errorMessage && <UserFeedback message={errorMessage} band={true} error={true} /> }
        { successMessage && <UserFeedback message={successMessage} band={true} success={true} /> }

        <section className='login'>
            <Logo />
            <Form 
                user={user}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                loading={loading}
            />            
        </section>
    </main>
);

export default ForgotPassword;
