import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import {
    map,
    find
} from 'lodash';
import ApexChart from 'react-apexcharts';

import './styles.css';
import LoaderSpinner from 'react-loader-spinner';

const Chart = (props) => {
    const {
        getApiUrl,
        companySelected
    } = props;

    const [nfeData, setNfeData] = useState(null);
    const [currentCompanyData, setCurrentCompanyData] = useState(null);
    const [chartSeries, setChartSeries] = useState([]);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [],
        },
        yaxis: {
            title: {
                text: 'Nº de notas'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' notas';
                }
            }
        },
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setCurrentCompanyData(find(nfeData, {nome: companySelected.origem}));
    }, [companySelected]);

    useEffect(() => {
        if (currentCompanyData) {
            mountChartData(currentCompanyData);
        }
    }, [currentCompanyData]);

    const validateDicasMEIToken = () => {
        const expiresIn = localStorage.getItem('clientToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('clientToken');
            localStorage.removeItem('clientToken_expiration');
            localStorage.setItem('loggedIn', false);
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('clientToken');
    };

    const fetchData = () => {
        Axios.get(`${getApiUrl('backoffice/nota/relatorio')}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': validateDicasMEIToken()
            }
        }).then(response => {
            setNfeData(response.data);
            setCurrentCompanyData(find(response.data, {nome: companySelected.origem}));
        }).catch(error => {
            console.log(error);
        });
    };

    const mountChartData = (companyData) => {
        const hours = Object.keys(companyData.relatorio);
        const customData = {
            notas_emitidas_automaticamente: [],
            notas_emitidas_manualmente: [],
            notas_geraram_demanda_manual: [],
            notas_recebidas: [],
        };
        map(companyData.relatorio, item => {
            customData.notas_emitidas_automaticamente.push(item.notas_emitidas_automaticamente);
            customData.notas_emitidas_manualmente.push(item.notas_emitidas_manualmente);
            customData.notas_geraram_demanda_manual.push(item.notas_geraram_demanda_manual);
            customData.notas_recebidas.push(item.notas_recebidas);
        });
        const series = [];
        Object.entries(customData).map(item => {
            const splitedName = item[0].split('_');
            let fixedName = '';
            splitedName.map(word => {
                fixedName = fixedName + `${word[0].toUpperCase()}${word.slice(1).toLowerCase()} `;
            });
            series.push({
                name: fixedName,
                data: item[1]
            });
        });
        setChartOptions({
            ...chartOptions,
            xaxis: {
                categories: hours,
            }
        });
        setChartSeries(series);
    };

    return(
        <div className={'main chart_container'}>
            <h2 className={'chart_title'}>
                {'Relatório do Dia - '}
                {companySelected.origem[0].toUpperCase()}{companySelected.origem.slice(1).toLowerCase()}
            </h2>
            <div className={'chart_container'}>
                {(currentCompanyData) ? (
                    <ApexChart
                        type="bar"
                        width="100%"
                        height="380"
                        series={chartSeries}
                        options={chartOptions}
                    />
                ) : (
                    <LoaderSpinner
                        width={60}
                        height={60}
                        type="Bars"
                        color="#002240"
                        style={{marginTop: '10%', textAlign: 'center'}}
                    />
                )}
            </div>
        </div>
    );
};

export default Chart;
