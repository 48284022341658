import React from 'react';
import parser from 'html-react-parser';
import './user-feedback.css';
import { AnimatePresence, motion } from 'framer-motion';

const UserFeedback = ({ message, band, error, alert }) => (
    <AnimatePresence>
        <motion.div
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            className={
                band
                    ? error
                        ? 'user-feedback user-feedback_band user-feedback_band-error'
                        : alert ? 'user-feedback user-feedback_band user-feedback_band-alert'
                            : 'user-feedback user-feedback_band user-feedback_band-success'
                    : 'user-feedback'
            }>
            <p className='user-feedback__p'>{parser(message)}</p>
        </motion.div>
    </AnimatePresence>
);

export default UserFeedback;
