import React, { Component } from 'react';
import Login from './Login';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setCompanies, setCurrentCompany, setPagination, setPaginationList} from '../../actions/company-actions';
import {saveEmail, savePassword} from '../../actions/login-actions';

const mapStateToProps = state => ({
    email: state.login.email,
    password: state.login.password
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveEmail: saveEmail,
        savePassword: savePassword
    }, dispatch);
};

class LoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            password: '',
            loading: false,
            errorMessage: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.fetchLogin = this.fetchLogin.bind(this);
    }

    async fetchLogin() {
        const response = await fetch(
            'https://www.dicasmei.com.br/api/backoffice/login_check',
            // 'https://dev.dicasmei.com.br/api/backoffice/login_check',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: this.state.user,
                    password: this.state.password
                })
            }
        );
        const data = await response.json();
        this.props.saveEmail(this.state.user);
        this.props.savePassword(this.state.password);
        return data;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            errorMessage: '',
            loading: false
        });
    }

    handleLoginError(error) {
        localStorage.setItem('loggedIn', false);
        this.setState({
            loading: false,
            errorMessage:
        error.message === 'Bad credentials'
            ? 'Usuário/senha inválidos!'
            : error.message
        });
    }

    handleFetchError(response) {
        if (response.code) throw new Error(response.message);
        return response;
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });

        this.fetchLogin()
            .then(this.handleFetchError)
            .then(response => {
                const clientToken = `Bearer ${response.token}`;
                this.props.handleLogin(clientToken);
            })
            .catch(error => this.handleLoginError(error));
    }

    render() {
        return (
            <Login
                {...this.state}
                handleSubmit={this.handleSubmit}
                handleInputChange={this.handleInputChange}
                handleLogin={this.props.handleLogin}
            />
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(LoginContainer);
