import React from 'react';
import Filter from './Filter/Filter';
import Table from './Table/Table';
import Register from './Register/Register';
import Pagination from '../Pagination/Pagination';
import UserFeedback from '../UserFeedback/UserFeedback';
import { connect } from 'react-redux';
import Loader from '../Loader/Loader';

const Company = ({
    cacheFree,
    setCompanyTomador,
    companyListLoading,
    companyListFiltered,
    handleLabel,
    cnpjAscending,
    nomeAscending,
    emailAscending,
    codstatusAscending,
    registerOpen,
    handlePageChange,
    filteredPageItems,
    itemPerPage,
    userFeedbackMessage,
    userFeedbackRegisterErrorMessage,
    userFeedbackRegisterSuccessMessage,
    handleShowCompanyDetail,
    handleCloseRegister,
    idAscending,
    datainicialAscending,
    fetchTestReceipt,
    companySelected,
    setMeiSituation,
    verifyReceiptTest,
    cancelReceipt,
    companies,
    getPaginationNumbers,
    page,
    setPage,
    getApiUrl,
    getNfeJson,
    handleCleanCnpjConsult
}) => (
    <main className="main">
        {userFeedbackRegisterErrorMessage && (
            <UserFeedback
                message={userFeedbackRegisterErrorMessage}
                band={true}
                error={true}
            />
        )}
        {userFeedbackRegisterSuccessMessage && (
            <UserFeedback
                message={userFeedbackRegisterSuccessMessage}
                band={true}
                success={true}
            />
        )}

        {registerOpen || (
            <section className="company-list">
                <h1 className="page-title">Empresas</h1>
                <Filter
                    handlePageChange={handlePageChange}
                />
                {!companyListLoading && <Table
                    filteredPageItems={filteredPageItems}
                    handleLabel={handleLabel}
                    cnpjAscending={cnpjAscending}
                    nomeAscending={nomeAscending}
                    emailAscending={emailAscending}
                    codstatusAscending={codstatusAscending}
                    handleShowCompanyDetail={handleShowCompanyDetail}
                    idAscending={idAscending}
                    datainicialAscending={datainicialAscending}
                />}
                {userFeedbackMessage && <UserFeedback message={userFeedbackMessage} />}
                {companyListLoading && <Loader message="Carregando empresas..." />}
                {!companyListLoading && companies.length > 0 &&
                (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        itemPerPage={itemPerPage}
                        items={companyListFiltered}
                        handlePageChange={handlePageChange}
                        getPaginationNumbers={getPaginationNumbers}
                    />
                )}
            </section>
        )}

        {registerOpen && (
            <Register
                cacheFree={cacheFree}
                getApiUrl={getApiUrl}
                getNfeJson={getNfeJson}
                cancelReceipt={cancelReceipt}
                setMeiSituation={setMeiSituation}
                companySelected={companySelected}
                fetchTestReceipt={fetchTestReceipt}
                verifyReceiptTest={verifyReceiptTest}
                setCompanyTomador={setCompanyTomador}
                handleCloseRegister={handleCloseRegister}
                handleCleanCnpjConsult={handleCleanCnpjConsult}
            />
        )}
    </main>
);

const mapStateToProps = state => ({
    companies: state.company.companies
});

export default connect(mapStateToProps)(Company);
