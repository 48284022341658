import React, { useState, useEffect } from 'react';
import useDebounce from './use-debounce';
import { connect } from 'react-redux';
import './filter.css';
import {bindActionCreators} from 'redux';
import {setFilters} from '../../../actions/company-actions';

const Filter = (props) => {

    const [cnpj, setCnpj] = useState(props.filters.cnpj === '' ? '' : props.filters.cnpj);

    const debouncedCnpj = useDebounce(cnpj.replace(/\D/g, ''), 500);

    useEffect(
        () => {
            if (props.filters.cnpj !== '' && debouncedCnpj === '' || debouncedCnpj) {
                setTimeout(async () => {
                    await props.saveFilters({
                        ...props.filters,
                        cnpj: debouncedCnpj
                    });
                    await props.handlePageChange(0);
                }, 500);
            }
        },[debouncedCnpj]
    );

    const handleItemsChange = async (value) => {
        await props.saveFilters({
            ...props.filters,
            itemPerPage: value
        });
        await props.handlePageChange(0);
    };

    const handleStatusChange = async (value) => {
        await props.saveFilters({
            ...props.filters,
            status: value
        });
        await props.handlePageChange(0);
    };

    return (
        <div className="filter">
            <div className="filter__form filter__form_company">
                <label className="label">
                    <input
                        className="input input_search"
                        value={cnpj}
                        name="search"
                        type="search"
                        placeholder="Digite aqui"
                        onChange={(e) => setCnpj(e.target.value)}
                    />
                    <p className="label__p label__p_float">CNPJ</p>
                </label>
                <label className="label">
                    <select
                        className="input input_status"
                        name="status"
                        value={props.filters.status}
                        onChange={(e) => handleStatusChange(e.target.value)}
                    >
                        <option value="0">Todos</option>
                        <option value="1">Pendente</option>
                        <option value="2">Finalizado</option>
                    </select>
                    <p className="label__p label__p_float">Status</p>
                </label>
                <label className="label label_perPage">
                    <select
                        className="input input_perPage"
                        name="itemPerPage"
                        value={props.filters.itemPerPage}
                        onChange={(e) => handleItemsChange(e.target.value)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                    </select>
                    <p className="label__p label__p_float">Itens por pg</p>
                </label>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    filters: state.company.filters,
    companyRegistered: state.newCompany.companyRegistered
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveFilters: setFilters,
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(Filter);
