import {
    SET_FILTERS,
    SET_INVOICES,
    SET_PAGINATION,
    RESET_PAGINATION,
    SET_CURRENT_PAGE,
    SET_CURRENT_INVOICE,
    SET_PAGINATION_LIST
} from '../actions/invoice-actions';

export default (state  = '', { type, payload }) => {
    switch (type) {
    case SET_FILTERS:
        return {
            ...state,
            filters: payload
        };
    case SET_INVOICES:
        return {
            ...state,
            invoices: payload
        };
    case SET_PAGINATION:
        return {
            ...state,
            pagination: payload
        };
    case RESET_PAGINATION:
        return {
            ...state,
            pagination: payload
        };
    case SET_PAGINATION_LIST:
        return {
            ...state,
            paginationList: payload
        };
    case SET_CURRENT_PAGE:
        return {
            ...state,
            currentPage: payload
        };
    case SET_CURRENT_INVOICE:
        return {
            ...state,
            currentInvoice: payload
        };
    default:
        return state;
    }
};
