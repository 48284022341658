export const SET_FILTERS = 'invoice:setFilters';
export const SET_INVOICES = 'invoice:setInvoices';
export const SET_PAGINATION = 'invoice:setPagination';
export const SET_CURRENT_PAGE = 'invoice:setCurrentPage';
export const RESET_PAGINATION = 'invoice:resetPagination';
export const SET_PAGINATION_LIST = 'invoice:setPaginationList';
export const SET_CURRENT_INVOICE = 'invoice:setCurrentInvoice';

export function setPagination(payload) {
    return {
        type: SET_PAGINATION,
        payload: payload
    };
}

export function setCurrentPage(payload) {
    return {
        type: SET_CURRENT_PAGE,
        payload: payload
    };
}

export function setPaginationList(payload) {
    return {
        type: SET_PAGINATION_LIST,
        payload: payload
    };
}

export function resetPagination() {
    return {
        type: RESET_PAGINATION,
        payload: {
            currentPage: 0,
            numberOfPages: 0,
            totalItems: 0,
            pageLimit: 0
        }
    };
}

export function setFilters(payload) {
    return {
        type: SET_FILTERS,
        payload: payload
    };
}

export function setInvoices(payload) {
    return {
        type: SET_INVOICES,
        payload: payload
    };
}

export function setCurrentInvoice(payload) {
    return {
        type: SET_CURRENT_INVOICE,
        payload: payload
    };
}
