import React from 'react';
import moment from 'moment';
import toArray from 'lodash/toArray';
import iconClip from '../../../Images/icon-clip.svg';
import iconPendent from '../../../Images/icon-pendent.svg';
import iconSuccess from '../../../Images/icon-success.svg';
import './list.css';

const statusName = {
    1: 'Pendente',
    2: 'Finalizado',
};
const statusClass = {
    1: 'invoice-list__item invoice-list__item_pendent',
    2: 'invoice-list__item'
};
const statusButtonClass = {
    1: 'invoice-list__btn invoice-list__btn_pendent',
    2: 'invoice-list__btn'
};
const statusIcon = {
    1: iconPendent,
    2: iconSuccess
};
const List = ({ companiesList, handleShowCompanyDetail }) => {
    const companies = toArray(companiesList);

    return (<ul className='invoice-list'>
        { companies && companies.map((company, index) => {
            if (company.codigostatus !== 9) {
                return <li className={statusClass[company.codigostatus]} data-coddemanda={company.codigodemanda} data-codstatus={company.codigostatus} data-cnpj={company.cnpj} data-mensagem={company.mensagem} key={index}>
                    <span className='invoice-list__icon'>
                        <img src={statusIcon[company.codigostatus]} alt='Ícone seta' />
                    </span>
                    <span className='invoice-list__span'>{ company.codigodemanda }</span>
                    <span className='invoice-list__span'>{ company.cnpj }</span>
                    <span className='invoice-list__span'>{ moment(company.datainicial).format('DD/MM/YYYY') }</span>
                    <span className='invoice-list__span'>{ statusName[company.codigostatus] }</span>
                    <button className={statusButtonClass[company.codigostatus]} type='button' onClick={handleShowCompanyDetail}>
                        <div className='invoice-list__btn-icon'>
                            <img src={iconClip} alt='Ícone Clip' />
                        </div>
                    </button>
                </li>;
            }
        })}
    </ul>);
};

export default List;
