import React from 'react';
import './pagination.css';
import { connect } from 'react-redux';
import iconArrowLeft from '../../Images/arrow-left-brand.svg';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const pager = this.props.usingInvoice ? this.props.invoice.paginationList : this.props.company.paginationList;
        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }

        return (
            <ul className='pagination'>
                <li className='pagination__item'>
                    <button
                        type='button'
                        title='Anterior'
                        disabled={(pager.currentPage === 1)}
                        onClick={() => {
                            this.props.handlePageChange(pager.currentPage - 1);
                        }}
                        className={`pagination__btn ${pager.currentPage === 1 ? 'pagination__btn_disabled' : ''}`}
                    >
                        <img src={iconArrowLeft} alt='Ícone flecha voltar' />
                    </button>
                </li>
                {pager.pages.map((page, index) =>
                    <li className='pagination__item'
                        key={index}
                    >
                        <button
                            type='button'
                            className={`pagination__btn ${pager.currentPage === page ? 'pagination__btn_active' : ''}`}
                            title={`Página ${page}`}
                            onClick={() => {
                                this.props.handlePageChange(page - 1);
                            }}
                        >
                            {page}
                        </button>
                    </li>
                )}
                <li className='pagination__item'>
                    <button
                        type='button'
                        title='Próxima'
                        id="next_pagination"
                        disabled={pager.currentPage === pager.totalPages}
                        onClick={() => {
                            if (pager.currentPage !== pager.totalPages) {
                                this.props.handlePageChange(pager.currentPage);
                            }
                        }}
                        className={`pagination__btn ${(pager.currentPage === pager.totalPages) ? 'pagination__btn_disabled' : ''}`}
                    >
                        <img src={iconArrowLeft} alt='Ícone flecha voltar' />
                    </button>
                </li>
            </ul>
        );
    }
}

const mapStateToProps = state => ({
    company: {
        filters: state.company.filters,
        pagination: state.company.pagination,
        paginationList: state.company.paginationList
    },
    invoice: {
        filters: state.invoice.filters,
        pagination: state.invoice.pagination,
        paginationList: state.invoice.paginationList
    }
});

export default connect(mapStateToProps)(Pagination);
