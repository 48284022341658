import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoPNG from '../../../Images/logo.png';
import LogoWebP from '../../../Images/logo.webp';
import './logo.css';

const Logo = () => (
    <NavLink 
        to='/empresas'
        className='logo' 
        title='DicasMEI Home Page' 
    >
        <picture>
            <source type='image/webp' srcSet={LogoWebP} />
            <source type='image/png'  srcSet={LogoPNG} />
            <img    src={LogoPNG}    alt='Logo DicasMEI' />
        </picture>
    </NavLink>
);

export default Logo;