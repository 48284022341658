import React from 'react';
import './modal.css';
import iconClose from '../../Images/icon-close.svg';

const Modal = ({ children, handleClose, small }) => (
    <section className='modal'>
        <div className={`modal__content ${small ? 'modal__content_small' : ''}`}>
            <div className='modal__body'>
                <button 
                    className='modal__close-btn' 
                    type='button'
                    onClick={handleClose}
                >
                    <img src={iconClose} alt='Ícone fechar' />
                </button>                
                { children }
            </div>
        </div>       
    </section>
);

export default Modal;