import React from 'react';
import iconArrowDown from '../../../Images/icon-arrow-down.svg';
import './label.css';

const Label = ({
    handleLabelID,
    handleLabelCNPJ,  
    handleLabelEntryDate,
    handleLabelStatus,
    labelIdAscending,
    labelCnpjAscending,
    labelEntryDateAscending,
    labelStatusAscending
}) => ( 
    <div className='invoice-label'>
        <button className='invoice-label__btn' name='invoice-label-id' type='button' onClick={handleLabelID}>
            ID 
            <div className={labelIdAscending ? 'invoice-label__icon' : 'invoice-label__icon invoice-label__icon_up'}>
                <img src={iconArrowDown} alt='Ícone seta para baixo'/>
            </div>
        </button>
        <button className='invoice-label__btn' type='button' onClick={handleLabelCNPJ}>
            CNPJ
            <div className={labelCnpjAscending ? 'invoice-label__icon' : 'invoice-label__icon invoice-label__icon_up'}>
                <img src={iconArrowDown} alt='Ícone seta para baixo' />
            </div>
        </button>
        <button className='invoice-label__btn' type='button' onClick={handleLabelEntryDate}>
            Data entrada
            <div className={labelEntryDateAscending ? 'invoice-label__icon' : 'invoice-label__icon invoice-label__icon_up'}>
                <img src={iconArrowDown} alt='Ícone seta para baixo' />
            </div>
        </button>
        <button className='invoice-label__btn' type='button' onClick={handleLabelStatus}>
            Status
            <div className={labelStatusAscending ? 'invoice-label__icon' : 'invoice-label__icon invoice-label__icon_up'}>
                <img src={iconArrowDown} alt='Ícone seta para baixo' />
            </div>
        </button>
        <button className='invoice-label__btn' type='button' onClick={handleLabelStatus}>
            Valor
            <div className={labelStatusAscending ? 'invoice-label__icon' : 'invoice-label__icon invoice-label__icon_up'}>
                <img src={iconArrowDown} alt='Ícone seta para baixo' />
            </div>
        </button>
    </div>
);

export default Label;