import React from 'react';
import iconClip from '../../../Images/icon-clip.svg';
import iconPendent from '../../../Images/icon-pendent.svg';
import iconProcessing from '../../../Images/icon-processing.svg';
import iconSuccess from '../../../Images/icon-success.svg';
import iconCanceled from '../../../Images/icon-canceled.svg';
import './list.css';

const buttonClass = {
    1: 'invoice-list__btn invoice-list__btn_pendent',
    2: 'invoice-list__btn',
    4: 'invoice-list__btn invoice-list__btn_canceled',
    5: 'invoice-list__btn invoice-list__btn_canceled',
    6: 'invoice-list__btn invoice-list__btn_canceled',
    7: 'invoice-list__btn invoice-list__btn_canceled',
    8: 'invoice-list__btn invoice-list__btn_canceled',
};
const statusName = {
    1: 'Pendente',
    2: 'Emitida',
    3: 'Cancelada',
    4: 'Sem Talão',
    5: 'Prefeitura Indisponível',
    6: 'Ação Necessária do Agente',
    7: 'Pendente AIDF',
    8: 'Nota Duplicada',
};
const mainClass = {
    1: 'invoice-list__item invoice-list__item_pendent',
    2: 'invoice-list__item',
    3: 'invoice-list__item invoice-list__item_canceled',
    4: 'invoice-list__item invoice-list__item_canceled',
    5: 'invoice-list__item invoice-list__item_canceled',
    6: 'invoice-list__item invoice-list__item_canceled',
    7: 'invoice-list__item invoice-list__item_canceled',
    8: 'invoice-list__item invoice-list__item_canceled',
    Processando: 'invoice-list__item invoice-list__item_processing',
};
const mainIcon = {
    1: iconPendent,
    2: iconSuccess,
    3: iconCanceled,
    4: iconPendent,
    5: iconPendent,
    6: iconPendent,
    7: iconPendent,
    8: iconCanceled,
    Processando: iconProcessing,
};
const List = ({ filteredInvoice, handleShowInvoiceDetail, }) => (
    <ul className='invoice-list'>
        {filteredInvoice && filteredInvoice.map((invoice, index) => (
            <li
                className={mainClass[invoice.codstatus]}
                data-cnpj={invoice.cnpj}
                data-status={statusName[invoice.codstatus]}
                data-codstatus={invoice.codstatus}
                data-coddemanda={invoice.coddemanda}
                data-mensagem={invoice.mensagem}
                key={index}
            >
                <span className='invoice-list__icon'>
                    <img src={mainIcon[invoice.codstatus]} alt='Ícone seta' />
                </span>
                <span className='invoice-list__span'>{ invoice.coddemanda }</span>
                <span className='invoice-list__span'>{ invoice.cnpj }</span>
                <span className='invoice-list__span'>{ invoice.dataInicial }</span>
                <span className='invoice-list__span'>{ statusName[invoice.codstatus] }</span>

                { (invoice.codstatus !== 'Processando' && invoice.codstatus !== 3) &&
                    <button className={buttonClass[invoice.codstatus]} type='button' onClick={handleShowInvoiceDetail}>
                        <div className='invoice-list__btn-icon'>
                            <img src={iconClip} alt='Ícone Clip' />
                        </div>
                    </button>
                }

            </li>
        ))}
    </ul>
);

export default List;
