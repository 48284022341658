import App from './App';
import React from 'react';
import './Shared/css/form.css';
import './Shared/css/index.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Store from './Store';
import * as serviceWorker from './serviceWorker';
import { Provider as BumbagProvider } from 'bumbag';
import { Notifications } from 'react-push-notification';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

ReactDOM.render(
    <Provider store={Store}>
        <BumbagProvider>
            <Notifications />
            <App/>
        </BumbagProvider>
    </Provider>
    ,document.getElementById('root'));

serviceWorker.unregister();
