import React from 'react';
import { Link } from 'react-router-dom';
import './Avatar.css';
import iconUser from '../../../Images/user.svg';
import iconArrowDown from '../../../Images/icon-arrow-down-white.svg';

const Avatar = ({
    avatarMenu,
    handleStateToggle,
    handleLogoff
}) => (
    <div className='avatar'>
        <button
            className='avatar__button'
            name='avatarMenu'
            onClick={handleStateToggle}
            type='button'
        >
            <span className='avatar__logo'>
                <img src={iconUser} alt='Avatar' />
            </span>
            <span className='avatar__arrow-down'>
                <img src={iconArrowDown} alt='Ícone seta para baixo' />
            </span>
        </button>
        { avatarMenu &&
            <div className='avatar__menu'>
                <span className='avatar__user' role='img' aria-label='Smiling Emoji'>
                    Olá! 😉
                </span>
                <Link
                    className='avatar__link'
                    onClick={handleLogoff}
                    to='/login'
                    title='Sair'
                >
                    Sair
                </Link>
            </div>
        }
    </div>
);

export default Avatar;
