import React, {useEffect, useState} from 'react';
import {ValidatorForm} from 'react-form-validator-core';
import TextValidator from '../../TextValidator/TextValidator';
import TextareaValidator from '../../TextareaValidator/TextareaValidator';
import './detail.scss';
import iconArrowDown from '../../../Images/icon-arrow-down.svg';
import iconArrowDownWhite from '../../../Images/icon-arrow-down-white.svg';
import iconArrowLeft from '../../../Images/icon-arrow-left.svg';
import Loader from '../../Loader/Loader';
import Axios from 'axios';
import {filter, map} from 'lodash';
import {FaChevronDown, FaChevronUp, GrClose} from 'react-icons/all';
import moment from 'moment';
import Modal from 'react-modal';
import {applyTheme, Button, SwitchField} from 'bumbag';

ValidatorForm.addValidationRule('isCPF', (val) => {
    const value = val.replace(/[^\d]+/g,'');

    if (value.length === 11) {
        let sum = 0;
        let mod;
        let i;

        if (value === '00000000000') return false;

        for (i = 1; i <= 9; i++) sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
        mod = (sum * 10) % 11;

        if ((mod === 10) || (mod === 11))  mod = 0;
        if (mod !== parseInt(value.substring(9, 10)) ) return false;

        sum = 0;
        for (i = 1; i <= 10; i++) sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);
        mod = (sum * 10) % 11;

        if ((mod === 10) || (mod === 11))  mod = 0;
        if (mod !== parseInt(value.substring(10, 11) ) ) return false;
        return true;
    } else if (value.length < 11) {
        return false;
    }
    return true;
});

ValidatorForm.addValidationRule('isCNPJ', (value) => {
    let cnpj = value.replace(/[^\d]+/g,'');

    if (cnpj.length === 14) {
        // Elimina CNPJs invalidos conhecidos
        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999')
            return false;

        // Valida DVs
        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0,size);
        let digits = cnpj.substring(size);
        let sum = 0;
        let pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== Number(digits.charAt(0)))
            return false;

        size = size + 1;
        numbers = cnpj.substring(0,size);
        sum = 0;
        pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== Number(digits.charAt(1)))
            return false;

        return true;
    } else if (cnpj.length < 14 && cnpj.length > 11) {
        return false;
    }
    return true;
});

ValidatorForm.addValidationRule('isCurrency', (value) => {
    const number = Number(value.replace('R$ ', ''));
    return number <= 0 ? false : true;
});

const PdfSwitch = applyTheme(SwitchField, {
    styles: {
        base: {
            marginTop: '30px',
            marginBottom: '30px',
        }
    }
});

const InvoiceDetail = (props) => {
    const {
        invoiceProviderOpen,
        invoiceHirerOpen,
        invoiceDistinctionOpen,
        invoiceTaxesOpen,
        invoiceOtherInfoOpen,
        invoiceAttachmentOpen,
        handleCopyToClipboard,
        handleInvoiceDetailClose,
        validateCpfOrCnpj,
        invoiceServiceInfo,
        invoiceOtherInfo,
        handleInputChange,
        invoiceFilePhotoName,
        invoiceFilePDFName,
        invoiceFileXMLName,
        handleInvoiceDetailSection,
        detailNumeroNota,
        detailValorNota,
        detailDataEmissao,
        validateCurrency,
        validatePhone,
        detailIDDemanda,
        detailPrestadorCpfCnpj,
        detailPrestadorRazao,
        detailPrestadorInscricao,
        detailPrestadorEndereco,
        detailPrestadorEstado,
        detailPrestadorCidade,
        detailPrestadorEmail,
        detailPrestadorPhone,
        detailPrestadorTipoCNPJ,
        detailTomadorCpfCnpj,
        detailTomadorRazao,
        detailTomadorInscricao,
        detailTomadorEndereco,
        detailTomadorEstado,
        detailTomadorCidade,
        detailTomadorEmail,
        detailTomadorPhone,
        detailTomadorTipoCNPJ,
        detailEncargoINSS,
        detailEncargoIRRF,
        detailEncargoCSLL,
        detailEncargoCOFINS,
        detailEncargoPISPASEP,
        detailEncargoCodServico,
        detailEncargoAliquota,
        detailEncargoISS,
        detailEncargoMunicipioPrestacao,
        dicasMEIMessage,
        detailPrefeituraHomologada,
        handleInputFileChange,
        invoiceFilePhotoError,
        invoiceFilePDFError,
        invoiceFileXMLError,
        invoiceDownloadFileOpen,
        detailFileXML,
        detailFilePDF,
        detailFilePhoto,
        invoiceServiceCNAE,
        invoiceServiceLC116,
        detailPrestadorNumero,
        detailPrestadorComplemento,
        detailPrestadorBairro,
        detailPrestadorCEP,
        detailTomadorNumero,
        detailTomadorComplemento,
        detailTomadorBairro,
        detailTomadorCEP,
        invoiceDetailRegisterLoading,
        handleSetInvoice,
        invoiceInscricaoOpen,
        detailInscricaoMunicipal,
        detailInscricaoEstadual,
        detailCredentialTokenUser,
        detailCredentialPassword,
        detailCertifiedPFX,
        invoiceCredentialOpen,
        detailVerificationCode,
        detailSerie,
        detailNfeId,
        handleCloseWithoutSave,
        pdfValidationRequest,
        pdfValidationResponse,
        setPdfValitationRequest,
        clearPdfValidationResponse
    } = props;

    const psModalStyles = {
        content : {
            height                : '16em',
            width                 : '30em',
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            border                : 'none',
            // border                : 'solid 2px #002240',
            paddingBottom         : '10px',
        }
    };

    const [obs, setObs] = useState('');
    const [status, setStatus] = useState(0);
    const [needFix, setNeedFix] = useState(false);
    const [psModal, setPsModal] = useState(false);
    const [actionType, setActionType] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [nfeHistory, setNfeHistory] = useState(null);
    const [pdfResponse, setPdfResponse] = useState([]);
    const [showHistory, setShowHistory] = useState(true);
    const [showActions, setShowActions] = useState(false);
    const [talaoSucesso, setTalaoSucesso] = useState(null);
    const [talaoWarning, setTalaoWarning] = useState(null);
    const [transactionId, setTransactionId] = useState('');
    const [showPdfValidation, setShowPdfValidation] = useState(false);
    const [cleanCompanyData, setCleanCompanyData] = useState({});
    const [validatePdfResponse, setValidatePdfResponse] = useState(false);
    const [invoiceDetailReadOnly, setInvoiceDetailReadOnly] = useState(false);

    useEffect(() => {
        getNfeHistory(detailIDDemanda);
        setInvoiceDetailReadOnly(props.invoiceDetailReadOnly);
    }, []);
    
    useEffect(() => {
        if (pdfResponse && pdfResponse.length > 0) {
            setValidatePdfResponse(validateResponseFields());
        }
    }, [pdfResponse]);

    useEffect(() => {
        if (pdfValidationResponse && !showPdfValidation) {
            const pdfErrors = filter(pdfValidationResponse.validations, {passed: false});
            if (pdfErrors && pdfResponse.length === 0) {
                const newResponse = [];
                pdfErrors.map(error => {
                    newResponse.push({
                        field: error.field,
                        ignore: false
                    });
                });
                setPdfResponse(newResponse);
            }
        }
    }, [pdfValidationResponse, showPdfValidation, pdfResponse]);

    useEffect(() => {
        if (actionType !== 0) setPsModal(true);
    }, [actionType]);

    useEffect(() => {
        if (nfeHistory) {
            setNeedFix(nfeHistory.codstatus >= 4);
            setInvoiceDetailReadOnly(nfeHistory.codstatus >= 4);
        }
    }, [nfeHistory]);

    useEffect(() => {
        if (transactionId !== '') {
            setStatus(1);
            setTimeout(() => consultListener(), 5000);
        }
    }, [transactionId]);

    const validateToken = () => {
        const expiresIn = localStorage.getItem('dicasMEIToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('dicasMEIToken');
            localStorage.removeItem('dicasMEIToken_expiration');
            localStorage.setItem('loggedIn', false);
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('dicasMEIToken');
    };

    const getNfeHistory = (idDemanda) => {
        Axios.get(`https://www.dicasmei.com.br/api/backoffice/demanda/${idDemanda}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': validateClientToken()
            }
        }).then(response => {
            setNfeHistory(response.data);
        }).catch(error => {
            if (error && error.response && error.response.data) {
                setNfeHistory(error.response.data);
            }
        });
    };

    const validateClientToken = () => {
        const expiresIn = localStorage.getItem('clientToken_expiration');
        if (!expiresIn || new Date() > new Date(expiresIn)) {
            localStorage.removeItem('clientToken');
            localStorage.removeItem('clientToken_expiration');
            localStorage.setItem('loggedIn', false);
            this.setState({loggedIn: false});
            window.location.href = '/login';
            return null;
        }
        return localStorage.getItem('clientToken');
    };

    // ID MAP
    // 4 - Sem Talão Manual
    // 5 - Prefeitura Indisponível
    // 6 - Ação Necessária do Agente
    // 7 - Pendente AIDF
    const handleModalConfirm = () => {
        Axios.post('https://www.dicasmei.com.br/api/backoffice/nota/finaliza', {
            pdf: '',
            xml: '',
            status: actionType,
            mensagem: obs,
            id_demanda: detailIDDemanda
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': validateClientToken()
            }
        }).then(
            () => {
                setActionType(0);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setShowModal(false);
                setTalaoSucesso('Nota alterada com sucesso!');
            }
        ).catch(
            () => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setShowModal(false);
                setTalaoWarning('Ocorreu um erro ao tentar alterar o status da nota, por favor, verifique sua conexao e tente novamente');
            }
        );
    };

    const handleFixNfe = () => {
        Axios.post('https://www.dicasmei.com.br/api/backoffice/nota/reprocessar', {
            TransactionID: detailNfeId
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': validateClientToken()
            }
        }).then(
            () => {
                handleCloseWithoutSave();
            }
        ).catch(
            () => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setShowModal(false);
                setTalaoWarning('Ocorreu um erro ao tentar alterar o status da nota, por favor, verifique sua conexao e tente novamente');
            }
        );
    };

    const consultListener = () => {
        Axios.post(`${props.getApiUrl('cnpj/consulta')}`, {
            TransactionID: transactionId
        },{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateToken()
            }
        })
            .then(async (res) => {
                switch (res.data.Transaction_Return.cod) {
                case 99:
                    setStatus(4);
                    setTimeout(() => {
                        setStatus(0);
                    }, 6000);
                    setCleanCompanyData(res.data.dados.cartaoNacionalPessoaJuridica);
                    break;
                case 1:
                case 2:
                    setStatus(2);
                    setTimeout(() => consultListener(), 5000);
                    break;
                default:
                    setStatus(3);
                    setTimeout(() => {
                        setStatus(0);
                    }, 10000);
                    break;
                }
            });
    };

    const getCleanCompanyInfo = () => {
        return Axios.post(`${props.getApiUrl('cnpj/solicita')}`, {
            CNPJ: props.detailPrestadorCpfCnpj,
            tp_consulta: 'X',
            origem: 'BACKOFFICE',
            force: true
        },{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: validateToken()
            }
        });
    };

    const handleCleanConsult = () => {
        if (props.detailPrestadorCpfCnpj !== '') {
            setStatus(2);
            getCleanCompanyInfo()
                .then(response => {
                    scrollToTop();
                    setTransactionId(response.data.TransactionID);
                });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    const changePdfResponseObject = (field, value) => {
        const newResponse = map(pdfResponse, function (o) {
            if (o.field === field) {
                o.ignore = value;
            }
            return o;
        });
        setPdfResponse(newResponse);
    };
    
    const validateResponseFields = () => {
        if (pdfResponse) {
            const not_ignored = filter(pdfResponse, { ignore: false });
            if (not_ignored) {
                return not_ignored.length === 0;
            }
        }
        return false;
    };

    return (<div className='invoice-detail'>

        { dicasMEIMessage && <div className='dicasMEI-message'> { dicasMEIMessage } </div> }

        { talaoSucesso && <div className='talaoSucesso-message'> { talaoSucesso } </div> }

        { talaoWarning && <div className='talaoError-message'> { talaoWarning } </div> }

        { (status === 2) &&
            <div className='backoffice-info-message'>
                Obtendo dados da empresa, aguarde... (A consulta pode demorar alguns minutos)
            </div>
        }

        { (status === 3) &&
            <div className='talaoError-message'>
                Ocorreu um erro ao obter os dados da empresa, verifique sua conexão e tente novamente.
            </div>
        }

        { (status === 4) &&
            <div className='talaoSucesso-message'>
                Dados da empresa carregado com sucesso.
            </div>
        }

        <header className='invoice-detail__header'>
            <div className='invoice-detail__main-title'>
                <button className='invoice-detail__close' type='button' onClick={() => {
                    if (invoiceDetailReadOnly) {
                        handleCloseWithoutSave();
                    } else {
                        handleInvoiceDetailClose();
                    }
                }}>
                    <div className='invoice-detail__close-icon'>
                        <img src={iconArrowLeft} alt='Icone voltar' />
                    </div>
                    <span>Voltar</span>
                </button>
                <h1 className='page-title page-title_invoice-detail'>Cadastro Nota Fiscal</h1>
            </div>
        </header>

        { invoiceDetailRegisterLoading ||
            <ValidatorForm onSubmit={handleSetInvoice}>
                <div className='invoice-detail__title' style={{marginBottom: '20px'}}>
                    <label className='label'>
                        <TextValidator
                            className='input'
                            name='detailSerie'
                            type={invoiceDetailReadOnly ? 'text' : 'number'}
                            value={detailSerie}
                            onChange={handleInputChange}
                            readOnly={invoiceDetailReadOnly ? true : false}
                            required
                        />
                        <p className='label__p label__p_float'>Número de série</p>
                    </label>
                    <label className='label'>
                        <TextValidator
                            className='input'
                            name='detailVerificationCode'
                            type={'text'}
                            value={detailVerificationCode}
                            onChange={handleInputChange}
                            readOnly={invoiceDetailReadOnly ? true : false}
                            required
                        />
                        <p className='label__p label__p_float'>Código de verificação</p>
                    </label>
                </div>
                <div className='invoice-detail__title'>
                    <label className='label'>
                        <TextValidator
                            className='input'
                            name='detailNumeroNota'
                            type='number'
                            value={detailNumeroNota}
                            validators={['isNumber']}
                            errorMessages={['Valor inválido!']}
                            onChange={handleInputChange}
                            readOnly={invoiceDetailReadOnly ? true : false}
                            required
                        />
                        <p className='label__p label__p_float'>Número da Nota</p>
                    </label>
                    <label className='label'>
                        <TextValidator
                            className='input input_copy'
                            name='detailValorNota'
                            type='text'
                            value={detailValorNota}
                            onChange={validateCurrency}
                            onClick={handleCopyToClipboard}
                            readOnly
                            required
                        />
                        <p className='label__p label__p_float'>Valor da Nota (R$)</p>
                    </label>
                    <label className='label'>
                        <TextValidator
                            className='input'
                            name='detailDataEmissao'
                            type={invoiceDetailReadOnly ? 'text' : 'date'}
                            value={detailDataEmissao}
                            validators={['minStringLength:10']}
                            errorMessages={['Data inválida!']}
                            onChange={handleInputChange}
                            readOnly={invoiceDetailReadOnly ? true : false}
                            required
                        />
                        <p className='label__p label__p_float'>Data de Emissão</p>
                    </label>
                    { invoiceDetailReadOnly ||
                        <button
                            className='btn btn_right'
                            type="button"
                            title="Anexar arquivo"
                            onClick={() => document.getElementById('attachment').scrollIntoView() }
                        >
                            Anexar arquivo
                            <div className='btn__icon'>
                                <img src={iconArrowDownWhite} alt='Ícone seta para baixo' />
                            </div>
                        </button>
                    }
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceProviderOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Prestador de Serviço</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    <button
                        className='c_button'
                        title={'Recarregar dados da empresa'}
                        style={{
                            height: '40px',
                            width: '210px',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            backgroundColor: '#002042'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleCleanConsult();
                        }}
                    >
                        Recriar cahe da empresa
                    </button>
                    { invoiceProviderOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_invoice-provider'>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorCpfCnpj'
                                    type='text'
                                    onChange={validateCpfOrCnpj}
                                    value={detailPrestadorCpfCnpj}
                                    maxLength='18'
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CPF / CNPJ</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorRazao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={(cleanCompanyData && cleanCompanyData.nomeEmpresarial) ?
                                        cleanCompanyData.nomeEmpresarial : detailPrestadorRazao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Nome / Razão Social</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorInscricao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorInscricao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Inscrição Municipal</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorEndereco'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={
                                        (cleanCompanyData &&
                                            cleanCompanyData.endereco &&
                                            cleanCompanyData.endereco.logradouro) ?
                                            cleanCompanyData.endereco.logradouro :
                                            detailPrestadorEndereco
                                    }
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Endereço</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorNumero'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={
                                        (cleanCompanyData &&
                                            cleanCompanyData.endereco &&
                                            cleanCompanyData.endereco.numero) ?
                                            cleanCompanyData.endereco.numero :
                                            detailPrestadorNumero
                                    }
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Número</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorComplemento'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={
                                        (cleanCompanyData &&
                                            cleanCompanyData.endereco &&
                                            cleanCompanyData.endereco.complemento) ?
                                            cleanCompanyData.endereco.complemento :
                                            detailPrestadorComplemento
                                    }
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Complemento</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorBairro'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={
                                        (cleanCompanyData &&
                                            cleanCompanyData.endereco &&
                                            cleanCompanyData.endereco.bairroDistrito) ?
                                            cleanCompanyData.endereco.bairroDistrito :
                                            detailPrestadorBairro
                                    }
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Bairro</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorCEP'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={
                                        (cleanCompanyData &&
                                            cleanCompanyData.endereco &&
                                            cleanCompanyData.endereco.cep) ?
                                            cleanCompanyData.endereco.cep :
                                            detailPrestadorCEP
                                    }
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CEP</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorEstado'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={
                                        (cleanCompanyData &&
                                            cleanCompanyData.endereco &&
                                            cleanCompanyData.endereco.uf) ?
                                            cleanCompanyData.endereco.uf :
                                            detailPrestadorEstado
                                    }
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Estado (UF)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorCidade'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={
                                        (cleanCompanyData &&
                                            cleanCompanyData.endereco &&
                                            cleanCompanyData.endereco.municipio) ?
                                            cleanCompanyData.endereco.municipio :
                                            detailPrestadorCidade
                                    }
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Cidade</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorEmail'
                                    type='email'
                                    onChange={handleInputChange}
                                    value={
                                        (cleanCompanyData && cleanCompanyData.enderecoEletronico) ?
                                            cleanCompanyData.enderecoEletronico :
                                            detailPrestadorEmail
                                    }
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>E-mail</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorPhone'
                                    type='tel'
                                    onChange={validatePhone}
                                    value={
                                        (cleanCompanyData && cleanCompanyData.telefone) ?
                                            cleanCompanyData.telefone :
                                            detailPrestadorPhone
                                    }
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Fone</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailPrestadorTipoCNPJ'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailPrestadorTipoCNPJ}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Tipo CNPJ</p>
                            </label>
                        </div>
                    }
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceHirerOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Tomador de Serviço</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceHirerOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_invoice-provider'>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorCpfCnpj'
                                    type='text'
                                    onChange={validateCpfOrCnpj}
                                    value={detailTomadorCpfCnpj}
                                    maxLength='18'
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CPF / CNPJ</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorRazao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorRazao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Nome / Razão Social</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorInscricao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorInscricao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Inscrição Municipal</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorEndereco'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorEndereco}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Endereço</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorNumero'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorNumero}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Número</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorComplemento'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorComplemento}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Complemento</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorBairro'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorBairro}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Bairro</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorCEP'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorCEP}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CEP</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorEstado'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorEstado}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Estado (UF)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorCidade'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorCidade}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Cidade</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorEmail'
                                    type='email'
                                    onChange={handleInputChange}
                                    value={detailTomadorEmail}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>E-mail</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorPhone'
                                    type='tel'
                                    onChange={validatePhone}
                                    value={detailTomadorPhone}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Fone</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailTomadorTipoCNPJ'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailTomadorTipoCNPJ}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Tipo CNPJ</p>
                            </label>
                        </div>
                    }
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceDistinctionOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Discriminação dos serviços</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceDistinctionOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_distinction'>
                            <label className='label'>
                                <TextareaValidator
                                    name='invoiceServiceInfo'
                                    className='input input_copy'
                                    value={invoiceServiceInfo}
                                    onChange={handleInputChange}
                                    rows='5'
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Discriminação</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    name='invoiceServiceCNAE'
                                    className='input input_copy'
                                    value={invoiceServiceCNAE}
                                    onChange={handleInputChange}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CNAE</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    name='invoiceServiceLC116'
                                    className='input input_copy'
                                    value={invoiceServiceLC116}
                                    onChange={handleInputChange}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>LC116</p>
                            </label>
                        </div>
                    }
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceTaxesOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Encargos</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceTaxesOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_taxes'>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoINSS'
                                    type='text'
                                    value={detailEncargoINSS}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>INSS (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoIRRF'
                                    type='text'
                                    value={detailEncargoIRRF}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>IRRF (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoCSLL'
                                    type='text'
                                    value={detailEncargoCSLL}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>CSLL (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoCOFINS'
                                    type='text'
                                    value={detailEncargoCOFINS}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>COFINS (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoPISPASEP'
                                    type='text'
                                    value={detailEncargoPISPASEP}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>PIS/PASEP (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoCodServico'
                                    type='number'
                                    value={detailEncargoCodServico}
                                    onChange={handleInputChange}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Cód. Serviço</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoAliquota'
                                    type='number'
                                    value={detailEncargoAliquota}
                                    onChange={handleInputChange}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Alíquota (%)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoISS'
                                    type='text'
                                    value={detailEncargoISS}
                                    onChange={validateCurrency}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Valor de ISS (R$)</p>
                            </label>
                            <label className='label'>
                                <TextValidator
                                    className='input input_copy'
                                    name='detailEncargoMunicipioPrestacao'
                                    type='text'
                                    onChange={handleInputChange}
                                    value={detailEncargoMunicipioPrestacao}
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Município da Prestação de Serviço</p>
                            </label>
                        </div>
                    }
                </div>

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceOtherInfoOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Outras Informações</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceOtherInfoOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_other-info'>
                            <label className='label'>
                                <TextareaValidator
                                    name='invoiceOtherInfo'
                                    className='input input_copy'
                                    value={invoiceOtherInfo}
                                    onChange={handleInputChange}
                                    rows='5'
                                    onClick={handleCopyToClipboard}
                                    readOnly
                                    required
                                />
                                <p className='label__p label__p_float'>Discriminação</p>
                            </label>
                        </div>
                    }
                </div>

                { (detailInscricaoMunicipal || detailInscricaoEstadual) && <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceInscricaoOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Inscrição Municipal/Estadual</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceInscricaoOpen &&  <div className='invoice-detail__section-content invoice-detail__section-content_inscricao'>
                        <label className='label'>
                            <TextValidator
                                className='input input_copy'
                                name='detailInscricaoMunicipal'
                                type='text'
                                value={detailInscricaoMunicipal}
                                onClick={handleCopyToClipboard}
                                readOnly
                                required
                            />
                            <p className='label__p label__p_float'>Inscrição Municipal</p>
                        </label>
                        <label className='label'>
                            <TextValidator
                                className='input input_copy'
                                name='detailInscricaoEstadual'
                                type='text'
                                value={detailInscricaoEstadual}
                                onClick={handleCopyToClipboard}
                                readOnly
                                required
                            />
                            <p className='label__p label__p_float'>Inscrição Estadual</p>
                        </label>
                    </div> }

                </div>  }

                { (detailCredentialTokenUser || detailCredentialPassword || detailCertifiedPFX) && <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceCredentialOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Credenciais de acesso</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceCredentialOpen && <div className='invoice-detail__section-content invoice-detail__section-content_credential'>
                        { detailCredentialTokenUser && <label className='label'>
                            <TextValidator
                                className='input input_copy'
                                name='detailCredentialTokenUser'
                                type='text'
                                value={detailCredentialTokenUser}
                                onClick={handleCopyToClipboard}
                                readOnly
                                required
                            />
                            <p className='label__p label__p_float'>Token/usuário de acesso à prefeitura</p>
                        </label>  }
                        { detailCredentialPassword && <label className='label'>
                            <TextValidator
                                className='input input_copy'
                                name='detailCredentialPassword'
                                type='text'
                                value={detailCredentialPassword}
                                onClick={handleCopyToClipboard}
                                readOnly
                                required
                            />
                            <p className='label__p label__p_float'>Senha de acesso à prefeitura</p>
                        </label> }
                        { detailCertifiedPFX && <a
                            className='invoice-detail__link-download'
                            rel="noopener noreferrer"
                            href={detailCertifiedPFX}
                            target='_blank'
                            download
                        >
                                Download Certificado (pfx)
                        </a> }
                    </div> }
                </div>  }

                { invoiceDetailReadOnly && <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' data-state='invoiceDownloadFileOpen' onClick={handleInvoiceDetailSection}>
                        <h2 className='invoice-detail__section-title'>Download de arquivos</h2>
                        <div className='invoice-detail__section-icon'>
                            <img src={iconArrowDown} alt='Ícone seta para baixo' />
                        </div>
                    </div>
                    { invoiceDownloadFileOpen &&
                        <div className='invoice-detail__section-content invoice-detail__section-content_download-file'>
                            { detailFileXML &&
                            <a
                                className='invoice-detail__link-download'
                                rel="noopener noreferrer"
                                href={`https://www.dicasmei.com.br/upload/evidencias/${detailFileXML}`}
                                target='_blank'
                                download>
                                Download XML
                            </a> }
                            { detailFilePDF &&
                            <a
                                className='invoice-detail__link-download'
                                rel="noopener noreferrer"
                                href={`https://www.dicasmei.com.br/upload/evidencias/${detailFilePDF}`}
                                target='_blank'>
                                Download PDF
                            </a> }
                            { detailFilePhoto &&
                            <a
                                className='invoice-detail__link-download'
                                rel="noopener noreferrer"
                                href={detailFilePhoto}
                                target='_blank'>
                                Download Imagem
                            </a> }
                        </div>
                    }
                </div>
                }

                { !invoiceDetailReadOnly &&
                    <div id='attachment' className='invoice-detail__section' title='Clique para esconder'>
                        <div className='invoice-detail__section-header' data-state='invoiceAttachmentOpen' onClick={handleInvoiceDetailSection}>
                            <h2 className='invoice-detail__section-title'>
                                { detailPrefeituraHomologada ? 'Anexar PDF / XML' : 'Anexar PDF' }
                            </h2>
                            <div className='invoice-detail__section-icon'>
                                <img src={iconArrowDown} alt='Ícone seta para baixo' />
                            </div>
                        </div>

                        { (invoiceAttachmentOpen && !detailPrefeituraHomologada) &&
                            <div className='invoice-detail__section-content invoice-detail__section-content_attachment'>
                                <label className='label label_input-photo'>
                                    <span className='input input_file-custom'>
                                        { invoiceFilePhotoName }
                                    </span>
                                    <p className='label__p label__p_float'>Envie um PDF</p>
                                    <input
                                        className='input_file'
                                        name='invoiceFilePhoto'
                                        type='file'
                                        onChange={handleInputFileChange}
                                        accept='application/pdf'
                                        data-accept='^.*\.(pdf|PDF)$'
                                        required
                                    />
                                    { invoiceFilePhotoError && <div className='error-message'> { invoiceFilePhotoError } </div>}
                                </label>
                            </div>
                        }

                        { (invoiceAttachmentOpen && detailPrefeituraHomologada) &&
                            <div className='invoice-detail__section-content invoice-detail__section-content_attachment'>
                                <label className='label label_input-pdf'>
                                    <span className='input input_file-custom'>
                                        { invoiceFilePDFName }
                                    </span>
                                    <p className='label__p label__p_float'>PDF Nota Fiscal</p>
                                    <input
                                        className='input_file'
                                        name='invoiceFilePDF'
                                        type='file'
                                        onChange={handleInputFileChange}
                                        accept='application/pdf'
                                        data-accept='^.*\.(pdf|PDF)$'
                                        required
                                    />
                                    { invoiceFilePDFError && <div className='error-message'> { invoiceFilePDFError } </div>}
                                </label>
                                <label className='label label_input-xml'>
                                    <span className='input input_file-custom'>
                                        { invoiceFileXMLName }
                                    </span>
                                    <p className='label__p label__p_float'>XML Nota Fiscal</p>
                                    <input
                                        className='input_file'
                                        name='invoiceFileXML'
                                        type='file'
                                        onChange={handleInputFileChange}
                                        accept='application/vnd.ms-excel, text/xml'
                                        data-accept='^.*\.(xml|XML)$'
                                        required
                                    />
                                    { invoiceFileXMLError && <div className='error-message'> { invoiceFileXMLError } </div>}
                                </label>
                            </div>
                        }

                    </div>
                }

                <div className='invoice-detail__section' title='Clique para esconder'>
                    <div className='invoice-detail__section-header' onClick={() => setShowHistory(!showHistory)}>
                        <h2 className='invoice-detail__section-title'>Histórico</h2>
                        <div className='invoice-detail__section-icon'>
                            {showHistory ? (<FaChevronUp />) : (<FaChevronDown />)}
                        </div>
                    </div>
                    { showHistory &&
                    <div className='invoice-detail__section-content invoice-detail__section-content_other-info'>
                        {(!nfeHistory || !nfeHistory.historicos) ? (
                            <h4 className={'historyError'}>
                                {(nfeHistory && !nfeHistory.historicos) ?
                                    ('Está nota ainda não possui um histórico.') :
                                    ('Não foi possivel carregar o histórico para esta nota. Contacte os desenvolvedores.')}
                            </h4>
                        ) : (
                            <table>
                                <thead>
                                    <tr>
                                        <th className={'tbHeader'}>Código</th>
                                        <th className={'tbHeader'}>Status</th>
                                        <th className={'tbHeader'}>Data</th>
                                        <th className={'tbHeader'}>Usuario</th>
                                        <th className={'tbHeader'}>Observação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {nfeHistory.historicos.map((item, key) => (
                                        <tr key={key}>
                                            <td className={'tbBody'}>{item.codigo}</td>
                                            <td className={'tbBody'}>{item.codstatus}</td>
                                            <td className={'tbBody'}>{moment(item.data).format('DD/MM/YYYY')}</td>
                                            <td className={'tbBody'}>{item.funcionario}</td>
                                            <td className={'tbBody'}>{item.desmensagem}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    }
                </div>


                {!invoiceDetailReadOnly &&
                (<div className={'buttons_aligment'}>
                    {/*<button className='c_button_talao'*/}
                    {/*    onClick={(e) => {*/}
                    {/*        e.preventDefault();*/}
                    {/*        setShowModal(true);}}*/}
                    {/*>*/}
                    {/*    Sem talao manual*/}
                    {/*</button>*/}
                    <div className={'action_button_container'}>
                        <button
                            className={'action_button'}
                            onClick={(e) => {
                                e.preventDefault();
                                setShowActions(!showActions);
                            }}
                        >
                            Mais ações
                            <FaChevronUp className={'action_button_arrow'} />
                        </button>
                        <div
                            id={'action_options'}
                            className={'action_items_container'}
                        >
                            <a
                                className={'action_button_item'}
                                onClick={() => setActionType(4)}
                            >
                                Sem Talão</a>
                            <a
                                className={'action_button_item'}
                                onClick={() => setActionType(5)}
                            >
                                Prefeitura Indisponível</a>
                            <a
                                className={'action_button_item'}
                                onClick={() => setActionType(6)}
                            >
                                Ação Necessária do Agente</a>
                            <a
                                className={'action_button_item'}
                                onClick={() => setActionType(7)}
                            >
                                Pendente AIDF
                            </a>
                            <a
                                className={'action_button_item'}
                                onClick={() => setActionType(8)}
                            >
                                Nota Duplicada
                            </a>
                        </div>
                    </div>
                    <button className='btn' type='submit'>Salvar Alterações</button>
                </div>)}
                {needFix && (
                    <div className={'buttons_aligment'}>
                        <button
                            className='btn'
                            onClick={(e) => {
                                e.preventDefault();
                                handleFixNfe();
                            }}
                        >
                            Reprocessar Nota
                        </button>
                    </div>
                )}
            </ValidatorForm>
        }

        { invoiceDetailRegisterLoading && <Loader message='Registrando nota...' /> }

        {pdfValidationResponse && filter(pdfValidationResponse.validations, {passed: false}).length >= 1 && (
            <div className="nfe_modal">
                <div className="nfe_modal_content">
                    <div className="nfe_modal_header">
                        <p className="nfe_modal_title">
                            Verifique as informações abaixo
                        </p>
                        <p className="nfe_modal_description">
                            Foram encontrados alguns erros no PDF que você anexou, por favor, verifique:
                        </p>
                        <p className="nfe_modal_pdf_text">
                            PDF Anexado:
                        </p>
                        <div className="nfe_modal_pdf">
                            <iframe
                                allow
                                title="PDF"
                                height={400}
                                width="100%"
                                allowFullScreen
                                src={pdfValidationResponse.pdf}
                            />
                        </div>
                        <p className="nfe_modal_errors_text">
                            Erros encontrados na validação:
                        </p>
                        <div className="nfe_modal_errors">
                            {filter(pdfValidationResponse.validations, {passed: false}).map((error, key) => (
                                <PdfSwitch
                                    key={key}
                                    label={
                                        `${error.on_error}
                                        ${error.search_term !== '' ? '(' + error.search_term + ')' : ''}`
                                    }
                                    switchProps={{
                                        state: 'warning',
                                        palette: '#45B347'
                                    }}
                                    switchLabel={`Ignorar erro ${error.label}`}
                                    onChange={e => changePdfResponseObject(error.field, e.target.checked)}
                                />
                            ))}
                        </div>
                        <div className="nfe_modal_buttons">
                            <Button palette="#fc5d5d" color="#ffffff" onClick={() => clearPdfValidationResponse()}>
                                Corrigir
                            </Button>
                            <Button
                                color="#ffffff"
                                palette="#45B347"
                                disabled={!validatePdfResponse}
                                title="Marque todas as opções para poder corrigir está nota"
                                onClick={() => setPdfValitationRequest(pdfResponse)}
                            >
                                Gravar
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )}

        <Modal
            isOpen={psModal}
            onRequestClose={() => {
                setActionType(0);
                setPsModal(false);
            }}
            style={psModalStyles}
            contentLabel="Observações"
        >
            <GrClose
                className={'psModal_close_icon'}
                onClick={() => {
                    setActionType(0);
                    setPsModal(false);
                }}
            />
            <div className={'psModal_container'}>
                <h3 className={'psModal_title invoice-detail__section-title'}>
                    Adicionar uma observação?
                </h3>
                <textarea
                    value={obs}
                    className={'psModal_input'}
                    onChange={(e) => setObs(e.target.value)}
                />
                <p className='label__p label__p_float psLabel'>Observação</p>
                <button
                    className={'psModal_button psCancel'}
                    onClick={(e) => {
                        e.preventDefault();
                        setActionType(0);
                        setPsModal(false);
                    }}
                >
                    Cancelar
                </button>
                <button
                    className={'psModal_button psSuccess'}
                    onClick={(e) => {
                        e.preventDefault();
                        setPsModal(false);
                        handleModalConfirm();
                    }}
                >
                    Salvar
                </button>
            </div>
        </Modal>
    </div>);};

export default InvoiceDetail;
