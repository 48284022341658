import React from 'react';
import Filter from './Filter/Filter';
import Table from './Table/Table';
import CancelInvoiceDetail from './CancelDetail/Detail';
import DetailClose from './DetailClose/DetailClose';
import Pagination from '../Pagination/Pagination';
import Loader from '../Loader/Loader';
import UserFeedback from '../UserFeedback/UserFeedback';

const CancelInvoice = ({
    page,
    setPage,
    filteredInvoice,
    filterDate,
    filterStatus,
    filterItemPerPage,
    labelIdAscending,
    labelCnpjAscending,
    labelValueAscending,
    labelEntryDateAscending,
    labelFinalDateAscending,
    labelStatusAscending,
    handleLabelCNPJ,
    handleLabelID,
    handleLabelValue,
    handleLabelEntryDate,
    handleLabelFinalDate,
    handleLabelStatus,
    handleFilterSearch,
    handleFilterDate,
    handleFilterStatus,
    handleFilterItemPerPage,
    itemPerPage,
    invoiceDetailOpen,
    invoiceDetail,
    handleShowInvoiceDetail,
    invoiceProviderOpen,
    invoiceHirerOpen,
    invoiceDistinctionOpen,
    invoiceTaxesOpen,
    invoiceOtherInfoOpen,
    invoiceAttachmentOpen,
    invoiceListOpen,
    handleCopyToClipboard,
    closeInvoiceDetailOpen,
    handleInvoiceDetailClose,
    handleCloseWithoutSave,
    handleCloseAndSave,
    validateCpfOrCnpj,
    invoiceServiceInfo,
    invoiceOtherInfo,
    invoiceFilePDF,
    invoiceFileXML,
    invoiceFilePhoto,
    handleInputChange,
    invoiceFilePhotoName,
    invoiceFilePDFName,
    invoiceFileXMLName,
    handlePageChange,
    filteredPageItems,
    handleInvoiceDetailSection,
    detailNumeroNota,
    detailValorNota,
    detailDataEmissao,
    invoiceListLoading,
    userFeedbackRegisterErrorMessage,
    userFeedbackRegisterSuccessMessage,
    invoiceDetailLoading,
    invoiceDetailReadOnly,
    handleDetailCloseButton,
    validateCurrency,
    validateCNPJ,
    detailPrestadorCpfCnpj,
    detailPrestadorRazao,
    detailPrestadorInscricao,
    detailPrestadorEndereco,
    detailPrestadorEstado,
    detailPrestadorCidade,
    detailPrestadorEmail,
    detailPrestadorPhone,
    validatePhone,
    detailIDDemanda,
    detailPrestadorTipoCNPJ,
    detailTomadorCpfCnpj,
    detailTomadorRazao,
    detailTomadorInscricao,
    detailTomadorEndereco,
    detailTomadorEstado,
    detailTomadorCidade,
    detailTomadorEmail,
    detailTomadorPhone,
    detailTomadorTipoCNPJ,
    detailEncargoINSS,
    detailEncargoIRRF,
    detailEncargoCSLL,
    detailEncargoCOFINS,
    detailEncargoPISPASEP,
    detailEncargoCodServico,
    detailEncargoAliquota,
    detailEncargoISS,
    detailEncargoMunicipioPrestacao,
    setInvoice,
    dicasMEIMessage,
    detailPrefeituraHomologada,
    handleInputFileChange,
    userFeedbackMessage,
    invoiceFilePhotoError,
    invoiceFilePDFError,
    invoiceFileXMLError,
    invoiceDownloadFileOpen,
    detailFileXML,
    detailFilePDF,
    detailFilePhoto,
    invoiceServiceCNAE,
    invoiceServiceLC116,
    detailPrestadorNumero,
    detailPrestadorComplemento,
    detailPrestadorBairro,
    detailPrestadorCEP,
    detailTomadorNumero,
    detailTomadorComplemento,
    detailTomadorBairro,
    detailTomadorCEP,
    invoiceDetailRegisterLoading,
    filterStatusValue,
    filterDateValue,
    filterSearchValue,
    handleSetInvoice,
    invoiceInscricaoOpen,
    detailInscricaoMunicipal,
    detailInscricaoEstadual,
    detailCredentialTokenUser,
    detailCredentialPassword,
    detailCertifiedPFX,
    invoiceCredentialOpen,
    handleCancelReasonChange,
    cancelNfeReason,
    cancelInProgress,
    handleCancelInvoice
}) => (
    <main className='main'>
        { userFeedbackRegisterErrorMessage && <UserFeedback message={userFeedbackRegisterErrorMessage} band={true} error={true} /> }
        { userFeedbackRegisterSuccessMessage && <UserFeedback message={userFeedbackRegisterSuccessMessage} band={true} success={true} /> }

        { invoiceListOpen &&
            <section className='invoice-list invoice-list_invoice'>
                <h1 className='page-title'>Notas Fiscais</h1>
                <Filter
                    handlePageChange={handlePageChange}
                    filterDate={filterDate}
                    filterStatus={filterStatus}
                    filterItemPerPage={filterItemPerPage}
                    handleFilterSearch={handleFilterSearch}
                    handleFilterDate={handleFilterDate}
                    handleFilterStatus={handleFilterStatus}
                    handleFilterItemPerPage={handleFilterItemPerPage}
                    filterStatusValue={filterStatusValue}
                    filterDateValue={filterDateValue}
                    itemPerPage={itemPerPage}
                    filterSearchValue={filterSearchValue}
                />
                { (filteredInvoice.length > 0 && !userFeedbackMessage) &&
                    <Table
                        labelIdAscending={labelIdAscending}
                        labelCnpjAscending={labelCnpjAscending}
                        labelValueAscending={labelValueAscending}
                        labelEntryDateAscending={labelEntryDateAscending}
                        labelFinalDateAscending={labelFinalDateAscending}
                        labelStatusAscending={labelStatusAscending}

                        handleLabelCNPJ={handleLabelCNPJ}
                        handleLabelID={handleLabelID}
                        handleLabelValue={handleLabelValue}
                        handleLabelEntryDate={handleLabelEntryDate}
                        handleLabelFinalDate={handleLabelFinalDate}
                        handleLabelStatus={handleLabelStatus}

                        filteredInvoice={filteredPageItems}

                        handleShowInvoiceDetail={handleShowInvoiceDetail}
                    />
                }
                { invoiceListLoading && <Loader message='Carregando notas...' /> }
                { (userFeedbackMessage && !invoiceListLoading) && <UserFeedback message={userFeedbackMessage} /> }
                { (filteredInvoice.length > 0 && userFeedbackMessage === '' && !filterSearchValue) &&
                    <Pagination
                        usingInvoice={true}
                        page={page}
                        setPage={setPage}
                        items={filteredInvoice}
                        handlePageChange={handlePageChange}
                        itemPerPage={itemPerPage}
                        filterDateValue={filterDateValue}
                        filterStatusValue={filterStatusValue}
                        filterSearchValue={filterSearchValue}
                    />
                }
            </section>
        }

        { invoiceDetailLoading && <Loader message='Carregando nota...' /> }

        { invoiceDetailOpen &&
            <CancelInvoiceDetail
                handleInputFileChange={handleInputFileChange}
                detailPrefeituraHomologada={detailPrefeituraHomologada}
                dicasMEIMessage={dicasMEIMessage}
                setInvoice={setInvoice}
                detailIDDemanda={detailIDDemanda}
                invoiceDetail={invoiceDetail}
                invoiceProviderOpen={invoiceProviderOpen}
                invoiceHirerOpen={invoiceHirerOpen}
                invoiceDistinctionOpen={invoiceDistinctionOpen}
                invoiceTaxesOpen={invoiceTaxesOpen}
                invoiceOtherInfoOpen={invoiceOtherInfoOpen}
                invoiceAttachmentOpen={invoiceAttachmentOpen}
                handleCopyToClipboard={handleCopyToClipboard}
                handleInvoiceDetailClose={handleInvoiceDetailClose}
                validateCpfOrCnpj={validateCpfOrCnpj}
                validatePhone={validatePhone}
                invoiceServiceInfo={invoiceServiceInfo}
                invoiceOtherInfo={invoiceOtherInfo}
                invoiceFilePDF={invoiceFilePDF}
                invoiceFileXML={invoiceFileXML}
                invoiceFilePhoto={invoiceFilePhoto}
                handleInputChange={handleInputChange}
                handleInvoiceDetailSection={handleInvoiceDetailSection}
                invoiceFilePhotoName={invoiceFilePhotoName}
                invoiceFileXMLName={invoiceFileXMLName}
                invoiceFilePDFName={invoiceFilePDFName}
                detailNumeroNota={ detailNumeroNota}
                detailValorNota={detailValorNota}
                detailDataEmissao={detailDataEmissao}
                invoiceDetailReadOnly={invoiceDetailReadOnly}
                validateCurrency={validateCurrency}
                validateCNPJ={validateCNPJ}
                cancelInProgress={cancelInProgress}
                detailPrestadorCpfCnpj={detailPrestadorCpfCnpj}
                detailPrestadorRazao={detailPrestadorRazao}
                detailPrestadorInscricao={detailPrestadorInscricao}
                detailPrestadorEndereco={detailPrestadorEndereco}
                detailPrestadorEstado={detailPrestadorEstado}
                detailPrestadorCidade={detailPrestadorCidade}
                detailPrestadorEmail={detailPrestadorEmail}
                detailPrestadorPhone={detailPrestadorPhone}
                detailPrestadorTipoCNPJ={detailPrestadorTipoCNPJ}
                detailTomadorCpfCnpj={detailTomadorCpfCnpj}
                detailTomadorRazao={detailTomadorRazao}
                detailTomadorInscricao={detailTomadorInscricao}
                detailTomadorEndereco={detailTomadorEndereco}
                detailTomadorEstado={detailTomadorEstado}
                detailTomadorCidade={detailTomadorCidade}
                detailTomadorEmail={detailTomadorEmail}
                detailTomadorPhone={detailTomadorPhone}
                detailTomadorTipoCNPJ={detailTomadorTipoCNPJ}
                detailEncargoINSS={detailEncargoINSS}
                detailEncargoIRRF={detailEncargoIRRF}
                detailEncargoCSLL={detailEncargoCSLL}
                detailEncargoCOFINS={detailEncargoCOFINS}
                detailEncargoPISPASEP={detailEncargoPISPASEP}
                detailEncargoCodServico={detailEncargoCodServico}
                detailEncargoAliquota={detailEncargoAliquota}
                detailEncargoISS={detailEncargoISS}
                detailEncargoMunicipioPrestacao={detailEncargoMunicipioPrestacao}
                invoiceFilePhotoError={invoiceFilePhotoError}
                invoiceFilePDFError={invoiceFilePDFError}
                invoiceFileXMLError={invoiceFileXMLError}
                invoiceDownloadFileOpen={invoiceDownloadFileOpen}
                detailFileXML={detailFileXML}
                detailFilePDF={detailFilePDF}
                detailFilePhoto={detailFilePhoto}
                invoiceServiceCNAE={invoiceServiceCNAE}
                invoiceServiceLC116={invoiceServiceLC116}
                detailPrestadorNumero={detailPrestadorNumero}
                detailPrestadorComplemento={detailPrestadorComplemento}
                detailPrestadorBairro={detailPrestadorBairro}
                detailPrestadorCEP={detailPrestadorCEP}
                detailTomadorNumero={detailTomadorNumero}
                detailTomadorComplemento={detailTomadorComplemento}
                detailTomadorBairro={detailTomadorBairro}
                detailTomadorCEP={detailTomadorCEP}
                invoiceDetailRegisterLoading={invoiceDetailRegisterLoading}
                handleSetInvoice={handleSetInvoice}
                handleCancelReasonChange={handleCancelReasonChange}
                invoiceInscricaoOpen={invoiceInscricaoOpen}
                detailInscricaoMunicipal={detailInscricaoMunicipal}
                detailInscricaoEstadual={detailInscricaoEstadual}
                detailCredentialTokenUser={detailCredentialTokenUser}
                detailCredentialPassword={detailCredentialPassword}
                detailCertifiedPFX={detailCertifiedPFX}
                invoiceCredentialOpen={invoiceCredentialOpen}
                cancelNfeReason={cancelNfeReason}
                handleCancelInvoice={handleCancelInvoice}
            />
        }

        { closeInvoiceDetailOpen &&
            <DetailClose
                handleInvoiceDetailClose={handleInvoiceDetailClose}
                handleCloseWithoutSave={handleCloseWithoutSave}
                handleCloseAndSave={handleCloseAndSave}
                handleDetailCloseButton={handleDetailCloseButton}
            />
        }
    </main>

);

export default CancelInvoice;
