import React, { useState, useEffect } from 'react';
import LoaderSpinner from 'react-loader-spinner';
import TextValidator from '../TextValidator/TextValidator';
import Axios from 'axios';
import {filter} from 'lodash';
import {GrClose} from 'react-icons/all';
import {AnimatePresence, motion} from 'framer-motion';

export default function CompanyOwner({ submitCompany, ownerData, setOwnerData, editing, getApiUrl, validateClientToken, notLoad = false }) {

    const [ownerMessage, setOwnerMessage] = useState('');
    const [ownerOptions, setOwnerOptions] = useState([]);
    const [ownerLoading, setOwnerLoading] = useState(!notLoad);
    const [ownerSearchData,setOwnerSearchData] = useState([]);
    const [selectedMeiOwner, setSelectedMeiOwner] = useState(null);

    useEffect(() => {
        if (ownerData && ownerData.change && ownerData.change.code && ownerData.change.code !== '') {
            submitCompany();
        }
        if (ownerData && (ownerData.cpf !== '' || ownerData.email !== '')) {
            setOwnerLoading(false);
        }
    }, [ownerData]);

    const changeMeiOwner = (selectedUser) => {
        setSelectedMeiOwner(selectedUser);
        setOwnerOptions(filter(ownerOptions, ['codusuario', selectedUser.codusuario]));
    };

    const changeMeiConfirmation = () => {
        setOwnerOptions([]);
        setOwnerData({ change: {
            cpf: selectedMeiOwner.cpfcpf,
            email: selectedMeiOwner.emlemail,
            code: selectedMeiOwner.codusuario
        }});
        setSelectedMeiOwner(null);
    };
    
    const handleInputChange = (e) => {
        setOwnerData({ change: {
            [e.target.name]: e.target.value
        } });
    };

    const handleMeiOwnerFetch = (query = '') => {
        if (query === '') {
            query = ownerData.email.length >= 3 ? ownerData.email : ownerData.cpf.length >= 3 ? ownerData.cpf : '';
        }
        if (query !== '') {
            setOwnerLoading(true);
            Axios.get(getApiUrl(`backoffice/usuarioAutocomplete/${query}`), {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: validateClientToken()
                }
            })
                .then(response => {
                    setOwnerLoading(false);
                    if (response.data.length < 1) {
                        setOwnerMessage('Nenhum usuário não encontrado.');
                    } else {
                        setOwnerOptions(response.data);
                        setOwnerSearchData(response.data);
                    }
                })
                .catch(error => {
                    setOwnerLoading(false);
                    setOwnerMessage('Nenhum usuário não encontrado.');
                });
        }
    };

    const handleOwnerChange = (e) => {
        e.preventDefault();
        setOwnerMessage('');
        if (ownerData && ownerData.change && ownerData.change.cpf && ownerData.change.cpf  !== '') {
            const clearedCpf = ownerData.change.cpf.match(/\d+/g).join('');
            if (clearedCpf.length >= 3) {
                handleMeiOwnerFetch(clearedCpf);
            }
        } else if (ownerData && ownerData.change && ownerData.change.email && ownerData.change.email !== '') {
            handleMeiOwnerFetch(ownerData.change.email);
        }
    };

    return (
        <>
            <div className='invoice-detail__section'>
                <div
                    className='invoice-detail__section-header'
                >
                    <h2 className='invoice-detail__section-title'>Proprietário da MEI</h2>
                </div>
                <div className='company-detail__section'>
                    <div className='company-detail__section-content'>
                        {(ownerLoading) && (
                            <div>
                                <LoaderSpinner
                                    type="TailSpin"
                                    color="#002240"
                                    style={{marginBottom: '1em', alignSelf: 'center',}}
                                />
                                <h5>Carregando proprietáro da MEI...</h5>
                            </div>
                        )}
                        {(!ownerLoading) && (
                            <div>
                                <div className='company-register__row'>
                                    <label className='label label_name'>
                                        <TextValidator
                                            name='owner_name'
                                            className='input'
                                            type='text'
                                            value={ownerData.name}
                                            readOnly
                                        />
                                        <p className='label__p label__p_float'>Nome</p>
                                    </label>
                                    <label className='label label_email'>
                                        <TextValidator
                                            name='owner_email'
                                            className='input'
                                            type='text'
                                            value={ownerData.email}
                                            readOnly
                                        />
                                        <p className='label__p label__p_float'>E-mail</p>
                                    </label>
                                    <label className='label label_cnpj'>
                                        <TextValidator
                                            name='owner_cpf'
                                            className='input'
                                            type='text'
                                            value={ownerData.cpf}
                                            readOnly
                                        />
                                        <p className='label__p label__p_float'>CPF</p>
                                    </label>
                                    <label className='label label_status'>
                                        <TextValidator
                                            name='owner_codigo'
                                            type='text'
                                            value={ownerData.code}
                                            className='input'
                                            readOnly
                                        />
                                        <p className='label__p label__p_float'>Código</p>
                                    </label>
                                </div>
                                <h3 className='invoice-detail__section-title'
                                    style={{
                                        fontSize: '1em',
                                        marginBottom: '2em'
                                    }}
                                >
                                Alterar Proprietário
                                </h3>
                                <div className='company-register__row'>
                                    <label className='label label_cnpj' style={{width: '300px'}}>
                                        <TextValidator
                                            className='input'
                                            name='cpf'
                                            type='text'
                                            value={ownerData.change.cpf}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleInputChange(e);
                                            }}
                                            readOnly={!editing}
                                        />
                                        <p className='label__p label__p_float'>CPF</p>
                                    </label>
                                    <label className='label label_email' style={{width: '300px'}}>
                                        <TextValidator
                                            className='input'
                                            name='email'
                                            type='text'
                                            value={ownerData.change.email}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleInputChange(e);
                                            }}
                                            readOnly={!editing}
                                        />
                                        <p className='label__p label__p_float'>Nome ou Email</p>
                                    </label>
                                    <button
                                        disabled={!ownerData.change || ownerData.change.email === '' || ownerData.change.cpf === ''}
                                        className={'mei_owner_change'}
                                        onClick={handleOwnerChange}
                                        style={!ownerData.change || ownerData.change.email === '' || ownerData.change.cpf === '' ? {
                                            cursor: 'unset',
                                            backgroundColor: '#888888'
                                        } : {}}
                                        title={!editing ? 'Entre no modo de edição para alterar o proprietário' : ''}
                                    >
                                    Pesquisar
                                    </button>
                                </div>
                                {(ownerMessage !== '') && (
                                    <div className='company-register__row'>
                                        <h4> {ownerMessage} </h4>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            
            {(ownerOptions.length >= 1) && (
                <div className='ownersModal'>
                    <div className='ownersModal_container'>
                        <GrClose
                            className='ownerModalClose'
                            onClick={() => {
                                setOwnerOptions([]);
                                setSelectedMeiOwner(null);
                            }}
                        />
                        <h2> {selectedMeiOwner ?
                            'Confirma a alteração ?'
                            : 'Usuários encontrados'}
                        </h2>
                        <h3>
                            {selectedMeiOwner ?
                                `Deseja realmente alterar o proprietário desta MEI para ${selectedMeiOwner.desnomeCompleto}`
                                : 'Por favor, selecione um usuário para continuar'}
                        </h3>
                        <div className='ownersModal_options'>
                            <AnimatePresence>
                                {ownerOptions.map((option, key) => (
                                    <motion.div
                                        key={key}
                                        className='owner_option'
                                        initial={{
                                            opacity: 0
                                        }}
                                        animate={{
                                            opacity: selectedMeiOwner && selectedMeiOwner.codusuario === option.codusuario ? 1 : selectedMeiOwner ? 0 : 1
                                        }}
                                        exit={{
                                            opacity: 0
                                        }}
                                    >
                                        <div className='owner_option_row'>
                                            <b>Nome:</b>&nbsp;
                                            <p>{option.desnomeCompleto}</p>
                                        </div>
                                        <div className='owner_option_row'>
                                            <b>CPF:</b>&nbsp;
                                            <p>{option.cpfcpf}</p>
                                        </div>
                                        <div className='owner_option_row'>
                                            <b>Email:</b>&nbsp;
                                            <p>{option.emlemail}</p>
                                        </div>
                                        <div className='owner_option_row'>
                                            <b>Plano:</b>&nbsp;
                                            <p>{option.desnomePlano}</p>
                                        </div>
                                        <br />
                                        {selectedMeiOwner ? (
                                            <div style={{ display: 'flex' }}>
                                                <motion.button
                                                    initial={{
                                                        opacity: 0
                                                    }}
                                                    animate={{
                                                        opacity: 1
                                                    }}
                                                    exit={{
                                                        opacity: 0
                                                    }}
                                                    className='owner_cancel_button'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedMeiOwner(null);
                                                        setOwnerOptions(ownerSearchData);
                                                    }}
                                                >
                                                    Cancelar
                                                </motion.button>
                                                <motion.button
                                                    initial={{
                                                        opacity: 0
                                                    }}
                                                    animate={{
                                                        opacity: 1
                                                    }}
                                                    exit={{
                                                        opacity: 0
                                                    }}
                                                    className='owner_confirm_button'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeMeiConfirmation();
                                                    }}
                                                >
                                                    Confirmar
                                                </motion.button>
                                            </div>
                                        ) : (parseInt(ownerData.code) === parseInt(option.codusuario)) ? (
                                            <motion.div
                                                initial={{
                                                    opacity: 0
                                                }}
                                                animate={{
                                                    opacity: 1
                                                }}
                                                exit={{
                                                    opacity: 0
                                                }}
                                                className='current_owner_label'
                                            >
                                                Proprietário atual da MEI
                                            </motion.div>
                                        ) : (
                                            <motion.button
                                                initial={{
                                                    opacity: 0
                                                }}
                                                animate={{
                                                    opacity: 1
                                                }}
                                                exit={{
                                                    opacity: 0
                                                }}
                                                className='owner_option_button'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    changeMeiOwner(option);
                                                }}
                                            >
                                                Mudar para este usuário
                                            </motion.button>
                                        )}
                                    </motion.div>
                                ))}
                            </AnimatePresence>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}