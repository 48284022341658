import React, { Component } from 'react';
import Header from './Header';

class HeaderContainer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Header
                {...this.state}
                nfeToggle={this.props.nfeToggle}
                avatarMenu={this.props.avatarMenu}
                handleLogoff={this.props.handleLogoff}
                companyToggle={this.props.companyToggle}
                companySelected={this.props.companySelected}
                handleInputChange={this.props.handleInputChange}
                handleStateToggle={this.props.handleStateToggle}
            />
        );
    }

}

export default HeaderContainer;
